import {useParams, useNavigate} from 'react-router-dom';
import React from 'react';
import axios from 'axios';

import { IoCaretDown } from "react-icons/io5";
import {BsChevronDoubleUp} from "react-icons/bs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider';
import DialogContentText from '@mui/material/DialogContentText';

import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';
import {URLORIGIN} from '../../config.js';
import {
    EditableField, 
    ReadonlyField, 
    AlertDialog, 
    TopRow,
    ShowPassword
} 
    from '../../template';

export function AddUser({windowWidthCategory}){
    const isAdd=true;
    const username = "";
    return (
    <AddOrEditUser 
    isAdd={isAdd} 
    pathUsername={username} 
    windowWidthCategory={windowWidthCategory}/>)
}

export function EditUser({windowWidthCategory}){
    const isAdd=false;
    const {username} = useParams();
    return (
    <AddOrEditUser 
    isAdd={isAdd} 
    pathUsername={username} 
    windowWidthCategory={windowWidthCategory}/>)
}

function AddOrEditUser({isAdd, pathUsername, windowWidthCategory}){
    const navigate = useNavigate();
    const iAmSuperAdmin = JSON.parse(window.sessionStorage.getItem("isSuperAdmin"));
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    const isMe = pathUsername === "me";
    const [username, setUsername] = React.useState(pathUsername);
    const [password, setPassword] = React.useState("");
    const [oldDisplayName, setOldDisplayName] = React.useState("");
    const [displayName, setDisplayName] = React.useState("");
    const [oldEmail, setOldEmail] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [oldDepartment, setOldDepartment] = React.useState("");
    const [department, setDepartment] = React.useState("");
    const [oldPosition, setOldPosition] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [modifiedDate, setModifiedDate] = React.useState(null);
    const [modifier, setModifier] = React.useState("");
    const [oldRoles, setOldRoles] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [hasAdmin, setHasAdmin] = React.useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
    const [unmatchedErrorOpen, setUnmatchedErrorOpen] = React.useState(false);
    const [userExistsErrorOpen, setUserExistsErrorOpen] = React.useState(false);
    const [isUserDeleted, setIsUserDeleted] = React.useState(false);
    const [rolesKey, setRolesKey] = React.useState(1);
    const [formKey, setFormKey] = React.useState(0);
    const passwordForm = new FormData();
    const isSecurityAdmin = JSON.parse(
        window.sessionStorage.getItem(
            "isSecurityAdmin"));
    const myAdminRoles = JSON.parse(
        window.sessionStorage.getItem(
            "adminRoles"))
        ?JSON.parse(
            window.sessionStorage.getItem(
                "adminRoles"))
        :[];
    if (isSecurityAdmin){
        myAdminRoles.push("Security Guard")
    };

    const submitPassword = passwordSubmit => {
        passwordSubmit.preventDefault();
        if (passwordSubmit.target[0].value === passwordSubmit.target[2].value) {
            passwordForm.append('password', passwordSubmit.target[0].value);
            axios(
                `${URLORIGIN}/users/edit/${username}`, {
                    baseURL: "",
                    method: "PATCH",
                    withCredentials: true,
                    data: passwordForm
                }
            ).then(
                () => setChangePasswordOpen(false)
            );
        } else {
            setUnmatchedErrorOpen(true);
            passwordSubmit.target[0].value = "";
            passwordSubmit.target[2].value = "";
            return false;
        }
    }
    const goHome = goingHome => {
        goingHome.preventDefault();
        navigate("/users");
    }
    const addUser = userAdd => {
        userAdd.preventDefault();
        const createUserRequestBody = {
            username: username,
            password: password,
            display_name: displayName,
            department: department,
            position: position,
            roles: roles
        }
        if (email){
            createUserRequestBody.email = email;
        }
        axios({
            baseURL: "",
            withCredentials: true, 
            method: 'POST',
            url: `${URLORIGIN}/users/create`,
            data: createUserRequestBody
        }).then(
            response =>{
                if (response.data.user_exists){
                    setIsUserDeleted(response.data.user_deleted);
                    setUserExistsErrorOpen(true);
                }
                else {
                    navigate("/users");
                }
            }
        );
    }
    const editUser = userEdit => {
        userEdit.preventDefault();
        const editUserRequestBody = {
            display_name: displayName,
            department: department,
            position: position,
            roles: roles,
            email: email
        }
        axios(
            `${URLORIGIN}/users/${username}`, {
                method: 'PATCH',
                baseURL: "",
                withCredentials: true,
                data: editUserRequestBody
            }
        ).then(
            () => navigate("/users")
        );
    }
    const deleteUser = userDelete => {
        userDelete.preventDefault();
        axios.delete(
            `${URLORIGIN}/users/${username}`, {
                baseURL: "",
                withCredentials: true}
        ).then(
            () =>navigate("/users")
        )
    };
    const resetUser = userReset => {
        userReset.preventDefault();
        setDisplayName(oldDisplayName);
        document.getElementById('display-name-input').value = oldDisplayName;
        setEmail(oldEmail);
        document.getElementById('email-address-input').value = oldEmail;
        setDepartment(oldDepartment);
        document.getElementById('department-input').value = oldDepartment;
        setPosition(oldPosition);
        document.getElementById('position-input').value = oldPosition;
        setRoles(JSON.parse(JSON.stringify(oldRoles)));
        setRolesKey(rolesKey + 1);
        setFormKey(formKey + 1);
    }
    React.useEffect(
        () => {
            setFormKey(formKey + 1);
            console.log(roles);
        }, [roles]
    )

    React.useEffect(
        () => {
            if (isAdd){
                axios.get(
                    `${URLORIGIN}/users/create`,
                    {
                        baseURL: "",
                        withCredentials: true
                    }
                ).catch( 
                    error => {
                        if (error.response.status === 303) {
                            navigate(error.response.data.url)
                        }
                    }
                );
            }
        }, []
    )
    React.useEffect(
        () => {
            if (!isAdd){
                axios.get(
                    `${URLORIGIN}/users/${username}`, {
                        baseURL: "",
                        withCredentials: true
                    }
                ).then(
                    response => {
                        setUsername(response.data.username?response.data.username:"");
                        setDisplayName(response.data.display_name?response.data.display_name:"");
                        setOldDisplayName(response.data.display_name?response.data.display_name:"");
                        setDepartment(response.data.department?response.data.department:"");
                        setOldDepartment(response.data.department?response.data.department:"");
                        setPosition(response.data.position?response.data.position:"");
                        setOldPosition(response.data.position?response.data.position:"");
                        setOldRoles(JSON.parse(JSON.stringify(response.data.roles?response.data.roles:[])));
                        setRoles(response.data.roles?response.data.roles:[]);
                        setEmail(response.data.email?response.data.email:"");
                        setOldEmail(response.data.email?response.data.email:"");
                        setIsSuperAdmin(response.data.is_superadmin?response.data.is_superadmin:false);
                        setHasAdmin(
                            iAmSuperAdmin
                            ?false
                            :response.data.roles
                                ?response.data.roles.some(role=>role.is_admin)
                                    ?true
                                    :response.data.roles.map(role=>role.role).filter(role => myAdminRoles.includes(role))
                                        ?false
                                        :true
                                :response.data.roles.map(role=>role.role).filter(role => myAdminRoles.includes(role))
                                    ?false
                                    :true);
                        setModifiedDate(response.data.modified_date?new Date(response.data.modified_date):"");
                        setModifier(response.data.modifier?response.data.modifier:"");
                    }
                ).catch(
                    error => {
                        if (error.response.status === 303) {
                            navigate(error.response.data.url)
                        }
                    }
                );
            }
        }, []
    );
    return(
        <article id="add-edit-user-container">
            <TopRow 
            headerText={isMe?"My Profile":isAdd?"Add User":"User Details"} 
            buttons={[
                isAdd
                ?{
                    idKey: "text-create",
                    text: "Add",
                    form: "add-user-form"
                }
                :{
                    idKey: "text-save",
                    text: "Update",
                    form: "edit-user-form"
                },
                isAdd
                ?null
                :{
                    idKey: "text-reset",
                    text: "Reset",
                    onClickFunction: resetUser
                },
                (!isAdd && !isMe && !hasAdmin)
                ?{
                    idKey: "text-change-password",
                    text: "Change Password",
                    onClickFunction: clicked =>{
                        clicked.preventDefault(); 
                        setChangePasswordOpen(true)}
                }
                :null,
                (!isAdd && !isMe && !hasAdmin)
                ?{
                    idKey: "text-delete",
                    text: "Delete",
                    onClickFunction: deleteUser
                }
                :null,
                {
                    idKey: "text-back", 
                    text: isAdd?"Cancel":"Back", 
                    onClickFunction: goHome}
            ]}/>

            <UserDetails
            isAdd={isAdd}
            hasAdmin={hasAdmin}
            isMe={isMe}
            iAmSuperAdmin={iAmSuperAdmin}
            isSuperAdmin={isSuperAdmin}
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            displayName={displayName}
            setDisplayName={setDisplayName}
            email={email}
            setEmail={setEmail}
            department={department}
            setDepartment={setDepartment}
            position={position}
            setPosition={setPosition}
            roles={roles}
            setRoles={setRoles}
            myAdminRoles={myAdminRoles}
            oldRoles={oldRoles}
            modifiedDate={modifiedDate}
            modifier={modifier}
            rolesKey={rolesKey}
            addUser={addUser}
            editUser={editUser}
            formKey={formKey}
            setFormKey={setFormKey}
            />
            <Dialog
                open={changePasswordOpen}
                onClose={() => {setChangePasswordOpen(false);}}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogContent>
                <form id="change-password-form" onSubmit={submitPassword} autoComplete="off">
                    <p>{username}</p>
                    <hr/>
                    <div className="password-form-container">
                        <label>
                            <p className="password-label">
                                New
                            </p>
                            <p className="password-colon">
                                :
                            </p>
                        </label>
                        {
                            roles.length === 1
                            ?(roles[0].role === "Security Guard")
                                ?<input 
                                    required 
                                    title="no space allowed."
                                    className="password-form-field"
                                    type="password" 
                                    id="new-password"
                                    name="NewPassword" 
                                    pattern="[^\s]+"
                                    placeholder="Pw (no space)"  />
                                :<input 
                                    required 
                                    title="Min. 8 characters, no space allowed."
                                    className="password-form-field"
                                    type="password" 
                                    id="new-password"
                                    name="NewPassword" 
                                    pattern="[^\s]+"
                                    minLength={8}
                                    placeholder="Pw (≥8 chars, no space)"  />
                            :<input 
                            required 
                            title="Min. 8 characters, no space allowed."
                            className="password-form-field"
                            type="password" 
                            id="new-password"
                            name="NewPassword" 
                            pattern="[^\s]+"
                            minLength={8}
                            placeholder="Pw (≥8 chars, no space)"  />
                        }
                        <ShowPassword idKey="new" passwordFieldID="new-password"/>
                        </div>
                        <div className="password-form-container">
                        <label>
                            <p className="password-label">
                                Confirm
                            </p>
                            <p className="password-colon">
                                :
                            </p>
                        </label>
                        {
                            roles.length === 1
                            ?(roles[0].role === "Security Guard")
                                ?<input 
                                    required 
                                    title="no space allowed."
                                    className="password-form-field"
                                    type="password" 
                                    id="confirm-password"
                                    name="ConfirmPassword" 
                                    pattern="[^\s]+"
                                    placeholder="Pw (no space)"  />
                                :<input 
                                    required 
                                    title="Min. 8 characters, no space allowed."
                                    className="password-form-field"
                                    type="password" 
                                    id="confirm-password"
                                    name="ConfirmPassword" 
                                    pattern="[^\s]+"
                                    minLength={8}
                                    placeholder="Pw (≥8 chars, no space)"  />
                            :<input 
                            required 
                            title="Min. 8 characters, no space allowed."
                            className="password-form-field"
                            type="password" 
                            id="confirm-password"
                            name="ConfirmPassword" 
                            pattern="[^\s]+"
                            minLength={8}
                            placeholder="Pw (≥8 chars, no space)"  />
                        }
                        <ShowPassword idKey="confirm" passwordFieldID="confirm-password"/>
                    </div>
                </form>
                </DialogContent>
                <DialogActions>
                    <button 
                    type="submit" 
                    form="change-password-form" 
                    className="password-submit-button">
                        Confirm
                    </button>
                    <button 
                    type="button" 
                    onClick={()=>setChangePasswordOpen(false)} 
                    className="password-close-button">
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            <AlertDialog 
            alertOpen={unmatchedErrorOpen} 
            setAlertOpen={setUnmatchedErrorOpen} 
            alertText="The 2 passwords do not match, please re-enter." 
            action=""/>
            <AlertDialog 
            alertOpen={userExistsErrorOpen} 
            setAlertOpen={setUserExistsErrorOpen} 
            alertTextWrapper={
                isUserDeleted
                ?<DialogContentText id='alert-dialog-description'>
                    <p>User with username {username} has been deleted. </p>
                    <p>Please create a new user with another username or contact technical support to recover their user information.</p>
                </DialogContentText>
                :<DialogContentText id='alert-dialog-description'>
                   <p> User with username {username} already existed.</p> 
                   <p>Please create with other usernames instead.</p>
                </DialogContentText>
            }
            action=""/>
        </article>
    )
}

function UserDetails(
    {
        isAdd,
        hasAdmin,
        isMe,
        iAmSuperAdmin,
        isSuperAdmin,
        username,
        setUsername,
        password,
        setPassword,
        displayName,
        setDisplayName,
        email,
        setEmail,
        department,
        setDepartment,
        position,
        setPosition,
        roles,
        setRoles,
        myAdminRoles,
        oldRoles,
        modifiedDate,
        modifier,
        rolesKey,
        addUser,
        editUser,
        formKey,
        setFormKey
    }
){
    return(
        <form
        id={isAdd?"add-user-form":"edit-user-form"}
        onSubmit={isAdd?addUser:editUser}
        key={formKey?formKey.toString():""}
        >
            <div className="dual-fields-container">
                {isAdd
                ? <EditableField 
                title="No space allowed."
                setter={setUsername} 
                label="Username*" 
                placeholder="Username (no space)" 
                idKey="username" 
                defaultValue={username} 
                cssClass="left-field short-label"
                pattern="[^\s]+"
                isRequired={true}/>
                : <ReadonlyField 
                value={username} 
                label="Username" 
                idKey="username" 
                placeholder="Username (no space)" 
                fullLength="" 
                cssClass="left-field short-label"
                />}
                {isAdd
                ? roles.length === 1
                    ?(roles[0].role === "Security Guard")
                        ?<EditableField 
                            setter={setPassword} 
                            label="Password*" 
                            placeholder="Pw (no space)" 
                            idKey="password" 
                            type="password"
                            defaultValue={password} 
                            cssClass="right-field short-label"
                            isRequired={true}
                            pattern="[^\s]+"
                            title="no space allowed."
                        />
                        :<EditableField 
                            setter={setPassword} 
                            label="Password*" 
                            placeholder="Pw (≥8 chars, no space)"
                            idKey="password" 
                            type="password"
                            defaultValue={password} 
                            cssClass="right-field short-label"
                            isRequired={true}
                            pattern="[^\s]+"
                            minLength={8}
                            title="Min. 8 characters, no space allowed."
                        />
                    :<EditableField 
                        setter={setPassword} 
                        label="Password*" 
                        placeholder="Pw (≥8 chars, no space)"
                        idKey="password" 
                        type="password"
                        defaultValue={password} 
                        cssClass="right-field short-label"
                        isRequired={true}
                        pattern="[^\s]+"
                        minLength={8}
                        title="Min. 8 characters, no space allowed."
                    />
                : null}
                {
                    isAdd || !hasAdmin || isMe
                    ?<EditableField 
                    setter={setDisplayName} 
                    label="Display Name*" 
                    placeholder="e.g., Chan Tai Man" 
                    idKey="display-name" 
                    defaultValue={displayName} 
                    cssClass="left-field"
                    isRequired={true}/>
                    :<ReadonlyField 
                    value={displayName} 
                    label="Display Name" 
                    idKey="display-name" 
                    placeholder="e.g., Chan Tai Man" 
                    fullLength="" 
                    cssClass="left-field"/>
                }
                {
                    isAdd || !hasAdmin || isMe
                    ?<EditableField 
                    setter={setEmail} 
                    label="Email Address" 
                    placeholder="Email Address" 
                    idKey="email-address" 
                    type="email"
                    defaultValue={email} 
                    cssClass="right-field"/>
                    :<ReadonlyField 
                    value={email} 
                    label="Email Address" 
                    idKey="email-address" 
                    placeholder="Email Address" 
                    fullLength="" 
                    cssClass="right-field"/>
                }
                {
                    isAdd || !hasAdmin || isMe
                    ?<EditableField 
                    setter={setDepartment} 
                    label="Department*" 
                    placeholder="Engineering" 
                    idKey="department" 
                    defaultValue={department} 
                    cssClass="left-field short-label"
                    isRequired={true}/>
                    :<ReadonlyField 
                    value={department} 
                    label="Department" 
                    idKey="department" 
                    placeholder="Engineering"  
                    fullLength="" 
                    cssClass="left-field short-label"/>
                }
                {
                    isAdd || !hasAdmin || isMe
                    ?<EditableField 
                    setter={setPosition} 
                    label="Position*" 
                    placeholder="Engineer" 
                    idKey="position" 
                    defaultValue={position} 
                    cssClass="right-field short-label"
                    isRequired={true}/>
                    :<ReadonlyField 
                    value={position} 
                    label="Position" 
                    idKey="position" 
                    placeholder="Engineer" 
                    fullLength="" 
                    cssClass="right-field short-label"/>
                }{
                    isAdd
                    ?null
                    :<>
                    <ReadonlyField 
                    value={modifier} 
                    label="Last Modifier" 
                    idKey="modifier" 
                    placeholder="Last Modifier" 
                    fullLength="" 
                    cssClass="left-field short-label"/>
                    <ReadonlyField 
                    value={modifiedDate
                        ? modifiedDate.toLocaleDateString(
                            undefined, {
                                day: 'numeric', 
                                month: 'short', 
                                year: 'numeric'})
                        : ""} 
                    label="Last Modified Date" 
                    idKey="modified-date" 
                    placeholder="Last Modified Date" 
                    fullLength="" 
                    cssClass="right-field"/>
                    </>
                }
                {isSuperAdmin || (isMe && iAmSuperAdmin)
                ?null
                :<Roles 
                isSuperAdmin={isSuperAdmin}
                isMe={isMe}
                iAmSuperAdmin={iAmSuperAdmin}
                isAdd={isAdd}
                roles={roles}
                setRoles={setRoles}
                myAdminRoles={myAdminRoles}
                oldRoles={oldRoles}
                rolesKey={rolesKey}
                formKey={formKey}
                setFormKey={setFormKey}
                />}
            </div>
        </form>
    )
}

function Roles(
    {
        isSuperAdmin,
        isMe,
        iAmSuperAdmin,
        isAdd,
        roles,
        setRoles,
        myAdminRoles,
        oldRoles,
        rolesKey,
        setFormKey,
        formKey
    }
){
    const leftOverRoles = myAdminRoles.filter(
        role => !roles.map(
            role=>role.role
        ).includes(role));

    const changeRoleAdmin = (roleIndex, isAdmin) =>{
        roles[roleIndex].is_admin = isAdmin;
        setRoles(roles);
        setFormKey(formKey + 1);
    }
    const changeRoles = (roleIndex, role)=>{
        if (roleIndex < roles.length){
            if (role){
                roles[roleIndex] = {role: role, is_admin: false};
                setRoles(roles);
                setFormKey(formKey + 1);
            } else {
                roles.splice(roleIndex, 1);
                setRoles(roles);
                setFormKey(formKey + 1);
            }
        } else {
            if (role){
                roles.push({role: role, is_admin: false});
                setRoles(roles);
                setFormKey(formKey + 1);
            }
        }
    }

    React.useEffect(()=>{},[formKey]);
    return(
        <div 
        className="long-field short-label role-container"
        id="roles"
        key={rolesKey}
        >
            <label>Roles</label>
            <div>
                {roles.map(
                    (role, roleIndex) => (
                        isSuperAdmin 
                        || (
                            !iAmSuperAdmin 
                            && (
                                (
                                    oldRoles.filter(oldRole => oldRole.role === role.role)[0]
                                        ?oldRoles.filter(oldRole => oldRole.role === role.role)[0].is_admin 
                                            || !myAdminRoles.includes(oldRoles.filter(oldRole => oldRole.role === role.role)[0].role) 
                                        :false
                                ) 
                                && !isMe)
                            && !isAdd
                        )
                        ?<ReadonlyRole
                        key={`${roleIndex}-role`}
                        role={role}
                        idKey={`${roleIndex}-role`}/>
                        :<EditableRole
                        key={`${roleIndex}-role`}
                        otherRoles={leftOverRoles}
                        changeRoles={changeRoles}
                        role={role}
                        roleIndex={roleIndex}
                        changeRoleAdmin={changeRoleAdmin}
                        idKey={`${roleIndex}-role`}
                        deleteOnly={
                            isMe 
                            && !(oldRoles.filter(oldRole => oldRole.role === role.role)[0]
                            ?oldRoles.filter(oldRole => oldRole.role === role.role)[0].is_admin
                            :false)}/>
                ))}
                {
                    leftOverRoles[0]
                    ?<EditableRole
                    key={`${roles.length}-role`}
                    otherRoles={leftOverRoles}
                    changeRoles={changeRoles}
                    role={{role: "", is_admin: false}}
                    roleIndex={roles.length}
                    changeRoleAdmin={changeRoleAdmin}
                    idKey={`${roles.length}-role`}
                    deleteOnly=""/>
                    :null
                }
            </div>
        </div>
    )
}

function EditableRole(
    {
        otherRoles, 
        changeRoles, 
        role, 
        roleIndex, 
        changeRoleAdmin, 
        idKey, 
        deleteOnly}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const listIsOpen = Boolean(anchorEl);
    return(
        <div className="role editable-role">
            <button
            type="button"
            id={`${idKey}-button`}
            className="dropdown-role-button"
            aria-controls={listIsOpen ? `${idKey}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={listIsOpen ? 'true' : undefined}
            onClick={(event) => {event.preventDefault(); setAnchorEl(event.currentTarget)}}
            >
                <div>
                    <input
                    id={`${idKey}-input`}
                    type="text"
                    placeholder="Role"
                    value={role.is_admin?`Admin - ${role.role}`:role.role}
                    readOnly/>
                    <IoCaretDown/>
                </div>
            </button>
            <Menu
                name={`${idKey}-menu`}
                anchorEl={anchorEl}
                open={listIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': `${idKey}-button`,
                }}
            >
                {
                    (otherRoles)
                    ?otherRoles.map(
                        (iter, index) => {
                            return(
                            <MenuItem 
                            key={`${idKey}-menu-${index}`} 
                            onClick={
                                () => {
                                    setAnchorEl(null); 
                                    changeRoles(roleIndex, iter.trim());}}>
                                        <p>{iter}</p>
                                        </MenuItem>)})
                    :null
                }
                {
                    role.role
                    ?
                    [<Divider key="divider" />,
                    <MenuItem 
                    key="null" 
                    onClick={() => {
                        setAnchorEl(null); 
                        changeRoles(roleIndex, null)}}>
                        Delete Role
                    </MenuItem>]
                    :null
                }
            </Menu>
            {
                deleteOnly || !role.role
                ?null
                :<button
                type="button"
                className="admin-role-button"
                onClick={
                    adminClicked => {
                        adminClicked.preventDefault(); 
                        changeRoleAdmin(roleIndex, !role.is_admin)}}>
                    <BsChevronDoubleUp 
                    color={role.is_admin?"#2d7abf":"lightgrey"}/>
                </button>}
        </div>
    )
}

function ReadonlyRole({role, idKey}){
    return(
        <div className="role readonly-role">
            <input
            id={`${idKey}-input`}
            className="readonly-role-input"
            type="text"
            placeholder="Role"
            value={role.is_admin?`Admin - ${role.role}`:role.role}
            readOnly/>
            {role.is_admin
            ?<BsChevronDoubleUp 
            color="#2d7abf" className="admin-role-icon"/>
            :null
            }
        </div>
    )
}