import React from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import qs from 'qs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {URLORIGIN} from '../../config.js';
import {
    EditableField, 
    ChooseFromList, 
    CalendarButton, 
    MultipleButtons, 
    AlertDialog
} from '../../template';
import SearchCustomerAndVessel from './searchCustomers.js';
import '../../index.scss';
import './overview.scss';
import './create.scss';

export default function CreateJob({windowWidthCategory}){
    const [jobId, setJobId] = React.useState("");
    const [role, setRole] = React.useState("");
    const roles = JSON.parse(window.sessionStorage.getItem('roles'));
    const [customerNo, setCustomerNo] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [totalCustomerNames, setTotalCustomerNames] = React.useState([""]);
    const [shipRegNo, setShipRegNo] = React.useState("");
    const [shipRegNos, setShipRegNos] = React.useState([""]);
    const [totalShipRegNos, setTotalShipRegNos] = React.useState([""]);
    const [shipNames, setShipNames] = React.useState([""]);
    const [shipName, setShipName] = React.useState("");
    const [dateStart, setDateStart] = React.useState("");
    const [dateEnd, setDateEnd] = React.useState("");
    const [description, setDescription] = React.useState("");
    let navigate = useNavigate();
    const [noRecordErrorOpen, setNoRecordErrorOpen] = React.useState(false);
    const [createJobOpen, setCreateJobOpen] = React.useState(false);
    const [jobExistsErrorOpen, setJobExistsErrorOpen] = React.useState(false);
    const [invalidDateErrorOpen, setInvalidDateErrorOpen] = React.useState(false);
    const [jobExistsErrorMessage, setJobExistsErrorMessage] = React.useState("");
    const [wrongRecord, setWrongRecord] = React.useState(null);
    React.useEffect(()=>{
        axios.get(
            `${URLORIGIN}/jobs/create/init`, {
                baseURL: "",
                withCredentials: true}
        ).then (response => {
            setTotalCustomerNames(response.data.customer_names);
            setTotalShipRegNos(response.data.ship_reg_nos);
        }).catch (error => {
            if (error.response.status === 303) {
                navigate(error.response.data.url)
            }
        })
    }, []);
    React.useEffect(
        () => {
            console.log(customerName);
        }, [customerName]
    );
    React.useEffect(
        () => {
            console.log(customerNo);
        }, [customerNo]
    );
    function searchCustomerAndShips(customerAndShipsQuerySubmit){
        customerAndShipsQuerySubmit.preventDefault();
        document.getElementById('customer-name-search-input').blur();
        document.getElementById('ship-reg-no-search-input').blur();
        const customerAndShipQuery = {};
        const inputCustomerName = document.getElementById("customer-name-search-input").value;
        const inputShipRegNo = document.getElementById("ship-reg-no-search-input").value;
        if (inputCustomerName){
            customerAndShipQuery.customer_name = inputCustomerName;
        }
        if (inputShipRegNo){
            customerAndShipQuery.ship_reg_no = inputShipRegNo;
        }
        if (inputCustomerName || inputShipRegNo){
             axios.get(
                `${URLORIGIN}/jobs/create`, {
                    baseURL: "",
                    withCredentials: true, 
                    params: customerAndShipQuery,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: "repeat" })
                    }}
            ).then(response => {
                if (!response.data){
                    if (inputCustomerName){
                        setWrongRecord(inputCustomerName);
                        setNoRecordErrorOpen(true);
                    } else{
                        setWrongRecord(inputShipRegNo);
                        setNoRecordErrorOpen(true);
                    }
                } else{
                    setCustomerName(response.data.customer_name);
                    document.getElementById('customer-name-input').value = response.data.customer_name;
                    setCustomerNo(response.data.customer_no);
                    document.getElementById('customer-id-input').value = response.data.customer_no;
                    const returnedShipNames = [];
                    const returnedShipRegNos = [];
                    var shipRegNoMatched = false;
                    for (const ship of response.data.ships){
                        returnedShipNames.push(ship.ship_name);
                        returnedShipRegNos.push(ship.ship_reg_no);
                        if (inputShipRegNo){
                            if (inputShipRegNo == ship.ship_reg_no){
                                setShipName(ship.ship_name);
                                setShipRegNo(ship.ship_reg_no);
                                shipRegNoMatched = true;
                            }
                        }
                    }
                    setShipRegNos(returnedShipRegNos);
                    if (!inputShipRegNo){
                        setShipRegNo(returnedShipRegNos[0]);
                        setShipName(returnedShipNames[0]);
                    }
                    setShipNames(returnedShipNames);
                    if (!shipRegNoMatched && inputShipRegNo){
                        setWrongRecord(`${inputShipRegNo} of ${inputCustomerName}`);
                        setNoRecordErrorOpen(true);
                    }
                }
            })
        } else{
            setCustomerNo("");
            setShipRegNos([]);
            setShipName("");
            setShipNames([]);
        }
    }
    function createJob(jobCreateClicked){
        jobCreateClicked.preventDefault();
        const createJobRequestBody = {
            roles: [role], 
            job_id: jobId,
            job_start: dateStart.getFullYear() 
                + '-' 
                + (dateStart.getMonth()>8?'':'0') 
                + (dateStart.getMonth() + 1)
                + '-'
                + (dateStart.getDate()>9?'':'0')
                + dateStart.getDate(),
        }
        if (description){
            createJobRequestBody.job_description = description;
        }
        console.log("CustomerNo");
        console.log(customerNo);
        if (customerNo){
            createJobRequestBody.customer_no = customerNo;
        }
        console.log("CustomerName");
        console.log(customerName);
        if (customerName){
            createJobRequestBody.customer_name = customerName;
        }
        if (document.getElementById("ship-reg-no-input").value){
            createJobRequestBody.ship_reg_no = document.getElementById("ship-reg-no-input").value;
        }
        if (document.getElementById("ship-name-input").value){
            createJobRequestBody.ship_name = document.getElementById("ship-name-input").value;
        }
        if (dateEnd){
            if (dateEnd < dateStart){
                setInvalidDateErrorOpen(true);
                setCreateJobOpen(false);
                return false;
            }
            createJobRequestBody.job_end = dateEnd.getFullYear() 
            + '-' 
            + (dateEnd.getMonth()>8?'':'0') 
            + (dateEnd.getMonth() + 1)
            + '-'
            + (dateEnd.getDate()>9?'':'0')
            + dateEnd.getDate();
        }
        axios({
            baseURL: "",
            withCredentials: true, 
            method: 'POST',
            url: `${URLORIGIN}/jobs/create`,
            data: createJobRequestBody
        }).then (response => {
            if (response.data.job_exists){
                setJobExistsErrorMessage((response.data.is_deleted?"Deleted j":"J") + "ob " + jobId + " is already found in the database for role " + response.data.role + ".");
                setJobExistsErrorOpen(true);
                setCreateJobOpen(false);
            } else {
                navigate("/jobs");
            }
        });
    }
    return(
        <article id="job-create-wrapper">
            <div id="job-create-top-row">
                <MultipleButtons 
                cssId="finish-buttons" 
                buttons={[
                    {
                        idKey: "text-create", 
                        text: "Create", 
                        form: "create-job-form"}, 
                    {
                        idKey: "text-back", 
                        text: "Cancel", 
                        onClickFunction: (clicked)=>{
                            clicked.preventDefault(); 
                            navigate("/jobs");}}
                ]}/>
                <SearchCustomerAndVessel 
                searchCustomerAndShips={searchCustomerAndShips} 
                totalCustomerNames={totalCustomerNames} 
                totalShipRegNos={totalShipRegNos}/>
            </div>
            <CreateJobMain 
            setJobId={setJobId} 
            roles={roles} role={role} 
            setRole={setRole} 
            customerNo={customerNo} 
            setCustomerNo={setCustomerNo}
            customerName={customerName} 
            setCustomerName={setCustomerName}
            shipRegNos={shipRegNos} 
            shipRegNo={shipRegNo} 
            setShipRegNo={setShipRegNo} 
            shipNames={shipNames} 
            shipName={shipName} 
            setShipName={setShipName} 
            dateStart={dateStart} 
            setDateStart={setDateStart} 
            dateEnd={dateEnd} 
            setDateEnd={setDateEnd} 
            setDescription={setDescription}
            setCreateJobOpen={setCreateJobOpen}/>
            <AlertDialog 
            alertOpen={noRecordErrorOpen} 
            setAlertOpen={setNoRecordErrorOpen} 
            alertText={`${wrongRecord} does not exist.`} 
            action=""
            />
            <AlertDialog 
            alertOpen={invalidDateErrorOpen} 
            setAlertOpen={setInvalidDateErrorOpen} 
            alertText="The end date should take place after the start date."
            action=""/>
            <AlertDialog 
            alertOpen={createJobOpen} 
            setAlertOpen={setCreateJobOpen} 
            alertText={`Confirm creating job ${jobId}?`}
            action={createJob}/>
            <AlertDialog
            alertOpen={jobExistsErrorOpen}
            setAlertOpen={setJobExistsErrorOpen}
            alertText={jobExistsErrorMessage}
            action=""/>
        </article>
    )
}


function CreateJobMain(
    {
        setJobId, 
        roles, 
        role,
        setRole, 
        customerNo, 
        setCustomerNo,
        customerName, 
        setCustomerName,
        shipRegNos, 
        shipRegNo,
        setShipRegNo, 
        shipNames, 
        shipName,
        setShipName, 
        dateStart, 
        setDateStart, 
        dateEnd, 
        setDateEnd, 
        setDescription,
        setCreateJobOpen
    }){
        return(
            <section id="create-job-main">
                <title id="create-job-header">Create Job</title>
                <form
                id="create-job-form"
                onSubmit={
                    jobCreate => {
                        jobCreate.preventDefault();
                        if (!document.getElementById("role-input").value){
                            document.getElementById("role-input").focus();
                        }else if (!document.getElementById("start-date-input").value){
                            document.getElementById("start-date-input").focus();
                        } else setCreateJobOpen(true);
                    }
                }
                >
                    <div className="dual-fields-container">
                        <EditableField 
                        setter={setJobId} 
                        label="Job ID*" 
                        placeholder="Job ID (no /)" 
                        idKey="job-id" 
                        cssClass="left-field short-label"
                        pattern="[^/]+"
                        isRequired={true}/>
                        <ChooseFromList 
                        list={roles} 
                        setItem={setRole} 
                        item={role} 
                        label="Role*" 
                        idKey="role" 
                        placeholder="Role" 
                        cssClass="right-field short-label"
                        form="create-job-form"
                        defaultText=""
                        isRequired={true}/>
                    </div>
                    <div className="dual-fields-container">
                        <EditableField 
                        value={customerNo} 
                        setter={setCustomerNo}
                        label="Customer ID" 
                        idKey="customer-id" 
                        placeholder="Customer ID" 
                        fullLength="" 
                        cssClass="left-field short-label"/>
                        <EditableField 
                        value={customerName} 
                        setter={setCustomerName}
                        label="Customer Name" 
                        idKey="customer-name" 
                        placeholder="Customer Name" 
                        fullLength="" 
                        cssClass="right-field"/>
                    </div>
                    <div className="dual-fields-container">
                        <div className="left-field autocomplete-field">
                            <label className="normal-label">Vessel Reg. No.</label>
                            <Autocomplete
                                disablePortal
                                id='ship-reg-no-input'
                                options={shipRegNos?shipRegNos:[""]}
                                value={shipRegNo}
                                onChange={
                                    (shipRegNoChange, newShipRegNo)=>{
                                        shipRegNoChange.preventDefault(); 
                                        setShipRegNo(newShipRegNo);
                                        if (
                                            shipRegNos.includes(
                                                newShipRegNo
                                            )
                                        ){
                                            setShipName(
                                                shipNames[
                                                    shipRegNos.indexOf(
                                                        newShipRegNo
                                                    )
                                                ]
                                            );
                                        }
                                    }
                                }
                                freeSolo
                                renderInput={
                                    (params) => <
                                        TextField{...params} 
                                        label="Vessel Reg. No." 
                                    />
                                }
                            />
                        </div>
                        <div className="right-field autocomplete-field short-label">
                            <label className="normal-label">Vessel Name</label>
                            <Autocomplete
                                disablePortal
                                id='ship-name-input'
                                options={shipNames?shipNames:[""]}
                                value={shipName}
                                onChange={
                                    (shipNameChange, newShipName)=>{
                                        shipNameChange.preventDefault(); 
                                        setShipName(newShipName);
                                        if (
                                            shipNames.includes(
                                                newShipName
                                            )
                                        ){
                                            setShipRegNo(
                                                shipRegNos[
                                                    shipNames.indexOf(
                                                        newShipName
                                                    )
                                                ]
                                            );
                                        }
                                    }
                                }
                                freeSolo
                                renderInput={
                                    (params) => <
                                        TextField{...params} 
                                        label="Vessel Name" 
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className="dual-fields-container">
                        <CalendarButton 
                        placeholder="Select Date" 
                        setter={setDateStart} 
                        value={dateStart} 
                        idKey="start-date" 
                        label="Start Date*" 
                        prependText="" 
                        cssClass="left-field short-label"
                        form="create-job-form"
                        isRequired={true}/>
                        <CalendarButton 
                        placeholder="Select Date" 
                        setter={setDateEnd} 
                        value={dateEnd} 
                        idKey="end-date" 
                        label="End Date" 
                        prependText="" 
                        cssClass="right-field short-label"/>
                    </div>
                    <div className="dual-fields-container">
                        <EditableField 
                        setter={setDescription} 
                        label="Description" 
                        placeholder="Description" 
                        idKey="description" 
                        cssClass="long-field short-label"
                        isRequired={false}
                        longfield={true}/>
                    </div>
                </form>
            </section>
        )
}