import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './login.scss';
import {Col} from "react-bootstrap";
import '../../scss/custom.scss';
import axios from 'axios';
import FormData from 'form-data';
import {useNavigate} from 'react-router-dom';
import {URLORIGIN} from '../../config.js';
import {AlertDialog} from "../../template";
import { responsiveFontSizes } from '@mui/material';

export default function Login(){
    return(
        <article>
            <title className="login-title text-center mt-5 mb-5">Login</title> 
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="login-box pt-4">
                <LoginForm/>
            </Col>
        </article>
    )
}

function LoginForm(){
    const form = new FormData();
    const [errorOpen, setErrorOpen] = React.useState(false)
    let navigate = useNavigate();
    const instance = axios.create(
        {
                baseURL: "",
                withCredentials: true,
          })
    const submit = loginSubmit => {
        loginSubmit.preventDefault();
        form.append('username', loginSubmit.target[0].value);
        form.append('password', loginSubmit.target[1].value);
        instance({
            method: 'POST',
            url: `${URLORIGIN}/login`,
            data: form,
            headers: {
                "Content-type": "multipart/form-data",
            },
        }).then(response => {
            window.sessionStorage.setItem('displayName', response.data.user.display_name);
            window.sessionStorage.setItem('initials', response.data.user.initials);
            var adminRoles = [];
            var roles = [];
            for (const role of response.data.user.roles){
                roles.push(role.role);
                if (role.is_admin){
                    adminRoles.push(role.role);
                }
            }
            window.sessionStorage.setItem('roles', JSON.stringify(roles));
            window.sessionStorage.setItem('adminRoles', JSON.stringify(adminRoles));
            window.sessionStorage.setItem('isSecurity', response.data.user.is_security);
            window.sessionStorage.setItem('isSecurityAdmin', response.data.user.is_security_admin);
            window.sessionStorage.setItem("isSuperAdmin", response.data.user.is_superadmin);
            navigate(response.data.url);
        }).catch(error => {
            if (error.response.status === 400) {
                setErrorOpen(true);
            }
        })
    }
    return(
        <div className="login-wrapper">
            <form onSubmit={submit} autoComplete="off">
                <div className="login-form border-1 px-3">
                    <input className="login-form-field" type="text" name="Username" placeholder="Username" autoFocus required/>
                    <input className="login-form-field" type="password" name="Password" placeholder="Password" required  autoComplete="new-password"/>
                </div>
                <div className="login-submit-wrapper">
                    <input className="login-submit" type="submit" name="Login" value="Login"/>
                </div>
            </form>
            <AlertDialog alertOpen={errorOpen} setAlertOpen={setErrorOpen} alertText="Please enter a correct username or password." action=""/>
        </div>
    )
}