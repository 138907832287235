// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import {Container, Col} from "react-bootstrap";
import {BsGear} from 'react-icons/bs';
import {IoLogOutOutline, IoHomeOutline} from 'react-icons/io5';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Popover from '@mui/material/Popover';

import reportWebVitals from './reportWebVitals';
import logo from './assets/HK_Ferry.webp';
import logoSmall from './assets/HK_Ferry_small.webp';
import {URLORIGIN} from './config.js';
import {AlertDialog, ShowPassword} from "./template";
import {BrowserRouter, Routes, Route, Navigate, NavLink, useLocation, useNavigate} from 'react-router-dom';
import Login from './components/login/login';
import JobOverview from './components/jobs/overview';
import CreateJob from './components/jobs/create';
import {ViewJob, EditJob} from './components/jobs/viewAndEdit';
import UploadDocuments from './components/jobs/upload';
import {UserList} from "./components/users/list"
import {AddUser, EditUser} from "./components/users/addAndEdit";
import {RoleList} from "./components/roles/list";
import {CustomerList} from "./components/customers/list";
import {AddCustomer, EditCustomer} from "./components/customers/addAndEdit";
import {EditVessels} from "./components/customers/ships";
import Security from "./components/security/security";
import SecurityDummy from "./components/security/securityDummy";
import SecurityRecord from "./components/security/record";
import './index.scss';
import './index.css';
import './components/login/login.scss';

ReactDOM.render(
  <BrowserRouter>
    <Main/>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot){
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Main(props){
  let location = useLocation();
  const displayName = window.sessionStorage.getItem("displayName");
  const initials = window.sessionStorage.getItem("initials");
  const isAdmin = window.sessionStorage.getItem("adminRoles")
    ?JSON.parse(window.sessionStorage.getItem("adminRoles"))[0]
      ?true
      :false
    :false;
  const homeLink = JSON.parse(window.sessionStorage.getItem('roles'))
    ?JSON.parse(window.sessionStorage.getItem('roles'))[0]
      ?{link: "/jobs", title: "Jobs Overview"}
      :JSON.parse(window.sessionStorage.getItem("isSecurityAdmin"))
        ?{link: "/security", title: "Security page"}
        :null
    :null;
  const variableButton = location.pathname === "/jobs" 
    || !homeLink 
    || (location.pathname === "/security" && homeLink.link === "/security") 
      ? <Settings isAdmin={isAdmin} securityNonAdminOnly={!homeLink} securityOnly={!(JSON.parse(window.sessionStorage.getItem('roles'))?JSON.parse(window.sessionStorage.getItem('roles'))[0]:null)}/> 
      : homeLink 
        ? <Home homeLink={homeLink}/> 
        : null;
  const [windowWidthCategory, setWindowWidthCategory] 
    = React.useState(
      window.innerWidth>2230 ? "xxxxxxxl" :
      window.innerWidth>2100 ? "xxxxxxl" :
      window.innerWidth>1920 ? "xxxxxl" :
      window.innerWidth>1740 ? "xxxxl" :
      window.innerWidth>1560 ? "xxxl" :
      window.innerWidth>1380 ? "xxl" :
      window.innerWidth>1200 ? "xl" : 
      window.innerWidth>992 ? "lg" : 
      window.innerWidth>768 ? "md" : 
      window.innerWidth>576 ? "sm" : 
      window.innerWidth>460 ? "xs" : "xxs");
  const updateWindowWidth = () => {
    setWindowWidthCategory(
      window.innerWidth>2230 ? "xxxxxxxl" :
      window.innerWidth>2100 ? "xxxxxxl" :
      window.innerWidth>1920 ? "xxxxxl" :
      window.innerWidth>1740 ? "xxxxl" :
      window.innerWidth>1560 ? "xxxl" :
      window.innerWidth>1380 ? "xxl" :
      window.innerWidth>1200 ? "xl" : 
      window.innerWidth>992 ? "lg" : 
      window.innerWidth>768 ? "md" : 
      window.innerWidth>576 ? "sm" : 
      window.innerWidth>460 ? "xs" : "xxs");
  }
  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  })
  return(
    <>
      {
        ["/", "/login"].includes(location.pathname) ? <LoginHeader/> :
        <MainHeader 
          displayName = {displayName} 
          initials = {initials} 
          variableButton = {variableButton} 
          windowWidthCategory = {windowWidthCategory}/>
      }
      <main>
        <Routes>
          <Route path="/" element={<Navigate to="/login"/>}/>
          <Route path="/login" element={<Login />} />
          <Route path="/jobs/suggestions" element={<Navigate to="/jobs"/>}/>
          <Route path="/jobs" element={<JobOverview windowWidthCategory = {windowWidthCategory} />} />
          <Route path="/jobs/create/init" element={<Navigate to="/jobs/create"/>}/>
          <Route path="/jobs/create" element={<CreateJob windowWidthCategory = {windowWidthCategory} />} />
          <Route path="/jobs/view/:job_no" element={<ViewJob windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/jobs/edit/:job_no" element={<EditJob windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/jobs/uploads/:job_no" element={<UploadDocuments windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/users" element={<UserList windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/users/create" element={<AddUser windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/users/:username" element={<EditUser windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/roles" element={<RoleList windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/customers" element={<CustomerList windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/customers/create" element={<AddCustomer windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/customers/edit/?is_retired=false" element={<Navigate to="/customers"/>}/>
          <Route path="/customers/edit/:customer_no" element={<EditCustomer windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/customers/ships/:customer_no" element={<EditVessels windowWidthCategory={windowWidthCategory}/>}/>
          <Route path="/security" element={<Security/>} />
          <Route path="/security/init" element={<Navigate to="/security"/>}/>
          <Route path="/security/records" element={<SecurityRecord/>}/>
        </Routes>
      </main>
      <Routes>
        <Route path="/login" element={<CopyrightFooter/>} />
        <Route path="/jobs" element={<CopyrightFooter/>} />
        <Route path="/jobs/create" element={<CopyrightFooter/>} />
        <Route path="/jobs/view/:job_no" element={<CopyrightFooter/>}/>
        <Route path="/jobs/edit/:job_no" element={<CopyrightFooter/>}/>
        <Route path="/jobs/uploads/:job_no" element={<CopyrightFooter/>}/>
        <Route path="/users" element={<CopyrightFooter/>}/>
        <Route path="/users/create" element={<CopyrightFooter/>}/>
        <Route path="/users/:username" element={<CopyrightFooter/>}/>
        <Route path="/roles" element={<CopyrightFooter/>}/>
        <Route path="/customers" element={<CopyrightFooter/>}/>
        <Route path="/customers/create" element={<CopyrightFooter/>}/>
        <Route path="/customers/edit/?is_retired=false" element={<CopyrightFooter/>}/>
        <Route path="/customers/edit/:customer_no" element={<CopyrightFooter/>}/>
        <Route path="/customers/ships/:customer_no" element={<CopyrightFooter/>}/>
        <Route path="/security/records" element={<CopyrightFooter/>}/>
      </Routes>
    </>
  )
}
function LoginHeader(){
  return(
    <header>
      <div className="header-lower-border-login border-bottom border-info border-1">
        <Col xs={12} sm={6} md={6} lg={4} xl={3} className='pt-1 pb-2'>
            <Container>
              <img src={logo} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" width="100%"/>
            </Container>
        </Col>
      </div>
    </header>
  )
}
function MainHeader({displayName, initials, variableButton, windowWidthCategory}){
  return(
    <header >
      {
        windowWidthCategory.includes("xl") ? <XLMainHeader displayName = {displayName} variableButton = {variableButton} /> :
        windowWidthCategory === "lg" ? <LGMainHeader displayName = {displayName} variableButton = {variableButton} /> :
        ["sm", "md"].includes(windowWidthCategory) ? <SMMDMainHeader initials = {initials} variableButton = {variableButton} /> :
        windowWidthCategory === "xs" ? <XSMainHeader initials = {initials} variableButton = {variableButton} />
        : <XXSMainHeader initials = {initials} variableButton = {variableButton} />
      }
    </header >
  )
}

function XLMainHeader({displayName, variableButton}){
  return(
    <div className="header-lower-border border-bottom border-info border-1">
      <div className="header-wrapper pt-1 pb-3">
        <div className="xl-logo-column">
          <div className="logo-container">
            <img src={logo} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" height="45px" width="auto"/>
          </div>
        </div>
        <div className="xl-lg-welcome-column">
          <h3 className="welcome-title">Welcome {displayName}!</h3>
        </div>
        <div className="header-icons">
          {variableButton}
          <Logout/>
        </div>
      </div>
    </div>
  )
}
function LGMainHeader({displayName, variableButton}){
  return(
    <div className="header-lower-border border-bottom border-info border-1">
      <div className="header-wrapper pt-1 pb-3">
        <div className="lg-logo-column">
          <div className="logo-container">
            <img src={logo} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" height="45px" width="auto"/>
          </div>
        </div>
        <div className="xl-lg-welcome-column">
          <h2 className="welcome-title">Welcome {displayName}!</h2>
        </div>
        <div className="header-icons">
          {variableButton}
          <Logout/>
        </div>
      </div>
    </div>
  )
}
function SMMDMainHeader({initials, variableButton}){
  return(
    <div className="header-lower-border border-bottom border-info border-1">
      <div className="header-wrapper pt-1 pb-3">
        <div className="sm-md-logo-column">
          <div className="logo-container">
            <img src={logo} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" height="45px" width="auto"/>
          </div>
        </div>
        <div className="header-icons">
          <div className="initials">
            {initials}
          </div>
          {variableButton}
          <Logout/>
        </div>
      </div>
    </div>
  )
}
function XSMainHeader({initials, variableButton}){
  return(
    <div className="header-lower-border border-bottom border-info border-1">
      <div className="header-wrapper pt-1 pb-3">
        <div className="xs-logo-column">
          <div className="logo-container">
            <img src={logo} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" width="60%"/>
          </div>
        </div>
        <div className="header-icons">
          <div className="initials">
            {initials}
          </div>
          {variableButton}
          <Logout/>
        </div>
      </div>
    </div>
  )
}
function XXSMainHeader({initials, variableButton}){
  return(
    <div className="header-lower-border border-bottom border-info border-1">
      <div className="header-wrapper pt-1 pb-3">
        <div className="xxs-logo-column">
          <div className="logo-container">
            <img src={logoSmall} className="hkferry-logo" alt="Logo of Hong Kong Ferry (Holdings) Company Limited" loading="eager" height="45px" width="auto"/>
          </div>
        </div>
        <div className="header-icons">
          <div className="initials">
            {initials}
          </div>
          {variableButton}
          <Logout/>
        </div>
      </div>
    </div>
  )
}
    
function Settings({isAdmin, securityNonAdminOnly, securityOnly}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const clickSettingsIcon = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const closeSettingsDropdown = () => {
    setAnchorEl(null);
  }
  const settingsDropdownOpen = Boolean(anchorEl);
  const id = settingsDropdownOpen ? 'simple-popover' : undefined;
  var settingLinks = [<li key="changePassword"><ChangePasswordModal isSecurityOnly={securityOnly} autoFocus/></li>];
  const isSecurityAdmin = window.sessionStorage.getItem('isSecurityAdmin') === "true";
  if (isAdmin || isSecurityAdmin){
    settingLinks.unshift(
    (!securityOnly)
      ?<li key="customer"><NavLink className="settings-dropdown-item" to='/customers' autoFocus>Customer List</NavLink></li>
      :null,
    <li key="user"><NavLink className="settings-dropdown-item" to='/users'>User List</NavLink></li>,
    (isAdmin)
    ?<li key="roles">
      <NavLink className="settings-dropdown-item" to='/roles'>Role List</NavLink>
    </li>
    :null
    );
  }
  const isSecurity = (window.sessionStorage.getItem('isSecurity') === "true");
  if (isSecurity && (!securityNonAdminOnly || !securityOnly)){
    settingLinks.push(<li key="security"><NavLink className="settings-dropdown-item" to='/security'>Security</NavLink></li>);
  }
  if (isSecurityAdmin){
    settingLinks.push(<li key="security-download"><NavLink className="settings-dropdown-item" to="/security/records">Download Security Records</NavLink></li>);
  }
  return (
    <>
      <button title="Other links" aria-describedby={id} variant="contained" className="header-buttons" onClick={clickSettingsIcon} type="button"> <BsGear className="home-icon"/> </button>
      <Popover
        className= "settings-dropdown"
        id={id}
        open={settingsDropdownOpen}
        anchorEl={anchorEl}
        onClose={closeSettingsDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="settings-dropdown-container">
          <ul className="settings-dropdown-list">
            {settingLinks}
          </ul>
        </div>
      </Popover>
    </>
  )
}

function Logout(){
  let navigate = useNavigate();
  const triggerLogout = logoutClicked => {
    logoutClicked.preventDefault();
    window.sessionStorage.clear();
    axios.delete(
      `${URLORIGIN}/login`,{
        baseURL: "",
        withCredentials: true
      }
    ).then(response => navigate(response.data.url))
  }
  return(
    <button title="Log out" className="header-buttons" type="button" onClick={triggerLogout}>
      <IoLogOutOutline className="home-icon"/>
    </button>
  )
}

function Home({homeLink}){
  let navigate = useNavigate();
  const goHome = goingHome => {
    goingHome.preventDefault();
    navigate(homeLink.link);
  }
  return(
    <button title={homeLink.title} className="header-buttons" type="button" onClick={goHome}>
      <IoHomeOutline className="home-icon"/>
    </button>
  )
}

function ChangePasswordModal(
  {
    isSecurityOnly
  }
){
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [incorrectErrorOpen, setIncorrectErrorOpen] = React.useState(false);
  const [unmatchedErrorOpen, setUnmatchedErrorOpen] = React.useState(false);
  const form = new FormData();
  const submit = passwordSubmit => {
    passwordSubmit.preventDefault();
    if (passwordSubmit.target[2].value === passwordSubmit.target[4].value) {
      if (passwordSubmit.target[0].value !== passwordSubmit.target[2].value){
        form.append('original_password', passwordSubmit.target[0].value);
        form.append('new_password', passwordSubmit.target[2].value);
        axios(
          `${URLORIGIN}/users/me`, {
              baseURL: "",
              method: "PATCH",
              withCredentials: true,
              data: form
            }
        ).catch(error => {
          if (error.response.status === 400) {
            setIncorrectErrorOpen(true);
            passwordSubmit.target[0].value = "";
            passwordSubmit.target[2].value = "";
            passwordSubmit.target[4].value = "";
            return false;
          }
        }).finally(() => setPasswordOpen(false));
      }
    } else {
      setUnmatchedErrorOpen(true);
      passwordSubmit.target[0].value = "";
      passwordSubmit.target[2].value = "";
      passwordSubmit.target[4].value = "";
      return false;
    }
  }
  return (
    <div>
      <button type="button" onClick={() => setPasswordOpen(true)} className="settings-dropdown-item change-password-hyperlink">Change Password</button>
      <Dialog
        open={passwordOpen}
        onClose={() => {setPasswordOpen(false);}}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        autoComplete="off"
      >
        <DialogContent>
          <form id="change-password-form" onSubmit={submit}>
            <div className="password-form-container">
              <label>
                <p className="password-label">
                  Original
                </p>
                <p className="password-colon">
                  :
                </p>
              </label>
              {
                isSecurityOnly
                  ?<input 
                  required 
                  title="no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="OriginalPassword" 
                  id="original-password"
                  placeholder="Pw (no space)" 
                  pattern="[^\s]+"
                  autoFocus/>
                  :<input 
                  required 
                  title="Min. 8 characters, no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="OriginalPassword" 
                  id="original-password"
                  placeholder="Pw (≥8 chars, no space)" 
                  pattern="[^\s]+"
                  minLength={8}
                  autoFocus/>
              }
              <ShowPassword idKey="original" passwordFieldID="original-password"/>
            </div>
            <div className="password-form-container">
              <label>
                <p className="password-label">
                  New
                </p>
                <p className="password-colon">
                  :
                </p>
              </label>
              {
                isSecurityOnly
                  ?<input 
                  required 
                  title="no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="NewPassword" 
                  id="new-password"
                  placeholder="Pw (no space)" 
                  pattern="[^\s]+"
                  autoFocus/>
                  :<input 
                  required 
                  title="Min. 8 characters, no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="NewPassword" 
                  id="new-password"
                  placeholder="Pw (≥8 chars, no space)" 
                  pattern="[^\s]+"
                  minLength={8}
                  autoFocus/>
              }
              <ShowPassword idKey="new" passwordFieldID="new-password"/>
            </div>
            <div className="password-form-container">
              <label>
                <p className="password-label">
                  Confirm
                </p>
                <p className="password-colon">
                  :
                </p>
              </label>
              {
                isSecurityOnly
                  ?<input 
                  required 
                  title="no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="ConfirmPassword" 
                  id="confirm-password"
                  placeholder="Pw (no space)" 
                  pattern="[^\s]+"
                  autoFocus/>
                  :<input 
                  required 
                  title="Min. 8 characters, no space allowed."
                  className="password-form-field"
                  type="password" 
                  name="ConfirmPassword" 
                  id="confirm-password"
                  placeholder="Pw (≥8 chars, no space)" 
                  pattern="[^\s]+"
                  minLength={8}
                  autoFocus/>
              }
              <ShowPassword idKey="confirm" passwordFieldID="confirm-password"/>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button type="submit" form="change-password-form" className="password-submit-button">Confirm</button>
          <button type="button" onClick={()=>setPasswordOpen(false)} className="password-close-button">Cancel</button>
        </DialogActions>
      </Dialog>
      <AlertDialog alertOpen={incorrectErrorOpen} setAlertOpen={setIncorrectErrorOpen} alertText="Incorrect original password." action=""/>
      <AlertDialog alertOpen={unmatchedErrorOpen} setAlertOpen={setUnmatchedErrorOpen} alertText="New and confirm passwords do not match, please re-enter." action=""/>
    </div>
  )
}

function CopyrightFooter(){
  return (
    <footer id="copyright-footer">
      {/* TODO: Add Chinese copyright statement below. */}
      <p>{`Copyright © 2022${new Date().getFullYear()>2022?`–${new Date().getFullYear()}`:""} Hong Kong Ferry (Holdings) Co. Ltd. All rights reserved.`}</p>
      <p>{`版權所有 © 2022${new Date().getFullYear()>2022?`–${new Date().getFullYear()}`:""} 香港小輪（集團）有限公司`}</p>
    </footer>
  )
}