import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import {BsPencilFill} from "react-icons/bs";
import {MdDeleteForever} from "react-icons/md";

import {URLORIGIN} from "../../config.js";
import {FullTable, ChangeColumn, Actions, AlertDialog} from "../../template";
import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';
import { Dialog } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

function fetchRoles(
    setRoleList,
    navigate,
    columnPriority
) {
    var roleListParams = {};
    for (const column of columnPriority){
        if ((column.name) && (column.ascending || column.descending)){
            roleListParams.sort = column.name;
            roleListParams.sort_ascending = column.ascending;
            roleListParams.sort_descending = column.descending;
        }
    }
    axios.get(
        `${URLORIGIN}/roles`, {
            baseURL: "",
            withCredentials: true, 
            params: roleListParams}
    ).then(response => {
        if (response.data) {
            setRoleList(response.data.roles);
        }
    }).catch (error => {
        if (error.response.status === 303) {
            navigate(error.response.data.url)
        }
    });
};

export function RoleList({windowWidthCategory}){
    const navigate = useNavigate();
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [roleList, setRoleList] = React.useState([]);
    const [tableKey, setTableKey] = React.useState(0);
    const [toEditRole, setToEditRole] = React.useState(null);
    const [editRoleOpen, setEditRoleOpen] = React.useState(false);
    const [addRoleOpen, setAddRoleOpen] = React.useState(false);
    const [triggerListRefresh, setTriggerListRefresh] = React.useState(0);
    const [reservedRoleErrorOpen, setReservedRoleErrorOpen] = React.useState(false);
    const [roleExistsErrorOpen, setRoleExistsErrorOpen] = React.useState(false);
    const defaultColumnPriority = [
        {
            name: "role",
            ascending: false,
            descending: false
        }
        // {
        //     name: "modifier",
        //     ascending: false,
        //     descending: false
        // },
        // {
        //     name: "modified_time",
        //     ascending: false,
        //     descending: false
        // }
    ]
    const [columnPriority, setColumnPriority] = React.useState(
        defaultColumnPriority
    );
    const setAndSaveColumnPriority = (updatedColumnPriority) => {
        setColumnPriority(updatedColumnPriority);
        localStorage.setItem(
            `customerColumnPriority`, 
            JSON.stringify(updatedColumnPriority)
        );
        setTableKey(tableKey + 1);
    };
    const tableHeadersDictionary={ 
        "role": "Role"
        // "modifier": "Modifier", 
        // "modified_time": "Modified Time"
    };
    const tableActionHeaders=["Edit", "Delete"];
    const preTableRowButtons=[
        {idKey: "table-create", 
        onClickFunction: (clicked)=>{
            clicked.preventDefault();
            setAddRoleOpen(true);
        }, 
        text: "Add Role"}
    ]
    
    const addRole = roleAdd => {
        roleAdd.preventDefault();
        var newRole = roleAdd.target[0].value.trim()
        newRole = newRole.charAt(0).toUpperCase() + newRole.slice(1).toLowerCase();
        if (newRole.toLowerCase() === "security guard"){
            setAddRoleOpen(false);
            setRoleExistsErrorOpen(true);
        } else axios(
            {
                method: 'POST',
                baseURL: "",
                url: `${URLORIGIN}/users/role`,
                withCredentials: true, 
                data: newRole
            }
        ).then(
            response => {
                setAddRoleOpen(false);
                if (response.data.role_exists){
                    setRoleExistsErrorOpen(true);
                } else{
                    var adminRoles = JSON.parse(
                        window.sessionStorage.getItem(
                            "adminRoles"
                        )
                    );
                    adminRoles.push(newRole);
                    window.sessionStorage.setItem(
                        "adminRoles", 
                        JSON.stringify(
                            adminRoles
                        )
                    );
                    const roles = JSON.parse(window.sessionStorage.getItem("roles"));
                    roles.push(newRole);
                    window.sessionStorage.setItem("roles", JSON.stringify(roles));
                    setTriggerListRefresh(triggerListRefresh + 1);
                }
            }
        )
    }

    const editRole = roleEdit => {
        roleEdit.preventDefault();
        var newRole = document.getElementById("edit-role-form-field").value.trim();
        newRole = newRole.charAt(0).toUpperCase() + newRole.slice(1).toLowerCase();
        if (newRole.toLowerCase() === "security guard"){
            setToEditRole(null);
            setReservedRoleErrorOpen(true);
        } else axios(
            `${URLORIGIN}/roles/${toEditRole.uid}`, {
                baseURL: "",
                method: "PATCH",
                withCredentials: true,
                params: {
                    new_role_name: newRole
                }
            }
        ).then(
            () => {
                var adminRoles = JSON.parse(
                    window.sessionStorage.getItem(
                        "adminRoles"
                    )
                ).filter(role=>{return role !== toEditRole.role})
                adminRoles.push(newRole)
                window.sessionStorage.setItem(
                    "adminRoles", 
                    JSON.stringify(
                        adminRoles
                    )
                );

                var roles = JSON.parse(window.sessionStorage.getItem("roles")).filter(role=>{return role !== toEditRole.role});
                roles.push(newRole);
                window.sessionStorage.setItem("roles", JSON.stringify(roles));
                setTriggerListRefresh(triggerListRefresh + 1);
                setToEditRole(null);
            }
        ).catch(
            error => {console.log(error);setRoleExistsErrorOpen(true)}
        );
    };

    const deleteRole = role_info => {
        axios.delete(
            `${URLORIGIN}/roles/${role_info.uid}`, {
                baseURL: "",
                withCredentials: true
            }
        ).then(
            () => {
                var adminRoles = JSON.parse(
                    window.sessionStorage.getItem(
                        "adminRoles"
                    )
                ).filter(role=>{return role !== role_info.role})
                window.sessionStorage.setItem(
                    "adminRoles", 
                    JSON.stringify(
                        adminRoles
                    )
                );
                var roles = JSON.parse(window.sessionStorage.getItem("roles"));
                roles.filter(role=>{return role !== role_info.role});
                window.sessionStorage.setItem("roles", JSON.stringify(roles));
                setTriggerListRefresh(triggerListRefresh + 1);
            }
        );
    };

    const tableHeader = (
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount
    ) => {
        return(
            <RoleHeader 
                columnPriority={columnPriority} 
                setAndSaveColumnPriority={setAndSaveColumnPriority}
                windowWidthCategory={windowWidthCategory}
                columnCount={columnCount}
                tableHeadersDictionary={tableHeadersDictionary}
                tableActionHeaders={tableActionHeaders}
            />
        )
    }

    React.useEffect(
        ()=>setEditRoleOpen(!!toEditRole), [toEditRole]
    );

    React.useEffect(
        ()=>setTableKey(tableKey + 1), [roleList]
    );

    const actionCells = row => {return([
        <button
        title="Edit Role"
        type="button"
        className="buttons-without-borders"
        onClick={roleEdit=>{roleEdit.preventDefault(); setToEditRole(row);}}>
            <BsPencilFill  className="action-icons pencil-icon"/>
        </button>,
        <button
        title="Delete Role"
        type="button"
        className="buttons-without-borders"
        onClick={roleDelete=>{roleDelete.preventDefault(); deleteRole(row);}}>
            <MdDeleteForever  className="action-icons delete-icon"/>
        </button>])};
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            <RoleRow key={`user-${rowIndex}`}
                row={row}
                rowIndex={rowIndex}
                columnPriority={columnPriority}
                columnCount={columnCount}
                windowWidthCategory={windowWidthCategory}
                actionCells={actionCells}/>
        )
    }
    React.useEffect(
        ()=>{
            fetchRoles(
                setRoleList, 
                navigate,
                columnPriority
            );
        }, 
        [columnPriority, triggerListRefresh]
    )

    return(
        <article id="user-list-entire-container">
            <FullTable  
                tableKey={tableKey}
                title="Roles"
                rowCount={rowCount} 
                page={page} 
                setRowCount={setRowCount} 
                setPage={setPage} 
                totalRowCount={roleList?roleList.length:0}
                data={
                    roleList.slice(
                        (page - 1) * rowCount, 
                        page * rowCount
                    )
                }
                defaultColumnPriority={defaultColumnPriority}
                columnPriorityKey="role"
                windowWidthCategory={windowWidthCategory}
                preTableRowButtons={preTableRowButtons}
                tableRow={tableRow}
                tableHeader={tableHeader}
                defaultColumnCount={1}
                columnPriority={columnPriority}
                setAndSaveColumnPriority={setAndSaveColumnPriority}
            />
            <Dialog
              open={addRoleOpen}
              onClose={() => {setAddRoleOpen(false);}}
              aria-labelledby='alert-dialog-title'
              aria-describedby='add-role-form'
            >
              <DialogContent>
                <form id="add-role-form" onSubmit={addRole}>
                  <div id="add-role-form-container">
                    <label>
                      New role: 
                    </label>
                    <input 
                      required 
                      id="add-role-form-field"
                      type="text" 
                      name="NewRole" 
                      placeholder="Role" 
                      autoFocus/>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <button type="submit" form="add-role-form" id="alert-first-button" className="alert-button">Confirm</button>
                <button type="button" id="alert-second-button" onClick={()=>setAddRoleOpen(false)} className="alert-button">Cancel</button>
              </DialogActions>
            </Dialog>
            <AlertDialog 
                alertOpen={roleExistsErrorOpen} 
                setAlertOpen={setRoleExistsErrorOpen} 
                alertText="Role already existed." 
                action=""
            />
            <AlertDialog
                alertOpen={reservedRoleErrorOpen}
                setAlertOpen={setReservedRoleErrorOpen}
                alertText="Role 'Security guard' is reserved."
                action=""
            />
            <Dialog
                open={editRoleOpen}
                onClose={()=>setEditRoleOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="edit-role-form"
            >
                <DialogContent>
                    <form id="edit-role-form" onSubmit={editRole}>
                        <div id="edit-role-form-container">
                            <input
                                required
                                id="edit-role-form-field"
                                type="text"
                                name="Role"
                                defaultValue={toEditRole?toEditRole.role:""}
                                placeholder="Role"
                                autoFocus
                            />
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <button type="submit" form="edit-role-form" id="alert-first-button" className="alert-button">Confirm</button>
                    <button type="buton" id="alert-second-button" onClick={()=>setToEditRole(null)} className="alert-button">Cancel</button>
                </DialogActions>
            </Dialog>
        </article>
    )
}

function RoleHeader(
    {columnPriority, 
    setAndSaveColumnPriority, 
    windowWidthCategory, 
    columnCount,
    tableHeadersDictionary,
    tableActionHeaders}
    ){
    return(
        <thead id="table-header">
            <tr>
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column, columnIndex) => {
                            return <ChangeColumn 
                                key={`rendered-${column}-header`}
                                column={column}
                                columnIndex={columnIndex}
                                columnPriority={columnPriority} 
                                tableHeadersDictionary={tableHeadersDictionary}
                                setAndSaveColumnPriority={setAndSaveColumnPriority}
                                noSort={false}
                            />
                        }
                    )
                }
                {(windowWidthCategory === "lg" || windowWidthCategory === "md" || windowWidthCategory.includes("xl")) 
                ? tableActionHeaders.map(
                    (action) => {
                        return (
                            <th key={`render-${action}-header`}>
                                {action}
                            </th>
                        )
                    }
                ) 
                :<th>
                    Action
                </th>
                }
            </tr>
        </thead>
    )
}

function RoleRow(
    {row, 
    rowIndex, 
    columnPriority, 
    columnCount, 
    windowWidthCategory, 
    actionCells}){
    return(
        <tr>
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    (column) =>{
                        return <td key={`user-${rowIndex}-${column.name}`}>
                            {row[column.name]}
                        </td>
                    }
                )
            }
            {(windowWidthCategory === "lg" || windowWidthCategory === "md" || windowWidthCategory.includes("xl")) 
            ? actionCells(row).map(
                (actionContainer, actionIndex)=>{
                    return (
                        <td key={`user-action-${actionIndex}`}>
                            {actionContainer}
                        </td>
                    )
                }
            ) 
            : <Actions actionCells={actionCells(row)}/>
            }
        </tr>
    )
}