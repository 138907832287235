import {useParams, useNavigate} from 'react-router-dom';
import React from 'react';
import axios from 'axios';

import {MdDeleteForever} from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import {URLORIGIN} from '../../config.js';
import {
    ReadonlyField, 
    AlertDialog, 
    TopRow,
    FullTable
} 
    from '../../template';
import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';

export function EditVessels({windowWidthCategory}){
    const navigate = useNavigate();
    const {customer_no} = useParams();
    const [firstRender, setFirstRender] = React.useState(true);
    const [customerName, setCustomerName] = React.useState("")
    const [deleteShipOpen, setDeleteShipOpen] = React.useState(false);
    const [oldShipListWithDelete, setOldShipListWithDelete] = React.useState([]);
    const [oldShipListWithoutDelete, setOldShipListWithoutDelete] = React.useState([]);
    const [shipList, setShipList] = React.useState([]);
    const [shipToDeleteList, setShipToDeleteList] = React.useState([]);
    const [currentToDeleteShip, setCurrentToDeleteShip] = React.useState(
        {
            shipIndex: NaN,
            ship: {
                ship_name: "", 
                ship_reg_no: "", 
                uid: ""
            }
        });
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [temp, setTemp] = React.useState(0);
    const [deleteTemp, setDeleteTemp] = React.useState(0);
    const [otherCustomerShips, setOtherCustomerShips] = React.useState(
        [{customer_no: "", ship_reg_no: ""}]);
    const [otherCustomerShipsOpen, setOtherCustomerShipsOpen] = React.useState(false);
    const [changeCount, setChangeCount] = React.useState(0);


    const resetShips = shipReset => {
        shipReset.preventDefault();
        setOldShipListWithDelete(JSON.parse(JSON.stringify(oldShipListWithoutDelete)));
        oldShipListWithoutDelete.push({
            ship_name: "", 
            ship_reg_no: "", 
            uid: ""
        })
        setShipList(
            JSON.parse(JSON.stringify(oldShipListWithoutDelete))
        );
        setShipToDeleteList([]);
        setDeleteTemp(deleteTemp+1);
    }
    const fetchShips = ()=>{
        axios.get(
            `${URLORIGIN}/customers/ships/${customer_no}`, {
                baseURL: "",
                withCredentials: true
            }
        ).then(response => {
            setCustomerName(response.data.customer_name);
            setOldShipListWithoutDelete(
                JSON.parse(
                    JSON.stringify(
                        response.data.ships
                    )
                )
            );
            setOldShipListWithDelete(
                JSON.parse(
                    JSON.stringify(
                        response.data.ships
                    )
                )
            );
            var fetchedShipList = JSON.parse(
                JSON.stringify(
                    response.data.ships
                )
            );
            fetchedShipList.push({
                ship_name: "", 
                ship_reg_no: "", 
                uid: ""
            });
            setShipList(fetchedShipList);
            setChangeCount(changeCount + 1);
            setTemp(temp+1);
            setDeleteTemp(deleteTemp+1);
        }).catch(error => {
            if (error.response.status === 303) {
                navigate(error.response.data.url)
            }
        }).finally(() => {
            setFirstRender(false);
        });
    }
    const updateShips = shipUpdate => {
        shipUpdate.preventDefault();
        var patchShips = [];
        oldShipListWithDelete.forEach(
            (ship, shipIndex) =>{
                if (
                    ship.ship_name !== shipList[shipIndex].ship_name
                    || ship.ship_reg_no !== shipList[shipIndex].ship_reg_no
                ){
                    patchShips.push(shipList[shipIndex]);
                }
            }
        )
        axios({
            baseURL: "",
            withCredentials: true, 
            method: "PATCH",
            url: `${URLORIGIN}/customers/ships/${customer_no}`,
            data: {
                add_ships: (shipList.length-oldShipListWithDelete.length>1)
                    ?shipList.slice(oldShipListWithDelete.length, shipList.length - 1)
                    :[],
                patch_ships: patchShips,
                delete_ships: shipToDeleteList
            }
        }).then(
            response => {
                setShipToDeleteList([]);
                if (response.data){
                    if (response.data[0]){
                        setOtherCustomerShips(response.data);
                    }
                }
                fetchShips();
            }
        )
    }
    const deleteShip = shipDelete => {
        shipDelete.preventDefault();
        oldShipListWithoutDelete.splice(
            currentToDeleteShip.shipIndex, 
            1
        );
        setOldShipListWithoutDelete(oldShipListWithoutDelete);
        oldShipListWithDelete.splice(
            currentToDeleteShip.shipIndex, 
            1
        )
        setOldShipListWithDelete(oldShipListWithDelete);
        shipList.splice(
            currentToDeleteShip.shipIndex, 
            1
        )
        setShipList(shipList);
        if (currentToDeleteShip.ship.uid){
            axios({
                baseURL: "",
                withCredentials: true,
                method: "DELETE",
                url: `${URLORIGIN}/customers/ships/${customer_no}`,
                data: {
                    ship_uid: currentToDeleteShip.ship.uid
                }
            });
        }
        setCurrentToDeleteShip(
            {
                shipIndex: NaN,
                ship:{
                    ship_name: "",
                    ship_reg_no: "",
                    uid: ""
                }
            }
        )
        setDeleteShipOpen(false);
        setTemp(temp+1);
        setDeleteTemp(deleteTemp+1);
    }
    const tableRow = (row, rowIndex) =>{
        return(
            <ShipRow
            row={row} 
            rowIndex={rowIndex} 
            rowCount={rowCount}
            deleteTemp={deleteTemp}
            setDeleteTemp={setDeleteTemp}
            page={page} 
            oldShipListWithDelete={oldShipListWithDelete} 
            setOldShipListWithDelete={setOldShipListWithDelete}
            shipList={shipList}
            setShipList={setShipList}
            temp={temp}
            setTemp={setTemp}
            shipToDeleteList={shipToDeleteList}
            setShipToDeleteList={setShipToDeleteList}
            setCurrentToDeleteShip={setCurrentToDeleteShip}
            setDeleteShipOpen={setDeleteShipOpen}
            changeCount={changeCount}
            />
        )
    }
    React.useEffect(fetchShips, [])
    React.useEffect(()=>{
        if (otherCustomerShips[0] && !firstRender){
            setOtherCustomerShipsOpen(true);
        }
    }, [otherCustomerShips]);
    React.useEffect(()=>{
        console.log("customer ships changed");
        if (otherCustomerShips[0] && !firstRender){
            setOtherCustomerShipsOpen(true);
        }
    }, [otherCustomerShips]);
// TODO: Make vessel reg. no. editable.
    React.useEffect(
        ()=>{
            console.log("Old ship list refreshed.");
            setChangeCount(changeCount + 1);
        }, [setOldShipListWithoutDelete]
    )
    return (
        <article id="add-edit-customer-container">
            <TopRow 
            headerText="Edit Vessels" 
            buttons={[
                {
                    idKey: "text-save",
                    text: "Update",
                    onClickFunction: updateShips
                },
                {
                    idKey: "text-reset",
                    text: "Reset",
                    onClickFunction: resetShips
                },
                {
                    idKey: "text-back",
                    text: "Back",
                    onClickFunction: clicked =>{
                        clicked.preventDefault();
                        navigate(`/customers/edit/${customer_no}`);
                    }
                }
            ]}/>
            <CustomerDetails
            customerNo={customer_no}
            customerName={customerName}
            />
            <FullTable
            classKey={"vessels-table"}
            tableKey={changeCount}
            rowCount={rowCount}
            page={page}
            setRowCount={setRowCount}
            setPage={setPage}
            totalRowCount={shipList.length}
            data={
                shipList
                ?shipList.slice(
                    (page - 1) * rowCount,
                    page * rowCount
                )
                :[]
            }
            defaultColumnPriority={[]}
            columnPriorityKey="editable-ships"
            windowWidthCategory={windowWidthCategory}
            preTableRowButtons=""
            tableRow={tableRow}
            tableHeader={
                ()=>
                <thead id="table-header">
                    <tr>
                        <th>Vessel Reg. No.</th>
                        <th>Vessel Name</th>
                        <th>Delete</th>
                    </tr>
                </thead>
            }
            defaultColumnCount={3}
            columnPriority={[]}
            />
            <AlertDialog
            alertOpen={deleteShipOpen}
            setAlertOpen={setDeleteShipOpen}
            alertText={
                `Confirm decommissioning vessel of ${
                    currentToDeleteShip.ship.ship_name && currentToDeleteShip.ship.ship_reg_no
                    ?`vessel registration number ${currentToDeleteShip.ship.ship_reg_no} and vessel name ${currentToDeleteShip.ship.ship_name}?`
                    :currentToDeleteShip.ship.ship_reg_no
                    ?`vessel registration number ${currentToDeleteShip.ship.ship_reg_no}?`
                    :`vessel name ${currentToDeleteShip.ship.ship_name}?`
                }`}
            action={deleteShip}/>
            <Dialog
            open={otherCustomerShipsOpen}
            onClose={() => setOtherCustomerShipsOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            >
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {
                        `Vessel 
                        ${otherCustomerShips[0]?otherCustomerShips[0].ship_reg_no:""} 
                        is already registered with customer 
                        ${otherCustomerShips[0]?otherCustomerShips[0].customer_no:""}.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button 
                type="button" 
                onClick={
                    () => {
                        if (otherCustomerShips.length === 1)setOtherCustomerShipsOpen(false);
                        otherCustomerShips.splice(0, 1);
                        setOtherCustomerShips(otherCustomerShips);
                    }
                } 
                id="alert-first-button" 
                className="alert-button" 
                autoFocus>
                    OK
                </button>
            </DialogActions>
            </Dialog>
        </article>
    )
}

function CustomerDetails(
    {
        customerNo,
        customerName
    }
){
    return(
        <form
        id="add-edit-customer-form">
            <div className="dual-fields-container">
                <ReadonlyField 
                value={customerNo} 
                label="Customer ID" 
                idKey="customer-no" 
                placeholder="Customer ID" 
                fullLength="" 
                cssClass="left-field short-label"/>
                <ReadonlyField 
                value={customerName} 
                label="Customer Name" 
                idKey="customer-name" 
                placeholder="Customer Name" 
                fullLength="" 
                cssClass="right-field"/>
            </div>
        </form>
    )
}

function ShipRow(
    {
        row,
        rowIndex,
        rowCount,
        page,
        deleteTemp,
        setDeleteTemp,
        oldShipListWithDelete,
        setOldShipListWithDelete,
        shipList,
        setShipList,
        temp,
        setTemp,
        shipToDeleteList,
        setShipToDeleteList,
        setCurrentToDeleteShip,
        setDeleteShipOpen
    }
){
    return(
        <tr className="ship-edit-row" key={`ship-${page}-${rowIndex}-${deleteTemp}`}>
            <td>
                {
                    oldShipListWithDelete[rowIndex]
                        ?<EditableShipField
                        idKey="ship-reg-no"
                        field="ship_reg_no"
                        otherField="ship_name"
                        page={page}
                        rowCount={rowCount}
                        rowIndex={rowIndex}
                        placeholder="Vessel Reg. No."
                        defaultValue={row.ship_reg_no}
                        shipList={shipList}
                        setShipList={setShipList}
                        setTemp={setTemp}
                        temp={temp}
                        deleteTemp={deleteTemp}
                        setDeleteTemp={setDeleteTemp}
                        shipToDeleteList={shipToDeleteList}
                        setShipToDeleteList={setShipToDeleteList}
                        oldShipListWithDelete={oldShipListWithDelete}
                        setOldShipListWithDelete={setOldShipListWithDelete}
                        />
                        :<EditableShipField
                        idKey="ship-reg-no"
                        field="ship_reg_no"
                        otherField="ship_name"
                        page={page}
                        rowCount={rowCount}
                        rowIndex={rowIndex}
                        placeholder="Vessel Reg. No."
                        defaultValue={row.ship_reg_no}
                        shipList={shipList}
                        setShipList={setShipList}
                        setTemp={setTemp}
                        temp={temp}
                        deleteTemp={deleteTemp}
                        setDeleteTemp={setDeleteTemp}
                        shipToDeleteList={shipToDeleteList}
                        setShipToDeleteList={setShipToDeleteList}
                        oldShipListWithDelete={oldShipListWithDelete}
                        setOldShipListWithDelete={setOldShipListWithDelete}
                    />
                }
            </td>
            <td>
                <EditableShipField
                idKey="ship-name"
                field="ship_name"
                otherField="ship_reg_no"
                page={page}
                rowCount={rowCount}
                rowIndex={rowIndex}
                placeholder="Vessel Name"
                defaultValue={row.ship_name}
                shipList={shipList}
                setShipList={setShipList}
                setTemp={setTemp}
                temp={temp}
                deleteTemp={deleteTemp}
                setDeleteTemp={setDeleteTemp}
                shipToDeleteList={shipToDeleteList}
                setShipToDeleteList={setShipToDeleteList}
                oldShipListWithDelete={oldShipListWithDelete}
                setOldShipListWithDelete={setOldShipListWithDelete}
                />
            </td>
            <td>
                {
                    (rowIndex===(shipList.length - 1))
                        ?null
                        :<button 
                            title="Delete Ship" 
                            type="button" 
                            className="buttons-without-borders" 
                            onClick={
                                deleteShipClicked => {
                                    deleteShipClicked.preventDefault();
                                    setCurrentToDeleteShip({
                                        shipIndex: rowIndex,
                                        ship: row
                                    })
                                    setDeleteShipOpen(true);
                                }
                            }
                        >
                        <MdDeleteForever 
                        className="action-icons delete-icon"/>
                    </button>
                }
            </td>
        </tr>
    )
}

function EditableShipField({
    idKey,
    field,
    otherField,
    page,
    rowCount,
    rowIndex,
    placeholder,
    defaultValue,
    shipList,
    setShipList,
    setTemp,
    temp,
    deleteTemp,
    setDeleteTemp,
    shipToDeleteList,
    setShipToDeleteList,
    oldShipListWithDelete,
    setOldShipListWithDelete
}){
    const shipIndex = (page-1) * rowCount + rowIndex;
    return(
        <input
            id={`${idKey}-${page}-${rowIndex}`}
            type="text"
            placeholder={placeholder}
            defaultValue={defaultValue}
            onInput={
                shipFieldInput => {
                    shipFieldInput.preventDefault();
                    if (shipFieldInput.target.value || shipList[shipIndex][otherField]){
                        if (shipIndex === shipList.length - 1){
                            shipList.push({
                                ship_name: "", 
                                ship_reg_no: "", 
                                uid: ""
                            });
                            setTemp(temp+1);
                        }
                        if (field === "ship_reg_no"){
                            shipList.splice(
                                shipIndex, 
                                1, 
                                {
                                    uid: shipList[shipIndex].uid, 
                                    ship_reg_no: shipFieldInput.target.value, 
                                    ship_name: shipList[shipIndex].ship_name
                                });
                        } else {
                            shipList.splice(
                                shipIndex, 
                                1, 
                                {
                                    uid: shipList[shipIndex].uid, 
                                    ship_reg_no: shipList[shipIndex].ship_reg_no, 
                                    ship_name: shipFieldInput.target.value
                                });
                        }
                    } else {
                        if (shipList[shipIndex].uid){
                            shipToDeleteList.push(shipList[shipIndex].uid);
                            setShipToDeleteList(shipToDeleteList);
                            oldShipListWithDelete.splice(shipIndex, 1)
                            setOldShipListWithDelete(oldShipListWithDelete);
                        }
                        shipList.splice(shipIndex, 1);
                        setTemp(temp+1);
                        setDeleteTemp(deleteTemp+1);
                    }
                    setShipList(shipList);
                }
            }
        />
    )
}