import React from "react";
import axios from "axios";
import {useNavigate, Link} from "react-router-dom";

import qs from "qs";
import {BsSearch} from "react-icons/bs";
import {BsPencilFill} from "react-icons/bs";
import {MdOutlineCloudUpload, MdDeleteForever} from "react-icons/md";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {URLORIGIN} from "../../config.js";
import {ChooseFromList, CalendarButton, AlertDialog, FullTable, ChangeColumn, Actions} from "../../template";
import {dateYmd, dateYmdhms} from "../../functions";
import "react-calendar/dist/Calendar.css";
import "./overview.scss";
import './create.scss';

function fetchJobs(
    reRender, 
    {
        roles, 
        rowCount, 
        page, 
        timePassed, 
        dateAfter, 
        dateBefore, 
        jobStatus, 
        refreshInterval, 
        setJobList, 
        setTotalJobCount, 
        setTimePassed, 
        setFirstRender,
        navigate,
        setTableKey,
        tableKey
    },
    columnPriority
) {
    const jobListParams = {
        roles: roles, 
        row_count: rowCount, 
        page: page, 
        time_passed_since_last_refresh: timePassed, 
        first_render: reRender}
    if (document.getElementById("job-id-input").value !== null){
        jobListParams.job_id = document.getElementById("job-id-input").value;
    }
    if (document.getElementById("ship-name-input").value !== null){
        jobListParams.ship_name = document.getElementById("ship-name-input").value;
    }
    if (document.getElementById("customer-name-input").value !== null){
        jobListParams.customer_name = document.getElementById("customer-name-input").value;
    }
    if (document.getElementById("ship-reg-no-input").value !== null){
        jobListParams.ship_reg_no = document.getElementById("ship-reg-no-input").value;
    }
    if (dateAfter !== null){
        jobListParams.date_after = dateYmd(dateAfter);
    }
    if (dateBefore !== null){
        jobListParams.date_before = dateYmd(dateBefore);
    }
    if (jobStatus !== null){
        jobListParams.status = jobStatus;
    }
    for (const column of columnPriority){
        if (column.ascending || column.descending){
            jobListParams.sort = column.name;
            jobListParams.sort_ascending = column.ascending;
            jobListParams.sort_descending = column.descending;
        }
    }
    axios.get(
        `${URLORIGIN}/jobs`, {
            baseURL: "",
            withCredentials: true, 
            params: jobListParams,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        }
    ).then(response => {
        if (response.data.job_count_estimate !== null) {
            setJobList(response.data.job_list);
            window.sessionStorage.setItem("jobList", JSON.stringify(response.data.job_list));
            setTotalJobCount(response.data.job_count_estimate);
            window.sessionStorage.setItem("jobCount", response.data.job_count_estimate);
        }
        setTimePassed(refreshInterval);
        window.sessionStorage.setItem("jobOverviewTimestamp", Date.now());
        setTableKey(tableKey + 1);
    }).catch (error => {
        console.log(error.response);
        if (error.response.status === 303) {
            navigate(error.response.data.url)
        }
    }).finally(() => {
        setFirstRender(false);
    })
};

function filterJobList(
    {
        inputDateAfter, 
        inputDateBefore, 
        inputStatus, 
        inputJobId, 
        inputShipName,
        inputCustomerName,
        inputShipRegNo
    }, 
    {
        setDateAfter,
        setDateBefore,
        setJobStatus,
        setJobId,
        setShipName,
        setCustomerName,
        setShipRegNo,
        setPage
    }){
    setDateAfter(inputDateAfter);
    setDateBefore(inputDateBefore);
    setJobStatus(inputStatus);
    setJobId(inputJobId);
    setShipName(inputShipName);
    setCustomerName(inputCustomerName);
    setShipRegNo(inputShipRegNo);
    setPage(1);
    document.getElementById('page-input-1').value = 1;
    document.getElementById('page-input-2').value = 1;
}

function formatJobListDownloadLink({formattedDate, roles, jobId, shipName, customerName, shipRegNo, dateAfter, dateBefore, jobStatus, columnPriority}){
    var formattedDownloadLink = `${URLORIGIN}/jobs/download/${formattedDate}.xls?`;
    if (roles)for (const role of roles)formattedDownloadLink += `&roles=${role}`;
    if (document.getElementById("job-id-input").value)formattedDownloadLink += `&job_id=${document.getElementById("job-id-input").value}`;
    if (document.getElementById("ship-name-input").value)formattedDownloadLink += `&ship_name=${document.getElementById("ship-name-input").value}`;
    if (document.getElementById("customer-name-input").value)formattedDownloadLink += `&customer_name=${document.getElementById("customer-name-input").value}`;
    if (document.getElementById("ship-reg-no-input").value)formattedDownloadLink += `&ship_reg_no=${document.getElementById("ship-reg-no-input").value}`;
    if (dateAfter !== null)formattedDownloadLink += `&date_after=${dateYmd(dateAfter)}`;
    if (dateBefore !== null)formattedDownloadLink += `&date_before=${dateYmd(dateBefore)}`;
    if (jobStatus)formattedDownloadLink += `&status=${jobStatus}`;
    if (columnPriority)for (const column of columnPriority){
        if (column.ascending || column.descending)formattedDownloadLink += `&sort=${column.name}&sort_ascending=${column.ascending}&sort_descending=${column.descending}`;
    }
    return formattedDownloadLink;
}

export default function JobOverview({windowWidthCategory}){
    const refreshInterval = 30;
    const [timePassed, setTimePassed] = React.useState(
        (window.sessionStorage.getItem("jobOverviewTimestamp") === null) ? 
        refreshInterval : 
        (Date.now() - window.sessionStorage.getItem("jobOverviewTimestamp")) / 1000)
    const [jobList, setJobList] = React.useState(JSON.parse(window.sessionStorage.getItem("jobList")));
    const [rowCount, setRowCount] = React.useState(
        (localStorage.getItem('rowCount') === null) ?
        10 : 
        localStorage.getItem('rowCount'));
    const [page, setPage] = React.useState(1);
    const [firstRender, setFirstRender] = React.useState(
        window.sessionStorage.getItem("jobList") === null);
    const [totalJobCount, setTotalJobCount] = React.useState(window.sessionStorage.getItem("jobCount"))
    const roles = window.sessionStorage.getItem('roles')?JSON.parse(window.sessionStorage.getItem('roles')):[];
    const [jobId, setJobId] = React.useState("");
    const [shipName, setShipName] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [shipRegNo, setShipRegNo] = React.useState("");
    const [dateAfter, setDateAfter] = React.useState(null);
    const [dateBefore, setDateBefore] = React.useState(null);
    const [jobStatus, setJobStatus] = React.useState("");
    const navigate = useNavigate();
    const [tableKey, setTableKey] = React.useState(0);
    const formattedDate = dateYmdhms(new Date());
    const [exportJobListLink, setExportJobListLink] = React.useState(`/jobs/download/${formattedDate}.xls`);
    const [totalJobIds, setTotalJobIds] = React.useState([]);
    const [totalShipNames, setTotalShipNames] = React.useState([]);
    const [totalCustomerNames, setTotalCustomerNames] = React.useState([]);
    const [totalShipRegNos, setTotalShipRegNos] = React.useState([]);
    const defaultColumnPriority = [
        {
            name: "job_id",
            ascending: false,
            descending: false
        },
        {
            name: "creation_date",
            ascending: false,
            descending: false
        },
        {
            name: "ship_name",
            ascending: false,
            descending: false
        },
        {
            name: "customer_name",
            ascending: false,
            descending: false
        },
        {
            name: "creator",
            ascending: false,
            descending: false
        },
        {
            name: "job_description",
            ascending: false,
            descending: false
        },
        {
            name: "job_status",
            ascending: false,
            descending: false
        },
        {
            name: "job_start",
            ascending: false,
            descending: false
        },
        {
            name: "job_end",
            ascending: false,
            descending: false
        },
        {
            name: "ship_reg_no",
            ascending: false,
            descending: false
        },
        {
            name: "roles",
            ascending: false,
            descending: false
        },
        {
            name: "last_modified_date",
            ascending: false,
            descending: false
        },
        {
            name: "last_modified_person",
            ascending: false,
            descending: false
        }
    ];
    const [columnPriority, setColumnPriority] = React.useState(defaultColumnPriority);
    const setAndSaveColumnPriority = (updatedColumnPriority) => {
        setColumnPriority(updatedColumnPriority);
        localStorage.setItem(
            `jobColumnPriority`, 
            JSON.stringify(updatedColumnPriority)
        );
        setTableKey(tableKey + 1);
    };
    const tableHeadersDictionary={ 
        "job_id": "Job ID",
        "job_status": "Job Status", 
        "creation_date": "Creation Date", 
        "job_description": "Job Description", 
        "customer_name": "Customer Name", 
        "ship_name": "Vessel Name",
        "job_start": "Start Date",
        "job_end": "End Date",
        "last_modified_date": "Modified Date",
        "last_modified_person": "Modifier",
        "ship_reg_no": "Ship Reg. No.",
        "roles": "Role",
        "creator": "Creator"
    };
    const isAdmin = window.sessionStorage.getItem("adminRoles")?JSON.parse(window.sessionStorage.getItem("adminRoles"))[0]?true:false:false;

    const formatJobListDownloadLinkParameters = {
        formattedDate: formattedDate, 
        roles: roles, 
        jobId: jobId, 
        shipName: shipName, 
        customerName: customerName,
        shipRegNo: shipRegNo,
        dateAfter: dateAfter, 
        dateBefore: dateBefore, 
        jobStatus: jobStatus}
    
    const filterJobListSetters={
        setDateAfter: setDateAfter,
        setDateBefore: setDateBefore,
        setJobStatus: setJobStatus,
        setJobId: setJobId,
        setShipName: setShipName,
        setCustomerName: setCustomerName,
        setShipRegNo: setShipRegNo,
        setPage: setPage
    }
    const fetchJobParams={
        roles: roles, 
        rowCount: rowCount, 
        page: page, 
        timePassed: timePassed, 
        jobId: jobId, 
        shipName: shipName, 
        customerName: customerName,
        shipRegNo: shipRegNo,
        dateAfter: dateAfter, 
        dateBefore: dateBefore, 
        jobStatus: jobStatus,
        refreshInterval: refreshInterval, 
        setJobList: setJobList, 
        setTotalJobCount: setTotalJobCount, 
        setTimePassed: setTimePassed, 
        setFirstRender: setFirstRender,
        navigate: navigate,
        setTableKey: setTableKey,
        tableKey: tableKey
    }

    const adminActionCells = row => {return([
        <button
        title="Edit job"
        type="button"
        className="buttons-without-borders"
        onClick={()=>navigate(`/jobs/edit/${row.job_id}`)}>
            <BsPencilFill  className="action-icons pencil-icon"/>
        </button>,
        // <button
        // title="Upload documents"
        // type="button"
        // className="buttons-without-borders"
        // onClick={()=>navigate(`/jobs/uploads/${row.job_id}`)}>
        //     <MdOutlineCloudUpload  className="action-icons cloud-icon"/>
        // </button>,
        JSON.parse(window.sessionStorage.getItem("adminRoles")).includes(row.roles[0])
        ?<DeleteJob 
            job={row} 
            fetchJobs={fetchJobs} 
            fetchJobParams={fetchJobParams} 
            columnPriority={columnPriority}
        />
        :null])};
    const actionCells = row => isAdmin
        ?adminActionCells(row)
        :adminActionCells(row).slice(
            0, 2);
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            <JobRow key={`job-${rowIndex}`}
                row={row}
                rowIndex={rowIndex}
                columnPriority={columnPriority}
                columnCount={columnCount}
                windowWidthCategory={windowWidthCategory}
                actionCells={actionCells}
            />
        )
    }
    const tableHeader = (
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount
    ) => {
        return(
            <JobHeader 
            columnPriority={columnPriority} 
            setAndSaveColumnPriority={setAndSaveColumnPriority}
            columnCount={columnCount}
            tableHeadersDictionary={tableHeadersDictionary}
            />
        )
    }

    const exportJobList = jobListExport => {
        jobListExport.preventDefault();
        const win = window.open(exportJobListLink, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    const createJob = createJobClicked => {
        createJobClicked.preventDefault();
        navigate("/jobs/create");
    }
    const preTableRowButtons=[
        {
            idKey: "table-create", 
            onClickFunction: createJob, 
            text: "Create"
        }, 
        {
            idKey: "table-download", 
            onClickFunction: exportJobList, 
            text: jobId || shipName || customerName || shipRegNo || dateAfter || dateBefore || jobStatus 
            ? "Export Filtered List" 
            : "Export Job List"
        }
    ];

    React.useEffect(() => {
        const formattedDownloadLink= formatJobListDownloadLink(formatJobListDownloadLinkParameters);
        setExportJobListLink(formattedDownloadLink);
    }, [jobId, shipName, customerName, shipRegNo, dateAfter, dateBefore, jobStatus]);
    React.useEffect(()=>{
        setTableKey(tableKey + 1);
    }, [rowCount, page])
    React.useEffect(() => {
        fetchJobs(firstRender, fetchJobParams, columnPriority);
        axios.get(
            `${URLORIGIN}/jobs/suggestions`, {
                baseURL: "",
                withCredentials: true}
        ).then(
            response => {
                setTotalJobIds(response.data.job_ids);
                setTotalShipNames(response.data.ship_names);
                setTotalCustomerNames(response.data.customer_names);
                setTotalShipRegNos(response.data.ship_reg_nos);
            }
        )
    }, []);
    React.useEffect(() => {
        const effectInterval = setInterval(() => {
            fetchJobs(false, fetchJobParams, columnPriority);
        }, refreshInterval * 1000);
        return () => clearInterval(effectInterval)
        }, [rowCount, page, jobId, shipName, customerName, shipRegNo, dateAfter, dateBefore, jobStatus, columnPriority]);
    React.useEffect(() => {
        fetchJobs(true, fetchJobParams, columnPriority);
    }, [rowCount, page, jobId, shipName, customerName, shipRegNo, dateAfter, dateBefore, jobStatus, columnPriority]);
    
    return(
        <article id="job-overview-entire-container">
            <JobFilter 
                filterJobList={filterJobList} 
                filterJobListSetters={filterJobListSetters} 
                totalJobIds={totalJobIds}
                totalShipNames={totalShipNames}
                totalCustomerNames={totalCustomerNames} 
                totalShipRegNos={totalShipRegNos}
            />
            <FullTable  
                title="Job Overview"
                tableKey={tableKey}
                rowCount={rowCount} 
                page={page} 
                setRowCount={setRowCount} 
                setPage={setPage} 
                totalRowCount={totalJobCount}
                data={jobList}
                defaultColumnPriority={defaultColumnPriority}
                columnPriorityKey="job"
                windowWidthCategory={windowWidthCategory}
                preTableRowButtons={preTableRowButtons}
                tableRow={tableRow}
                tableHeader={tableHeader}
                defaultColumnCount={1}
                columnPriority={columnPriority}
                setAndSaveColumnPriority={setAndSaveColumnPriority}
            />
        </article>
    )
}

function JobFilter(
    {
        filterJobList, 
        filterJobListSetters, 
        totalJobIds, 
        totalShipNames, 
        totalCustomerNames,
        totalShipRegNos
    }
){
    const [inputDateAfter, setInputDateAfter] = React.useState(null);
    const [inputDateBefore, setInputDateBefore] = React.useState(null);
    const [inputStatus, setInputStatus] = React.useState("");
    const [wrongDateOpen, setWrongDateOpen] = React.useState(false);
    const submit = jobFilterQuerySubmit => {
        jobFilterQuerySubmit.preventDefault();
        console.log(jobFilterQuerySubmit);
        if (inputDateAfter && inputDateBefore && (inputDateAfter > inputDateBefore)){
            setWrongDateOpen(true);
        } else {
            filterJobList(
                {
                    inputDateAfter: inputDateAfter, 
                    inputDateBefore: inputDateBefore, 
                    inputStatus: inputStatus, 
                    inputJobId: jobFilterQuerySubmit.target[0].value, 
                    inputShipName: jobFilterQuerySubmit.target[2].value,
                    inputCustomerName: jobFilterQuerySubmit.target[4].value,
                    inputShipRegNo: jobFilterQuerySubmit.target[6].value
                },
                filterJobListSetters
            );
        }
    }
    return(
        <div>
            <section id="filter-grid">
                <h3 id="filter-text">
                    Filter
                </h3>
                <form id="job-form-filter" onSubmit={submit}>
                    <div id="second-filter-row">
                        <Autocomplete
                            disablePortal
                            className="filter-form-input"
                            id="job-id-input"
                            options={totalJobIds?totalJobIds:[""]}
                            freeSolo
                            sx={{ width: 2/5 }}
                            renderInput={(params) => <TextField{...params} label="Job ID" />}
                            />
                        <Autocomplete
                            disablePortal
                            className="filter-form-input"
                            id="ship-name-input"
                            freeSolo
                            options={totalShipNames?totalShipNames:[""]}
                            sx={{ width: 2/5 }}
                            renderInput={(params)=><TextField{...params} label="Vessel Name" />}
                            />
                    </div>
                    <div id="third-filter-row">
                        <Autocomplete
                            disablePortal
                            className="filter-form-input"
                            id="customer-name-input"
                            options={totalCustomerNames?totalCustomerNames:[""]}
                            freeSolo
                            sx={{ width: 2/5 }}
                            renderInput={(params) => <TextField{...params} label="Customer Name" />}
                        />
                        <Autocomplete
                            disablePortal
                            id="ship-reg-no-input"
                            className="filter-form-input"
                            freeSolo
                            options={totalShipRegNos?totalShipRegNos:[""]}
                            sx={{ width: 2/5 }}
                            renderInput={(params)=><TextField{...params} label="Ship Reg. No." />}
                        />
                    </div>
                </form>
                <div id="first-filter-row">
                    <CalendarButton
                    placeholder="From: "
                    setter={setInputDateAfter}
                    value={inputDateAfter}
                    idKey={"date-after"}
                    label=""
                    prependText="From: "
                    cssClass="menu-button-container calendar-button-container"/>
                    <CalendarButton
                    placeholder="To: "
                    setter={setInputDateBefore}
                    value={inputDateBefore}
                    idKey={"date-before"}
                    label=""
                    prependText="To: "
                    cssClass="menu-button-container calendar-button-container"/>
                    <ChooseFromList 
                    list={["Open", "Pending", "Complete"]} 
                    setItem={setInputStatus} 
                    item={inputStatus} 
                    label="" 
                    idKey="job-status" 
                    placeholder="Status" 
                    cssClass="menu-button-container status-button-container" 
                    defaultText="All"/>
                </div>
                <div id="job-filter-submit-container">
                    <button title="Filter jobs" id="search-button" className="search-button" type="submit" form="job-form-filter">
                        <BsSearch id="search-icon" />
                    </button>
                </div>
            </section>
            <AlertDialog 
            alertOpen={wrongDateOpen} 
            setAlertOpen={setWrongDateOpen} 
            alertText="Impossible date range."/>
        </div>
    )
}

function JobHeader(
    {columnPriority, 
    setAndSaveColumnPriority, 
    columnCount,
    tableHeadersDictionary}
    ){
    return(
        <thead id="table-header">
            <tr>
                {columnPriority.slice(
                    0, columnCount
                ).map(
                    (column, columnIndex) => {
                        return (
                            <ChangeColumn 
                            key={`rendered-${column}-header`}
                            column={column}
                            columnIndex={columnIndex}
                            columnPriority={columnPriority} 
                            tableHeadersDictionary={tableHeadersDictionary}
                            setAndSaveColumnPriority={setAndSaveColumnPriority}/>
                            )})}
                {
                <th>
                    Action
                </th>
                }
            </tr>
        </thead>
    )
}
function JobRow(
    {row, 
    rowIndex, 
    columnPriority, 
    columnCount, 
    actionCells}){
    return(
        <tr>
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    (column) =>{
                        return (
                            <td key={`job-${rowIndex}-${column.name}`}>
                                {
                                    column.name==="roles"
                                        ?row[column.name][0]
                                        :column.name==="job_id"
                                            ?<>
                                                <Link to={`/jobs/view/${row.job_id}`}>
                                                    {row.job_id}
                                                </Link>
                                            </>
                                            :row[column.name]
                                }
                            </td>
                        )
                    }
                )
            }
            {
                <Actions actionCells={actionCells(row)}/>
            }
        </tr>
    )
}
function DeleteJob({job, fetchJobs, fetchJobParams, columnPriority}){
    const [jobDeleteOpen, setJobDeleteOpen] = React.useState(false)
    const deleteJob = () => {
        axios.delete(
            `${URLORIGIN}/jobs/${job.job_id}`, {
                baseURL: "",
                withCredentials: true}
            ).then(() => fetchJobs(true, fetchJobParams, columnPriority));
    }
    return (
        <div>
            <button title="Delete job" type="button" className="buttons-without-borders" onClick={() => setJobDeleteOpen(true)}>
                <MdDeleteForever className="action-icons delete-icon"/>
            </button>
            <AlertDialog 
            alertOpen={jobDeleteOpen} 
            setAlertOpen={setJobDeleteOpen} 
            alertText={`Confirm delete ${job.job_id}?`} 
            action={(jobDelete) => {jobDelete.preventDefault();setJobDeleteOpen(false);deleteJob()}}/>
        </div>
    )
}

