import {useParams, useNavigate} from 'react-router-dom';
import React from 'react';
import axios from 'axios';

import qs from 'qs';
import {MdDeleteForever} from "react-icons/md";
import {BsCaretDownFill, BsCaretUpFill, BsEyeFill, BsSearch} from "react-icons/bs";
import {FaFile, FaFileMedical, FaFolder, FaFolderPlus, FaFolderOpen} from "react-icons/fa";
import {TbArrowBigUpLine} from "react-icons/tb";
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import SearchCustomerAndVessel from './searchCustomers.js';

import {URLORIGIN} from '../../config.js';
import {
    EditableField, 
    ReadonlyField, 
    ChooseFromList, 
    CalendarButton, 
    AlertDialog, 
    ChangeColumn, 
    FullTable,
    Actions,
    MultipleButtons
} from '../../template';
import './overview.scss';
import './create.scss';


export function ViewJob({windowWidthCategory}){
    const isEdit=false;
    const {job_no} = useParams();
    return (<ViewOrEditJob isEdit={isEdit} job_no={job_no} windowWidthCategory={windowWidthCategory}/>)
}

export function EditJob({windowWidthCategory}){
    const isEdit=true;
    const {job_no} = useParams();
    return (<ViewOrEditJob isEdit={isEdit} job_no={job_no} windowWidthCategory={windowWidthCategory}/>)
}

function ViewOrEditJob({isEdit, job_no, windowWidthCategory}){
    const [jobId, setJobId] = React.useState(job_no);
    const roles = JSON.parse(window.sessionStorage.getItem('roles'));
    const [oldRole, setOldRole] = React.useState("");
    const [role, setRole] = React.useState("");
    const [oldCustomerNo, setOldCustomerNo] = React.useState("");
    const [customerNo, setCustomerNo] = React.useState("");
    const [oldCustomerName, setOldCustomerName] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [totalCustomerNames, setTotalCustomerNames] = React.useState([""]);
    const [oldShipRegNo, setOldShipRegNo] = React.useState("");
    const [shipRegNo, setShipRegNo] = React.useState("");
    const [shipRegNos, setShipRegNos] = React.useState([""]);
    const [totalShipRegNos, setTotalShipRegNos] = React.useState([""]);
    const [oldShipName, setOldShipName] = React.useState("");
    const [shipName, setShipName] = React.useState("");
    const [shipNames, setShipNames] = React.useState([""]);
    const [oldDateStart, setOldDateStart] = React.useState(null);
    const [dateStart, setDateStart] = React.useState(null);
    const [oldDateEnd, setOldDateEnd] = React.useState(null);
    const [dateEnd, setDateEnd] = React.useState(null);
    const [dateModified, setDateModified] = React.useState(null);
    const [dateCreated, setDateCreated] = React.useState(null);
    const [modifier, setModifier] = React.useState(null);
    const [creator, setCreator] = React.useState("");
    const [oldJobStatus, setOldJobStatus] = React.useState("");
    const [jobStatus, setJobStatus] = React.useState("");
    const jobStatuses = ["Open", "Pending", "Complete"];
    const [oldDescription, setOldDescription] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [documentsInterface, setDocumentsInterface] = React.useState("files");
    const [directoryPath, setDirectoryPath] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [oldDocuments, setOldDocuments] = React.useState([]);
    const [directories, setDirectories] = React.useState([]);
    const [oldDirectories, setOldDirectories] = React.useState([]);
    const navigate = useNavigate();
    const [jobDeleteOpen, setJobDeleteOpen] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [wrongRecord, setWrongRecord] = React.useState(null);
    const [noRecordErrorOpen, setNoRecordErrorOpen] = React.useState(false);
    const [invalidDateErrorOpen, setInvalidDateErrorOpen] = React.useState(false);
    const [editJobOpen, setEditJobOpen] = React.useState(false);
    const [addFolderOpen, setAddFolderOpen] = React.useState(false);
    const [tableKey, setTableKey] = React.useState(0);
    const [previewUid, setPreviewUid] = React.useState("");
    const [previewDocumentName, setPreviewDocumentName] = React.useState("");
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewPageNum, setPreviewPageNum] = React.useState(1);
    const [isJobShow, setIsJobShow] = React.useState(isEdit);
    const [isDocumentShow, setIsTableShow] = React.useState(!isEdit);
    const defaultDocumentsColumnPriority = [
        {
            name: "filename",
            ascending: false,
            descending: false
        },
        {
            name: "created_timestamp",
            ascending: false,
            descending: false
        },
        {
            name: "display_name",
            ascending: false,
            descending: false
        }
    ];
    const [documentsColumnPriority, setDocumentsColumnPriority] = React.useState(
        defaultDocumentsColumnPriority
    );
    const setAndSaveDocumentsColumnPriority = updatedColumnPriority => {
        setDocumentsColumnPriority(updatedColumnPriority);
        localStorage.setItem(
            `documentColumnPriority`, 
            JSON.stringify(updatedColumnPriority)
        );
        // setTableKey(tableKey + 1);
    };
    // const setAndSaveColumnPriority = () => {};
    const documentsTableHeadersDictionary={ 
        "created_timestamp": "Upload Time",
        "display_name": "Uploader",
        "filename": "Files"
    };
    const defaultDirectoriesColumnPriority = [
        {
            name: "folder",
            ascending: false,
            descending: false
        }
    ]
    const [directoriesColumnPriority, setDirectoriesColumnPriority] = React.useState(
        defaultDirectoriesColumnPriority
    );
    const setAndSaveDirectoriesColumnPriority = updatedColumnPriority => {
        setDirectoriesColumnPriority(updatedColumnPriority);
        localStorage.setItem(
            `directoryColumnPriority`,
            JSON.stringify(updatedColumnPriority)
        );
        // setTableKey(tableKey + 1);
    }
    const directoriesTableHeadersDictionary={
        "folder": "Folder"
    };
// TODO: cache preview images into session storage.
    function searchCustomerAndShips(customerAndShipsQuerySubmit){
        customerAndShipsQuerySubmit.preventDefault();
        document.getElementById('customer-name-search-input').blur();
        document.getElementById('ship-reg-no-search-input').blur();
        const customerAndShipQuery = {};
        const inputCustomerName = document.getElementById("customer-name-search-input").value;
        const inputShipRegNo = document.getElementById("ship-reg-no-search-input").value;
        if (inputCustomerName){
            customerAndShipQuery.customer_name = inputCustomerName;
        }
        if (inputShipRegNo){
            customerAndShipQuery.ship_reg_no = inputShipRegNo;
        }
        if (inputCustomerName || inputShipRegNo){
            axios.get(
                `${URLORIGIN}/jobs/create`, {
                    baseURL: "",
                    withCredentials: true, 
                    params: customerAndShipQuery,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: "repeat" })
                    }
                }
            ).then(
                response => {
                    if (!response.data){
                        if (inputCustomerName){
                            setWrongRecord(inputCustomerName);
                            setNoRecordErrorOpen(true);
                        } else{
                            setWrongRecord(inputShipRegNo);
                            setNoRecordErrorOpen(true);
                        }
                    } else{
                        setCustomerName(response.data.customer_name);
                        document.getElementById('customer-name-input').value = response.data.customer_name;
                        setCustomerNo(response.data.customer_no);
                        document.getElementById('customer-id-input').value = response.data.customer_no;
                        const returnedShipNames = [];
                        const returnedShipRegNos = [];
                        var shipRegNoMatched = false;
                        for (const ship of response.data.ships){
                            returnedShipNames.push(ship.ship_name);
                            returnedShipRegNos.push(ship.ship_reg_no);
                            if (inputShipRegNo){
                                if (inputShipRegNo == ship.ship_reg_no){
                                    setShipName(ship.ship_name);
                                    setShipRegNo(ship.ship_reg_no);
                                    document.getElementById('ship-name-input').value = ship.ship_name;
                                    document.getElementById('ship-reg-no-input').value = ship.ship_reg_no;
                                    shipRegNoMatched = true;
                                }
                            }
                        }
                        setShipRegNos(returnedShipRegNos);
                        if (!inputShipRegNo){
                            setShipRegNo(returnedShipRegNos[0]);
                            setShipName(returnedShipNames[0]);
                            document.getElementById('ship-name-input').value = returnedShipNames[0];
                            document.getElementById('ship-reg-no-input').value = returnedShipRegNos[0];
                        }
                        setShipNames(returnedShipNames);
                        if (!shipRegNoMatched && inputShipRegNo){
                            setWrongRecord(`${inputShipRegNo} of ${inputCustomerName}`);
                            setNoRecordErrorOpen(true);
                        }
                    }
                }
            );
        } else{
            setCustomerNo("");
            setShipRegNos([]);
            setShipName("");
            setShipNames([]);
            document.getElementById('ship-name-input').value = "";
            document.getElementById('ship-reg-no-input').value = "";
        }
    }
    const editJob = jobEdit =>{
        jobEdit.preventDefault();
        if (
            role===oldRole 
            && customerNo===oldCustomerNo 
            && customerName===oldCustomerName 
            && document.getElementById("ship-reg-no-input").value===oldShipRegNo
            && document.getElementById("ship-name-input").value===oldShipName
            && dateStart.getTime()===oldDateStart.getTime()
            && (dateEnd?dateEnd.getTime():"")===(oldDateEnd?oldDateEnd.getTime():"")
            && jobStatus===oldJobStatus
            && description===oldDescription
        ){
            navigate("/jobs");
        } else if (dateEnd && dateStart && (dateEnd < dateStart)){
            setInvalidDateErrorOpen(true);
            return false;
        } else{
            const changedJobFields={
                "job_id": jobId,
                "role": role,
                "job_start": dateStart.getFullYear() 
                + '-' 
                + (dateStart.getMonth()>8?'':'0') 
                + (dateStart.getMonth() + 1)
                + '-'
                + (dateStart.getDate()>9?'':'0')
                + dateStart.getDate(),
                "job_status": jobStatus,
                "job_description": description,
                "job_end": dateEnd
                ?dateEnd.getFullYear() 
                + '-' 
                + (dateEnd.getMonth()>8?'':'0') 
                + (dateEnd.getMonth() + 1)
                + '-'
                + (dateEnd.getDate()>9?'':'0')
                + dateEnd.getDate()
                :null
            };
            if (customerNo)changedJobFields.customer_no = customerNo;
            if (customerName)changedJobFields.customer_name = customerName;
            if (document.getElementById("ship-reg-no-input").value)changedJobFields.ship_reg_no = document.getElementById("ship-reg-no-input").value;
            if (document.getElementById("ship-name-input").value)changedJobFields.ship_name = document.getElementById("ship-name-input").value;
            axios(
                `${URLORIGIN}/jobs/${job_no}`, {
                    method: 'PATCH',
                    baseURL: "",
                    withCredentials: true,
                    data: changedJobFields
                }
            ).then(() => navigate("/jobs")).catch(
                error => {
                    if (error.response.status === 303) navigate(error.response.data.url);
                }
            );
        }
    }
    const goHome = goingHome =>{
        goingHome.preventDefault();
        navigate("/jobs");
    }
    const deleteJob = () =>axios.delete(
        `${URLORIGIN}/jobs/${jobId}`, {
            baseURL: "",
            withCredentials: true
        }
    ).then(() => navigate("/jobs"));
    const selectDeselectAllCheckBoxes = ()=>{
        const newCheckBoxState = document.getElementById("select-all").checked;
        const checkBoxes = document.getElementsByName("document-checkbox");
        for (var checkBoxIndex = 0; checkBoxIndex < checkBoxes.length; checkBoxIndex++)checkBoxes[checkBoxIndex].checked = newCheckBoxState;
    }
    const downloadDocuments = documentsDownload=>{
        documentsDownload.preventDefault();
        var downloadLinkTemplate = `${URLORIGIN}/jobs/downloads/?job_no=${jobId}`;
        for (
            var checkBoxIndex = 0; 
            checkBoxIndex < Math.min(rowCount, oldDocuments.length - (page - 1) * rowCount); 
            checkBoxIndex++
        ) if (document.getElementById(`document-${checkBoxIndex}-filename`).checked){
            var win = window.open(
                `${downloadLinkTemplate}&uid=${oldDocuments[(page - 1) * rowCount + checkBoxIndex].uid}`, '_blank'
            );
            if (win != null) win.focus();
        }
    }
    const tableHeader = (
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount
        ) => {
            return(
                (documentsInterface==="files")
                    ?<JobDocumentTableHeader 
                        columnPriority={columnPriority} 
                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                        columnCount={columnCount}
                        tableHeadersDictionary={documentsTableHeadersDictionary}
                        isEdit={isEdit}
                        selectDeselectAllCheckBoxes={selectDeselectAllCheckBoxes}
                        windowWidthCategory={windowWidthCategory}
                    />
                    :<JobDirectoryTableHeader
                        columnPriority={columnPriority}
                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                        columnCount={columnCount}
                        tableHeadersDictionary={directoriesTableHeadersDictionary}
                        windowWidthCategory={windowWidthCategory}
                    />
            )
        }
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            (documentsInterface==="files")
                ?<JobDocumentRow 
                    key={`job-${rowIndex}`}
                    isEdit={isEdit}
                    row={row}
                    rowIndex={rowIndex}
                    page={page}
                    rowCount={rowCount}
                    columnPriority={columnPriority}
                    columnCount={columnCount}
                    documents={documents}
                    setDocuments={setDocuments}
                    setOldDocumentsList={setOldDocuments}
                    setPreviewUid={setPreviewUid}
                    setPreviewDocumentName={setPreviewDocumentName}
                    setPreviewPageNum={setPreviewPageNum}
                    directoryPath={directoryPath}
                    windowWidthCategory={windowWidthCategory}
                />
                :<JobDirectoryRow
                    job_no={job_no}
                    row={row}
                    rowIndex={rowIndex}
                    page={page}
                    columnPriority={columnPriority}
                    columnCount={columnCount}
                    directories={directories}
                    setDirectories={setDirectories}
                    setOldDirectories={setOldDirectories}
                    directoryPath={directoryPath}
                    setDirectoryPath={setDirectoryPath}
                    windowWidthCategory={windowWidthCategory}
                />
        )
    }
    const openDeleteJobDialog = jobDelete => {
        jobDelete.preventDefault();
        setJobDeleteOpen(true);
    }
    const resetJob = jobReset => {
        jobReset.preventDefault();
        setCustomerNo(oldCustomerNo);
        document.getElementById('customer-id-input').value = oldCustomerNo;
        setCustomerName(oldCustomerName);
        document.getElementById('customer-name-input').value = oldCustomerName;
        setShipName(oldShipName);
        document.getElementById('ship-name-input').value = oldShipName;
        setShipRegNo(oldShipRegNo);
        document.getElementById('ship-reg-no-input').value = oldShipRegNo;
        setDateStart(oldDateStart);
        setDateEnd(oldDateEnd);
        setDescription(oldDescription);
        document.getElementById('description-input').value = oldDescription;
        setJobStatus(oldJobStatus);
        document.getElementById('job-status-input').value = oldJobStatus;
        setRole(oldRole);
        document.getElementById('role').value = oldRole;
        setTableKey(tableKey + 1);
    }
    const refreshJobDetails = () => {
        var tableParams = {};
        if (documentsInterface==="files") {
            for (const column of documentsColumnPriority) if (column.ascending || column.descending) tableParams = {
                sort: column.name,
                sort_ascending: column.ascending,
                sort_descending: column.descending,
            };
        }
        else for (const column of directoriesColumnPriority) if (column.ascending || column.descending) tableParams = {
            sort: column.name,
            sort_ascending: column.ascending,
            sort_descending: column.descending
        };
        if (directoryPath[0])tableParams.directory_path = directoryPath.join("/") + "/";
        if(document.getElementById("filename-search-input")) tableParams.filename = document.getElementById("filename-search-input").value;
        axios.get(
            `${URLORIGIN}/jobs/view/${job_no}`, {
                baseURL: "",
                withCredentials: true,
                params: tableParams,
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "repeat" })
                }
            }
        ).then(
            response => {
                setCustomerNo(response.data.customer_no?response.data.customer_no:"");
                setOldCustomerNo(response.data.customer_no?response.data.customer_no:"");
                setCustomerName(response.data.customer_name?response.data.customer_name:"");
                setOldCustomerName(response.data.customer_name?response.data.customer_name:"");
                setShipName(response.data.ship_name?response.data.ship_name:"");
                setOldShipName(response.data.ship_name?response.data.ship_name:"");
                setShipRegNo(response.data.ship_reg_no?response.data.ship_reg_no:"");
                setOldShipRegNo(response.data.ship_reg_no?response.data.ship_reg_no:"");
                setDateStart(new Date(response.data.job_start));
                setOldDateStart(new Date(response.data.job_start));
                setDateEnd(response.data.job_end?new Date(response.data.job_end):"");
                setOldDateEnd(response.data.job_end?new Date(response.data.job_end):"");
                setDateModified(response.data.last_modified_date?new Date(response.data.last_modified_date):"");
                setModifier(response.data.modifier?response.data.modifier:"");
                setDateCreated(response.data.created_date?new Date(response.data.created_date):"");
                setCreator(response.data.creator?response.data.creator:"");
                setDescription(response.data.job_description?response.data.job_description:"");
                setOldDescription(response.data.job_description?response.data.job_description:"");
                setJobStatus(response.data.job_status);
                setOldJobStatus(response.data.job_status);
                setRole(response.data.roles[0]);
                setOldRole(response.data.roles[0]);
                setIsAdmin(JSON.parse(window.sessionStorage.getItem("adminRoles")).includes(response.data.roles[0]));
                if (response.data.job_documents){
                    setDocuments(JSON.parse(JSON.stringify(response.data.job_documents)));
                    setOldDocuments(
                        JSON.parse(
                            JSON.stringify(
                                response.data.job_documents
                            )
                        )
                    );
                }
                if (response.data.job_folders){
                    setDirectories(
                        JSON.parse(
                            JSON.stringify(
                                response.data.job_folders
                            )
                        )
                    );
                    setOldDirectories(
                        JSON.parse(
                            JSON.stringify(
                                response.data.job_folders
                            )
                        )
                    );
                }
            }
        ).catch(
            error => {
                if (error.response.status === 303) navigate(error.response.data.url);
            }
        )
    }
    const patchDocuments = documentsPatch => {
        if (documentsPatch)documentsPatch.preventDefault();
        var patchDocuments = [];
        documents.forEach(
            (document) =>{
                const oldDocument = oldDocuments.filter(
                    oldDocument => {
                        return oldDocument.uid === document.uid
                    }
                )[0];
                if (
                    document.filename !== oldDocument.filename
                )patchDocuments.push(
                    {
                        uid: document.uid,
                        old_filename: oldDocument.filename,
                        new_filename: document.filename
                    }
                )
            }
        )
        const params = directoryPath[0]
            ?{directory_path: encodeURIComponent(directoryPath.join("/") + "/")}
            :{};
        if (patchDocuments[0]) {
            axios(
                `${URLORIGIN}/jobs/documents/${job_no}`, {
                    method: 'PATCH',
                    baseURL: "",
                    withCredentials: true,
                    data: patchDocuments,
                    params: params,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: "repeat" })
                    }
                }
            ).then(
                () => refreshJobDetails()
            ).catch(
                error => {
                    if (error.response.status === 303) navigate(error.response.data.url);
                }
            )
        } else refreshJobDetails();
    }
    const patchDirectories = directoriesPatch => {
        if (directoriesPatch)directoriesPatch.preventDefault();
        var patchDirectories = [];
        directories.forEach(
            directory => {
                const oldDirectory = oldDirectories.filter(
                    oldDirectory => {
                        return oldDirectory.uid === directory.uid
                    }
                )[0];
                if (directory.folder !== oldDirectory.folder) patchDirectories.push(
                    {
                        old_folder_name: oldDirectory.folder,
                        new_folder_name: directory.folder
                    }
                );
            }
        )
        const params = directoryPath[0]
            ?{directory_path: encodeURIComponent(directoryPath.join("/") + "/")}
            :{};
        if (patchDirectories[0]){
            axios(
                `${URLORIGIN}/jobs/folders/${job_no}`, {
                    method: "PATCH",
                    baseURL: "",
                    withCredentials: true,
                    data: patchDirectories,
                    params: params,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: "repeat" })
                    }
                }
            ).then(
                () => refreshJobDetails()
            ).catch(
                error => {
                    if (error.response.status === 303) navigate(error.response.data.url);
                }
            )
        } else refreshJobDetails();
    }
    const addFolder = folderAdd => {
        folderAdd.preventDefault();
        const params = directoryPath[0]
            ?{
                directory_path: encodeURIComponent(directoryPath.join("/") + "/"),
                folder: document.getElementById("add-folder-form-field").value
            }
            :{folder: document.getElementById("add-folder-form-field").value};
        console.log(params);
        axios(
            `${URLORIGIN}/jobs/folders/${job_no}`, {
                method: "POST",
                baseURL: "",
                withCredentials: true,
                params: params,
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: "repeat"})
                }
            }
        ).then(
            () => {refreshJobDetails(); setAddFolderOpen(false);}
        ).catch(
            error => {
                if (error.response.status === 303) navigate(error.response.data.url);
            }
        )
    }
    const preTableRowButtons=[
        {
            idKey: "table-up-directory", 
            onClickFunction: directoryUp => {
                directoryUp.preventDefault();
                directoryPath.pop();
                setDirectoryPath(
                    JSON.parse(
                        JSON.stringify(
                            directoryPath
                        )
                    )
                )
            }, 
            icon: <TbArrowBigUpLine/>
        }, 
        {
            idKey: "table-create",
            onClickFunction: fileCreate => {
                fileCreate.preventDefault();
                document.getElementById("choose-files").click();
            },
            icon: <FaFileMedical/>
        },
        {
            idKey: "table-create",
            onClickFunction: folderCreate => {
                folderCreate.preventDefault();
                setAddFolderOpen(true);
            },
            icon: <FaFolderPlus/>
        }
    ];
    React.useEffect(
        ()=>{
            if (document.getElementById("select-all")){
                document.getElementById("select-all").checked = false;
                const checkBoxes = document.getElementsByName("document-checkbox");
                for (var checkBoxIndex = 0; checkBoxIndex < checkBoxes.length; checkBoxIndex++)checkBoxes[checkBoxIndex].checked = false;
            }
        }, [rowCount, page]
    )
    React.useEffect(
        () => setTableKey(tableKey + 1), [oldDocuments, oldDirectories, page, rowCount, documentsInterface]
    );
    React.useEffect(
        () =>{
            if (documentsInterface==="files"){patchDocuments();} else patchDirectories();
            if (isEdit) axios.get(
                `${URLORIGIN}/jobs/create/init`, {
                    baseURL: "",
                    withCredentials: true}
            ).then(
                response => {
                    setTotalCustomerNames(response.data.customer_names);
                    setTotalShipRegNos(response.data.ship_reg_nos);
                }
            ).catch (
                error => {
                    if (error.response.status === 303) navigate(error.response.data.url);
                }
            );
        }, [documentsColumnPriority, directoriesColumnPriority]
    );
    React.useEffect(
        ()=>{
            setPreviewOpen(!!previewUid);
        }, [previewUid]
    );
    React.useEffect(
        ()=>refreshJobDetails(), [directoryPath]
    );
    return(
        <>
            <section id="edit-view-job-container">
                <div className={isJobShow?"hide-button-container":"show-button-container"}>
                    <title id="create-job-header">{isEdit?"Edit Job":"View Job Details"}</title>
                    {
                        isEdit
                            ?null
                            :isJobShow
                                ?<HideButton
                                    setShow={setIsJobShow}
                                    containerId="job-container"
                                    title="Hide Job Details"
                                />
                                :<ShowButton
                                    setShow={setIsJobShow}
                                    containerId="job-container"
                                    title="Show Job Details"
                                />
                    }
                </div>
                <div id="job-container" className={`slider ${isJobShow?"opened":"closed"}`}>
                    <div id="job-create-top-row">
                        {
                            isEdit
                                ?<SearchCustomerAndVessel 
                                searchCustomerAndShips={searchCustomerAndShips} 
                                totalCustomerNames={totalCustomerNames} 
                                totalShipRegNos={totalShipRegNos}/>
                                :null
                        }
                        <MultipleButtons 
                        cssId="finish-buttons" 
                        buttons={[
                            isEdit
                            ?{
                                idKey: "text-save", 
                                text: "Save Changes", 
                                form: "view-edit-form"
                            }
                            :null, 
                            isEdit
                            ?{
                                idKey: "text-reset",
                                text: "Reset",
                                onClickFunction: resetJob
                            }
                            :null,
                            (isAdmin)?
                            {
                                idKey: "text-delete", 
                                text: "Delete Job", 
                                onClickFunction: openDeleteJobDialog}
                            :null,
                            {
                                idKey: "text-back", 
                                text: isEdit?"Cancel":"Back", 
                                onClickFunction: goHome}
                        ]}/>
                    </div>

                    <JobDetails
                        isEdit={isEdit}
                        isAdmin={isAdmin}
                        setJobId={setJobId}
                        jobId={jobId}
                        roles={roles}
                        setRole={setRole}
                        role={role}
                        oldRole={oldRole}
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo}
                        customerName={customerName}
                        setCustomerName={setCustomerName}
                        setShipRegNo={setShipRegNo}
                        shipRegNo={shipRegNo}
                        shipRegNos={shipRegNos}
                        setShipName={setShipName}
                        shipName={shipName}
                        shipNames={shipNames}
                        setDateStart={setDateStart}
                        dateStart={dateStart}
                        oldDateStart={oldDateStart}
                        setDateEnd={setDateEnd}
                        dateEnd={dateEnd}
                        oldDateEnd={oldDateEnd}
                        jobStatuses={jobStatuses}
                        setJobStatus={setJobStatus}
                        oldJobStatus={oldJobStatus}
                        jobStatus={jobStatus}
                        setDescription={setDescription}
                        description={description}
                        creator={creator}
                        dateCreated={dateCreated}
                        modifier={modifier}
                        dateModified={dateModified}
                        setEditJobOpen={setEditJobOpen}
                    />
                    {
                        isEdit
                            ?null
                            :<div className="hide-button-container">
                                <HideButton
                                    setShow={setIsJobShow}
                                    containerId="job-container"
                                    title="Hide Job Details"
                                />
                            </div>
                    }
                </div>
            </section>
            {
                isEdit
                    ?null
                    :<>
                        <hr/>
                        <section id="edit-document-view-container">
                            <div className={isDocumentShow?"hide-button-container":"show-button-container"}>
                                <title id="create-job-header">Documents</title>
                                <button
                                    type="button"
                                    title="Files Interface"
                                    className="buttons-without-borders"
                                    id={
                                        (documentsInterface==="files")
                                            ?"file-button-active"
                                            :"file-button-inactive"
                                    }
                                    onClick={
                                        fileInterfaceActivate => {
                                            fileInterfaceActivate.preventDefault();
                                            setDocumentsInterface("files");
                                        }
                                    }
                                >
                                    <FaFile/>
                                </button>
                                <button
                                    type="button"
                                    title="Folders Interface"
                                    className="buttons-without-borders"
                                    id={
                                        (documentsInterface==="folders")
                                            ?"folder-button-active"
                                            :"folder-button-inactive"
                                    }
                                    onClick={
                                        fileInterfaceActivate => {
                                            fileInterfaceActivate.preventDefault();
                                            setDocumentsInterface("folders");
                                        }
                                    }
                                >
                                    <FaFolder/>
                                </button>
                                {
                                    isDocumentShow
                                        ?<HideButton
                                            setShow={setIsTableShow}
                                            containerId="document-container"
                                            title="Hide Documents"
                                        />
                                        :<ShowButton
                                            setShow={setIsTableShow}
                                            containerId="document-container"
                                            title="Show Documents"
                                        />
                                }
                            </div>
                            <div id="document-container" className={`slider ${isDocumentShow?"opened":"closed"}`}>
                                <div id="document-top-row">
                                    {
                                        isEdit || documentsInterface==="folders"
                                            ?null
                                            :<SearchFileName 
                                                searchFiles={patchDocuments} 
                                                totalDocumentNames={
                                                    documents.map(
                                                        document => document.filename
                                                    )
                                                } 
                                            />
                                    }
                                    {
                                        isEdit
                                            ?null
                                            :<MultipleButtons 
                                            cssId="finish-buttons" 
                                            buttons={
                                                [
                                                    (documentsInterface==="files")
                                                    ?{
                                                        idKey: "text-download", 
                                                        text: "Download", 
                                                        onClickFunction: downloadDocuments
                                                    }
                                                    :null,
                                                    {
                                                        idKey: "text-save", 
                                                        text: "Update", 
                                                        onClickFunction: namesPatch => {
                                                            namesPatch.preventDefault();
                                                            if (documentsInterface==="files"){
                                                                if (documents.length > 0)patchDocuments(namesPatch);
                                                            } else {
                                                                if (directories.length > 0)patchDirectories(namesPatch);
                                                            }
                                                        }
                                                    },
                                                    {
                                                        idKey: "text-reset", 
                                                        text: "Reset", 
                                                        onClickFunction: filenamesReset => {
                                                            filenamesReset.preventDefault();
                                                            if (documentsInterface==="files"){
                                                                if (oldDocuments.length > 0) setDocuments(
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            oldDocuments
                                                                        )
                                                                    )
                                                                );
                                                            } else {
                                                                if (oldDirectories.length > 0) setDirectories(
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            oldDirectories
                                                                        )
                                                                    )
                                                                )
                                                            };
                                                            setTableKey(tableKey + 1);
                                                        }
                                                    }
                                                ]
                                            }
                                        />
                                    }
                                </div>
                                <p id="directory-path">{directoryPath[0]?directoryPath.join("/") + "/":null}</p>
                                <FullTable
                                    tableKey={tableKey}
                                    rowCount={rowCount} 
                                    page={page} 
                                    setRowCount={setRowCount} 
                                    setPage={setPage} 
                                    totalRowCount={
                                        (documentsInterface==="files")
                                            ?documents.length
                                            :directories.length
                                    }
                                    data={
                                        (documentsInterface==="files")
                                            ?documents.slice(
                                                (page - 1) * rowCount, 
                                                page * rowCount
                                            )
                                            :directories.slice(
                                                (page - 1) * rowCount,
                                                page * rowCount
                                            )
                                    }
                                    defaultColumnPriority={
                                        (documentsInterface==="files")
                                            ?defaultDocumentsColumnPriority
                                            :defaultDirectoriesColumnPriority
                                    }
                                    columnPriorityKey={
                                        (documentsInterface==="files")
                                            ?"document"
                                            :"directory"
                                    }
                                    windowWidthCategory={windowWidthCategory}
                                    tableRow={tableRow}
                                    tableHeader={tableHeader}
                                    defaultColumnCount={1}
                                    columnPriority={
                                        (documentsInterface==="files")
                                            ?documentsColumnPriority
                                            :directoriesColumnPriority
                                    }
                                    setAndSaveColumnPriority={
                                        (documentsInterface==="files")
                                            ?setAndSaveDocumentsColumnPriority
                                            :setAndSaveDirectoriesColumnPriority
                                    }
                                    showBottomDivider={true}
                                    preTableRowButtons={preTableRowButtons}
                                />
                                <div className="hide-button-container">
                                    <HideButton
                                        setShow={setIsTableShow}
                                        containerId="document-container"
                                        title="Hide Documents"
                                    />
                                </div>
                            </div>
                        </section>
                    </>
            }
            <input 
            id="choose-files" 
            type="file" 
            onChange={
                filesChosen => {
                    filesChosen.preventDefault();
                    const formData = new FormData();
                    Array.from(
                        filesChosen.target.files
                    ).forEach(
                        document => {
                            formData.append(
                                "files",
                                document
                            );
                            formData.append(
                                "new_names",
                                document.name
                            );
                        }
                    )
                    const params = directoryPath[0]
                        ?{directory_path: encodeURIComponent(directoryPath.join("/") + "/")}
                        :{};
                    axios(
                        `${URLORIGIN}/jobs/uploads/${job_no}`,{
                            baseURL: "",
                            withCredentials: true,
                            method: "POST",
                            data: formData,
                            headers: {
                                "Content-type": "multipart/form-data"
                            },
                            params: params,
                            paramsSerializer: params => {
                                return qs.stringify(
                                    params, { arrayFormat: "repeat" }
                                )
                            }
                        }
                    ).then(
                        () => refreshJobDetails()
                    ).catch(
                        error => {
                            if (error.response.status === 303) navigate(error.response.data.url);
                        }
                    );
                }
            } 
            multiple 
            hidden/>
            <AlertDialog 
                alertOpen={jobDeleteOpen} 
                setAlertOpen={setJobDeleteOpen} 
                alertText={`Confirm delete ${jobId}?`} 
                action={(jobDelete) => {jobDelete.preventDefault();setJobDeleteOpen(false);deleteJob()}}
            />
            <AlertDialog 
                alertOpen={invalidDateErrorOpen} 
                setAlertOpen={setInvalidDateErrorOpen} 
                alertText="The end date should take place after the start date."
                action={openError => setEditJobOpen(false)}
            />
            <AlertDialog 
                alertOpen={noRecordErrorOpen} 
                setAlertOpen={setNoRecordErrorOpen} 
                alertText={`${wrongRecord} does not exist.`} 
                action=""
            />
            <AlertDialog 
                alertOpen={editJobOpen} 
                setAlertOpen={setEditJobOpen} 
                alertText={`Confirm editing job ${jobId}?`}
                action={editJob}
            />
            <Dialog
                open={previewOpen}
                onClose={() => {setPreviewOpen(false);}}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                autoComplete="off"
            >
                <DialogContent>
                    <div id="preview-page-turner">
                        <div>
                            <button 
                                type="button" 
                                onClick={
                                    leftButtonClicked=>{
                                        leftButtonClicked.preventDefault();
                                        setPreviewPageNum(previewPageNum-1);
                                    }
                                }
                            >
                                <BiLeftArrow />
                            </button>
                            <button 
                                type="button" 
                                onClick={
                                    rightButtonClicked=>{
                                        rightButtonClicked.preventDefault();
                                        setPreviewPageNum(previewPageNum + 1);
                                    }
                                }
                            >
                                <BiRightArrow/>
                            </button>
                        </div>
                    </div>
                    <img
                        src={`${URLORIGIN}/jobs/documents/${previewUid}?page_num=${previewPageNum}`}
                        // alt={`Preview image of ${previewDocumentName}.`}
                        alt="Preview unavailable."
                        id="preview-image"
                    />
                </DialogContent>
                <DialogActions>
                    <button type="button" onClick={()=>setPreviewUid("")} className="password-close-button">Close</button>
                </DialogActions>
            </Dialog>
            <Dialog
              open={addFolderOpen}
              onClose={() => {setAddFolderOpen(false);}}
              aria-labelledby='alert-dialog-title'
              aria-describedby='add-folder-form'
            >
              <DialogContent>
                <form id="add-folder-form" onSubmit={addFolder}>
                  <div id="add-folder-form-container">
                    <label>
                      New folder: 
                    </label>
                    <input 
                      required 
                      id="add-folder-form-field"
                      type="text" 
                      name="NewFolder" 
                      placeholder="Folder" 
                      autoFocus/>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <button type="submit" form="add-folder-form" id="alert-first-button" className="alert-button">Confirm</button>
                <button type="button" id="alert-second-button" onClick={()=>setAddFolderOpen(false)} className="alert-button">Cancel</button>
              </DialogActions>
            </Dialog>
        </>
    )
}
function HideButton({setShow, containerId, title}){
    return (
        <button                   
            type="button" 
            title={title}
            className="buttons-without-borders hide-button" 
            onClick={
                deleteDocumentClicked => {
                    deleteDocumentClicked.preventDefault();
                    setShow(false);
                    if (
                        !document.getElementById(
                            containerId
                        ).classList.contains("closed")
                    )document.getElementById(
                        containerId
                    ).classList.add("closed");
                    if (
                        document.getElementById(
                            containerId
                        ).classList.contains("opened")
                    )document.getElementById(
                        containerId
                    ).classList.remove("opened");
                }
            }
        >
            <BsCaretUpFill/>
        </button>
    )
}
function ShowButton({setShow, containerId, title}){
    return(
        <button                   
            title={title}
            type="button" 
            className="buttons-without-borders show-button" 
            onClick={
                divShow => {
                    divShow.preventDefault();
                    setShow(true);
                    if (
                        document.getElementById(
                            containerId
                        ).classList.contains("closed")
                    )document.getElementById(
                        containerId
                    ).classList.remove("closed");
                    if (
                        !document.getElementById(
                            containerId
                        ).classList.contains("opened")
                    )document.getElementById(
                        containerId
                    ).classList.add("opened");
                }
            }
        >
            <BsCaretDownFill/>
        </button>
    )
}
function SearchFileName({searchFiles, totalDocumentNames}){
    return(
        <section id="search-document">
            <h3 id="search-customer-and-vessel-header">Search</h3>
            <form
            id="search-document-form"
            onSubmit={searchFiles}>
                <div className="search-document-input-container">
                    <Autocomplete
                        disablePortal
                        fullWidth="true"
                        id='filename-search-input'
                        options={totalDocumentNames?totalDocumentNames:[""]}
                        freeSolo
                        // sx={{ width: 19/20 }}
                        style={{ width: '100%' }}
                        popoverProps={{ style: { width: '100%'} }}
                        renderInput={
                            (params) => <TextField{...params} 
                                label="File" 
                            />
                        }
                    />
                </div>
                <button title="Search" 
                className="search-button" 
                type="submit" 
                form="search-document-form">
                    <BsSearch/>
                </button>
            </form>
        </section>
    )
}
function JobDetails(
    {
        isEdit, 
        isAdmin, 
        setJobId, 
        jobId, 
        roles, 
        setRole, 
        role, 
        oldRole,
        customerNo, 
        setCustomerNo,
        customerName, 
        setCustomerName,
        setShipRegNo,
        shipRegNo, 
        shipRegNos,
        setShipName,
        shipName, 
        shipNames,
        setDateStart, 
        dateStart, 
        oldDateStart,
        setDateEnd, 
        dateEnd, 
        oldDateEnd,
        jobStatuses, 
        setJobStatus, 
        jobStatus, 
        oldJobStatus,
        setDescription, 
        description,
        creator,
        dateCreated,
        modifier,
        dateModified,
        setEditJobOpen
    }
){
    return(
        // <section id="create-job-main">
        <form
        id="view-edit-form" className={isEdit?"edit-job-form":"view-job-form"} onSubmit={
                jobEdit=>{
                    jobEdit.preventDefault();
                    if (isEdit){
                        if (!document.getElementById("role-input").value){
                            document.getElementById("role-input").focus();
                        }else if (!document.getElementById("start-date-input").value){
                            document.getElementById("start-date-input").focus();
                        } else setEditJobOpen(true);
                    }
                }
            }>
            <div className="dual-fields-container">
                {(isEdit&&isAdmin)
                    ? <EditableField 
                    setter={setJobId} 
                    label="Job ID*" 
                    placeholder="Job ID" 
                    idKey="job-id" 
                    defaultValue={jobId} 
                    cssClass="left-field short-label"
                    isRequired={true}/>
                    : <ReadonlyField 
                    value={jobId} 
                    label="Job ID" 
                    idKey="job-id" 
                    placeholder="Job ID" 
                    fullLength="" 
                    cssClass="left-field short-label"/>
                }
                {(isEdit&&isAdmin)
                    ? <ChooseFromList 
                    list={roles} 
                    setItem={setRole} 
                    item={role} 
                    label="Role*" 
                    idKey="role" 
                    placeholder="Role" 
                    cssClass="right-field short-label" 
                    defaultText="Set to default"
                    isRequired={true}
                    defaultValue={oldRole}
                    />
                    : <ReadonlyField 
                    value={role} 
                    label="Role" 
                    idKey="role" 
                    placeholder="Role" 
                    fullLength="" 
                    cssClass="right-field short-label"/>
                }
            </div>
            <div className="dual-fields-container">
                {
                    isEdit
                        ?<EditableField 
                        value={customerNo} 
                        setter={setCustomerNo}
                        defaultValue={customerNo} 
                        label="Customer ID" 
                        idKey="customer-id" 
                        placeholder="Customer ID" 
                        fullLength="" 
                        cssClass="left-field short-label"/>
                        :<ReadonlyField 
                        value={customerNo} 
                        label="Customer ID" 
                        idKey="customer-id" 
                        placeholder="Customer ID" 
                        fullLength="" 
                        cssClass="left-field short-label"/>
                }
                {
                    isEdit
                        ?<EditableField 
                        value={customerName} 
                        setter={setCustomerName}
                        defaultValue={customerName} 
                        label="Customer Name" 
                        idKey="customer-name" 
                        placeholder="Customer Name" 
                        fullLength="" 
                        cssClass="right-field"/>
                        :<ReadonlyField 
                        value={customerName} 
                        label="Customer Name" 
                        idKey="customer-name" 
                        placeholder="Customer Name" 
                        fullLength="" 
                        cssClass="right-field"/>
                }
            </div>
            <div className="dual-fields-container">
                {
                    isEdit
                        ?<div className="left-field autocomplete-field">
                            <label className="normal-label">Vessel Reg. No.</label>
                            <Autocomplete
                                disablePortal
                                id='ship-reg-no-input'
                                options={shipRegNos?shipRegNos:[""]}
                                value={shipRegNo}
                                onChange={
                                    (shipRegNoChange, newShipRegNo)=>{
                                        shipRegNoChange.preventDefault(); 
                                        setShipRegNo(newShipRegNo);
                                        if (
                                            shipRegNos.includes(
                                                newShipRegNo
                                            )
                                        ){
                                            setShipName(
                                                shipNames[
                                                    shipRegNos.indexOf(
                                                        newShipRegNo
                                                    )
                                                ]
                                            );
                                        }
                                    }
                                }
                                freeSolo
                                renderInput={
                                    (params) => <
                                        TextField{...params} 
                                        label="Vessel Reg. No." 
                                    />
                                }
                            />
                        </div>
                        :<ReadonlyField 
                        value={shipRegNo} 
                        label="Vessel Reg. No." 
                        idKey="ship-reg-no" 
                        placeholder="Registration No." 
                        fullLength="" 
                        cssClass="left-field"/>
                }
                {
                    isEdit
                        ?<div className="right-field autocomplete-field short-label">
                            <label className="normal-label">Vessel Name</label>
                            <Autocomplete
                                disablePortal
                                id='ship-name-input'
                                options={shipNames?shipNames:[""]}
                                value={shipName}
                                onChange={
                                    (shipNameChange, newShipName)=>{
                                        shipNameChange.preventDefault(); 
                                        setShipName(newShipName);
                                        if (
                                            shipNames.includes(
                                                newShipName
                                            )
                                        ){
                                            setShipRegNo(
                                                shipRegNos[
                                                    shipNames.indexOf(
                                                        newShipName
                                                    )
                                                ]
                                            );
                                        }
                                    }
                                }
                                freeSolo
                                renderInput={
                                    (params) => <
                                        TextField{...params} 
                                        label="Vessel Name" 
                                    />
                                }
                            />
                        </div>
                        :<ReadonlyField 
                            value={shipName} 
                            label="Vessel Name" 
                            idKey="ship-name" 
                            placeholder="Vessel Name" 
                            fullLength="" 
                            cssClass="right-field short-label"
                        />
                }
            </div>
            <div className="dual-fields-container">
                <ReadonlyField 
                value={creator} 
                label="Creator" 
                idKey="creator" 
                placeholder="Creator" 
                fullLength="" 
                cssClass="left-field short-label"/>
                <ReadonlyField 
                value={
                    dateCreated
                    ? dateCreated.toLocaleDateString(
                        undefined, {
                            day: 'numeric', 
                            month: 'short', 
                            year: 'numeric'
                        }
                    )
                    : ""
                } 
                label="Creation Date" 
                idKey="creation-date" 
                placeholder="Creation Date" 
                fullLength="" 
                cssClass="right-field"/>
            </div>
            <div className="dual-fields-container">
                <ReadonlyField 
                value={modifier} 
                label="Last Modifier" 
                idKey="modifier" 
                placeholder="Last Modifier" 
                fullLength="" 
                cssClass="left-field short-label"/>
                <ReadonlyField 
                value={dateModified
                    ? dateModified.toLocaleDateString(
                        undefined, {
                            day: 'numeric', 
                            month: 'short', 
                            year: 'numeric'})
                    : ""} 
                label="Last Modified Date" 
                idKey="modified-date" 
                placeholder="Last Modified Date" 
                fullLength="" 
                cssClass="right-field"/>
            </div>
            <div className="dual-fields-container">
                {isEdit
                ? <CalendarButton 
                placeholder="Select Date" 
                setter={setDateStart} 
                value={dateStart} 
                idKey="start-date" 
                label="Start Date*" 
                prependText="" 
                cssClass="left-field short-label"
                isRequired={true}
                defaultValue={oldDateStart}/>
                : <ReadonlyField 
                value={
                    dateStart
                    ? dateStart.toLocaleDateString(
                        undefined, {
                            day: 'numeric', 
                            month: 'short', 
                            year: 'numeric'})
                    : ""} 
                label="Start Date" 
                idKey="start-date" 
                placeholder="Date" 
                fullLength="" 
                prependText="" 
                cssClass="left-field short-label"/>}
                {isEdit
                ? <CalendarButton 
                placeholder="Select Date" 
                setter={setDateEnd} 
                value={dateEnd} 
                idKey="end-date" 
                label="End Date" 
                prependText="" 
                cssClass="right-field short-label"
                isRequired={false}
                defaultValue={oldDateEnd}/>
                : <ReadonlyField 
                value={
                    dateEnd
                    ? dateEnd.toLocaleDateString(
                        undefined, {
                            day: 'numeric', 
                            month: 'short', 
                            year: 'numeric'})
                    : ""} 
                label="End Date" 
                idKey="end-date" 
                placeholder="Date" 
                fullLength="" 
                prependText="" 
                cssClass="right-field short-label"/>}
                {isEdit
                ? <ChooseFromList 
                list={jobStatuses} 
                setItem={setJobStatus} 
                item={jobStatus} 
                label="Status" 
                idKey="job-status" 
                placeholder="Status" 
                cssClass="left-field short-label" 
                defaultText="Set to default"
                defaultValue={oldJobStatus}/>
                : <ReadonlyField 
                value={jobStatus} 
                label="Status" 
                idKey="job-status" 
                placeholder="Status" 
                fullLength="" 
                cssClass="left-field short-label second-row"/>}
            </div>
            <div className="dual-fields-container">
                {
                    isEdit
                        ? <EditableField 
                            setter={setDescription} 
                            label="Description" 
                            placeholder="Description" 
                            idKey="description" 
                            cssClass="long-field short-label" 
                            defaultValue={description}
                            isRequired={false}
                            longfield={true}
                        />
                        : <ReadonlyField value={description} 
                            label="Description" 
                            idKey="description" 
                            placeholder="Description" 
                            fullLength="True" 
                            cssClass="long-field short-label"
                            longfield={true}
                        />
                }
                </div>
        </form>
    )
}

function JobDocumentTableHeader(
    {
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount,
        tableHeadersDictionary,
        isEdit,
        selectDeselectAllCheckBoxes,
        windowWidthCategory
    }
){
    const actionHeaders=["Preview", "Delete"];
    return(
        <thead id="table-header">
            <tr>
                {
                    isEdit
                        ?null
                        :<th>
                            <input
                                type="checkbox"
                                id="select-all"
                                onClick={selectDeselectAllCheckBoxes}
                            />
                        </th>
                }
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column, columnIndex) => {
                            return (
                                <ChangeColumn 
                                key={`rendered-${column}-header`}
                                column={column}
                                columnIndex={columnIndex}
                                columnPriority={columnPriority} 
                                tableHeadersDictionary={tableHeadersDictionary}
                                setAndSaveColumnPriority={setAndSaveColumnPriority}/>
                            )
                        }
                    )
                }
                {
                    isEdit
                        ?null
                        :(windowWidthCategory === "lg" || windowWidthCategory.includes("xl")) 
                            ?actionHeaders.map(
                                (action) => {
                                    return (
                                        <th key={`render-${action}-header`}>
                                            {action}
                                        </th>
                                    )
                                }
                            ) 
                            :<th>
                                Action
                            </th>
                }
            </tr>
        </thead>
    )
}
function JobDirectoryTableHeader(
    {
        columnPriority,
        setAndSaveColumnPriority,
        columnCount,
        tableHeadersDictionary,
        windowWidthCategory
    }
){
    const actionHeaders=["Open", "Delete"];
    return(
        <thead id="table-header">
            <tr>
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column, columnIndex) => {
                            return (
                                <ChangeColumn
                                    key={`rendered-${column}-header`}
                                    column={column}
                                    columnIndex={columnIndex}
                                    columnPriority={columnPriority}
                                    tableHeadersDictionary={tableHeadersDictionary}
                                    setAndSaveColumnPriority={setAndSaveColumnPriority}
                                />
                            )
                        }
                    )
                }
                {
                    (windowWidthCategory==="lg" || windowWidthCategory.includes("xl") || windowWidthCategory==="md")
                        ?actionHeaders.map(
                            action => {
                                return (
                                    <th key={`render-${action}-header`}>
                                        {action}
                                    </th>
                                )
                            }
                        )
                        :<th>
                            Action
                        </th>
                }
            </tr>
        </thead>
    )
}
{/* TODO: documents: 1. Add search by filename functionality*/}
{/* TODO: add new ChooseFromList field: responder, make it searchable at overviews page by regular expression (search value at top: my display name). */}
function JobDocumentRow(
    {
        isEdit,
        row, 
        rowIndex, 
        page,
        columnPriority, 
        columnCount,
        documents,
        setDocuments,
        setOldDocumentsList,
        setPreviewDocumentName,
        setPreviewUid,
        setPreviewPageNum,
        directoryPath,
        windowWidthCategory
    }
){
    // TODO: with width of input to 100%.
    //TODO: Add padding-left to input.
    const actionCells = document => [
        <button 
            title="Preview document" 
            type="button" 
            className="buttons-without-borders" 
            onClick={
                documentPreview => {
                    documentPreview.preventDefault();
                    setPreviewDocumentName(document.filename);
                    setPreviewUid(document.uid);
                    setPreviewPageNum(1);
                }
            }
        >
            <BsEyeFill className="action-icons show-icon"/>
        </button>,
        <button 
            title="Delete document" 
            type="button" 
            className="buttons-without-borders" 
            onClick={
                deleteDocumentClicked => {
                    deleteDocumentClicked.preventDefault();
                    const params = directoryPath[0]
                        ?{directory_path: encodeURIComponent(directoryPath.join("/") + "/")}
                        :{};
                    axios.delete(
                        `${URLORIGIN}/jobs/downloads/${row.uid}`, {
                            baseURL: "",
                            withCredentials: true,
                            params: params,
                            paramsSerializer: params => {
                                return qs.stringify(
                                    params, { arrayFormat: "repeat" }
                                )
                            }
                        }
                    ).then(
                        () =>{
                            const new_documents = documents.filter(
                                document => document.uid !== row.uid
                            )
                            setDocuments(
                                JSON.parse(
                                    JSON.stringify(
                                        new_documents
                                    )
                                )
                            );
                            setOldDocumentsList(
                                JSON.parse(
                                    JSON.stringify(
                                        new_documents
                                    )
                                )
                            )
                        }
                    );
                }

            }
        >
            <MdDeleteForever className="action-icons delete-icon"/>
        </button>
    ];
    return(
        <tr>
            {
                isEdit
                    ?null
                    :<td>
                        <input
                        type="checkbox"
                        id={`document-${rowIndex}-filename`}
                        name="document-checkbox"/>
                    </td>
            }
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    (column) =>{
                        return (
                            <td key={`document-${rowIndex}-${column.name}`}>
                                {
                                    (column.name==="created_timestamp")
                                        ?row[column.name].replace(/T/g,' ')
                                        :(column.name==="filename" && !isEdit)
                                            ?<input
                                                id={`file-${page}-${rowIndex}`}
                                                type="text"
                                                placeholder="File name"
                                                defaultValue={row.filename}
                                                onChange={
                                                    newNameInput => {
                                                        newNameInput.preventDefault();
                                                        const new_documents = documents.map(
                                                            document => {
                                                                if (document.uid===row.uid){
                                                                    document.filename=newNameInput.target.value;
                                                                }
                                                                return document;
                                                            }
                                                        )
                                                        setDocuments(JSON.parse(JSON.stringify(new_documents)));
                                                    }
                                                }
                                            />
                                            :row[column.name]
                                }
                            </td>
                        )
                    }
                )
            }
            {
                isEdit
                    ?null
                    :(windowWidthCategory === "lg" || windowWidthCategory.includes("xl"))
                        ?actionCells(row).map(
                            (actionContainer, actionIndex)=>{
                                return (
                                    <td key={`document-action-${actionIndex}`}>
                                        {actionContainer}
                                    </td>
                                )
                            }
                        ) 
                        :<Actions actionCells={actionCells(row)}/>
            }
            
        </tr>
    )
}
function JobDirectoryRow(
    {
        job_no,
        row,
        rowIndex,
        page,
        columnPriority,
        columnCount,
        directories,
        setDirectories,
        setOldDirectories,
        directoryPath,
        setDirectoryPath,
        windowWidthCategory
    }
){
    const actionCells = folder_info => [
        <button
            title="Open directory"
            type="button"
            className="buttons-without-borders"
            onClick={
                directoryOpen => {
                    directoryOpen.preventDefault();
                    directoryPath.push(folder_info.folder);
                    setDirectoryPath(
                        JSON.parse(
                            JSON.stringify(
                                directoryPath
                            )
                        )
                    );
                }
            }
        >
            <FaFolderOpen className="action-icons open-folder-icon"/>
        </button>,
        <button
            title="Delete directory"
            type="button"
            className="buttons-without-borders"
            onClick={
                directoryDelete => {
                    directoryDelete.preventDefault();
                    axios.delete(
                        `${URLORIGIN}/jobs/folders/${job_no}/${folder_info.uid}`, {
                            baseURL: "",
                            withCredentials: true
                        }
                    ).then(
                        ()=>{
                            const newDirectories = directories.filter(
                                directory => directory.uid !== folder_info.uid
                            );
                            setDirectories(
                                JSON.parse(
                                    JSON.stringify(
                                        newDirectories
                                    )
                                )
                            );
                            setOldDirectories(
                                JSON.parse(
                                    JSON.stringify(
                                        newDirectories
                                    )
                                )
                            );
                        }
                    )
                }
            }
        >
            <MdDeleteForever className="action-icons delete-icon"/>
        </button>
    ];
    return(
        <tr>
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    column => {
                        return (
                            <td key={`document-${rowIndex}-${column.name}`}>
                                <input
                                    id={`directory-${page}-${rowIndex}`}
                                    type="text"
                                    placeholder="Folder"
                                    defaultValue={row.folder}
                                    onChange={
                                        newNameInput => {
                                            newNameInput.preventDefault();
                                            const newDirectories = directories.map(
                                                directory => {
                                                    if (directory.uid===row.uid)directory.folder=newNameInput.target.value;
                                                    return directory;
                                                }
                                            )
                                            setDirectories(
                                                JSON.parse(
                                                    JSON.stringify(
                                                        newDirectories
                                                    )
                                                )
                                            );
                                        }
                                    }
                                    />
                            </td>
                        )
                    }
                )
            }
            {
                (windowWidthCategory==="lg" || windowWidthCategory.includes("xl") || windowWidthCategory==="md")
                    ?actionCells(row).map(
                        (actionContainer, actionIndex)=>{
                            return (
                                <td key={`directory-action-${actionIndex}`}>
                                    {actionContainer}
                                </td>
                            )
                        }
                    )
                    :<Actions actionCells={actionCells(row)}/>
            }
        </tr>
    )
}