export const dateYmdhms = dateTime => dateTime.getFullYear() 
+ '-' 
+ (dateTime.getMonth()>8?'':'0') 
+ (dateTime.getMonth() + 1)
+ '-'
+ (dateTime.getDate()>9?'':'0')
+ dateTime.getDate()
+ '--'
+ (dateTime.getHours()>9?'':'0')
+ dateTime.getHours()
+ '-'
+ (dateTime.getMinutes()>9?'':'0')
+ dateTime.getMinutes()
+ '-'
+ (dateTime.getSeconds()>9?'':'0')
+ dateTime.getSeconds();

export const dateYmd = date => date.getFullYear() 
+ '-' 
+ (date.getMonth()>8?'':'0') 
+ (date.getMonth() + 1)
+ '-'
+ (date.getDate()>9?'':'0')
+ date.getDate();

export const dateYmdhm = date => date.toLocaleString(
    undefined, {
        day: 'numeric', month: 'short', year: 'numeric'
    }
) 
+ " "
+ date.toLocaleString(
    undefined, {
        hour: '2-digit', minute: '2-digit'
    }
)

export const dateYmdh = dateTime => dateTime.getFullYear() 
+ '-' 
+ (dateTime.getMonth()>9?'':'0') 
+ dateTime.getMonth()
+ '-'
+ (dateTime.getDate()>9?'':'0')
+ dateTime.getDate()
+ "--"
+ (dateTime.getHours()>9?'':'0')
+ dateTime.getHours()