import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import {BsSearch, BsPencilFill} from "react-icons/bs";
import {MdDeleteForever, MdReadMore} from "react-icons/md";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {URLORIGIN} from "../../config.js";
import {ChooseFromList, AlertDialog, FullTable, ChangeColumn, Actions} from "../../template";
import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';

function fetchUsers(
    firstRender,
    searchUserName,
    searchRole,
    setUserList,
    setAllUserNames,
    navigate,
    columnPriority,
    setTableKey,
    tableKey
    ) {
        const userListParams = {}
        if (searchUserName){
            userListParams.username = searchUserName;
        }
        if (searchRole){
            userListParams.role = searchRole;
        }
        for (const column of columnPriority){
            if (column.ascending || column.descending){
                userListParams.sort = column.name;
                userListParams.sort_ascending = column.ascending;
                userListParams.sort_descending = column.descending;
            }
        }
        axios.get(
            `${URLORIGIN}/users`, {
                baseURL: "",
                withCredentials: true, 
                params: userListParams
            }
        ).then(
            response => {
                if (response.data) {
                    setUserList(response.data.users);
                    if (firstRender){
                        setAllUserNames(response.data.users.map((user) => user.username));
                    }
                }
            }
        ).catch(
            error=>{
                if (error.response.status === 303) navigate(error.response.data.url);
            }
        )
    };


export function UserList({windowWidthCategory}){
    const navigate = useNavigate();
    const isSuperAdmin = JSON.parse(window.sessionStorage.getItem("isSuperAdmin"));
    const [myAdminRoles, setMyAdminRoles] = React.useState(
        JSON.parse(
            window.sessionStorage.getItem(
                "adminRoles")));
    const [searchRole, setSearchRole] = React.useState("");
    const [allUserNames, setAllUserNames] = React.useState("");
    const searchUserName = "";
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [userList, setUserList] = React.useState([]);
    const [firstRender, setFirstRender] = React.useState(true);
    const [addRoleOpen, setAddRoleOpen] = React.useState(false);
    const [roleExistsErrorOpen, setRoleExistsErrorOpen] = React.useState(false);
    const [tableKey, setTableKey] = React.useState(0);
    const defaultColumnPriority = [
        {
            name: "username",
            ascending: false,
            descending: false
        }, 
        {
            name: "display_name",
            ascending: false,
            descending: false
        },
        {
            name: "department",
            ascending: false,
            descending: false
        },
        {
            name: "position",
            ascending: false,
            descending: false
        },
        {
            name: "roles",
            ascending: false,
            descending: false
        },
        {
            name: "modified_date",
            ascending: false,
            descending: false
        },
        {
            name: "modifier",
            ascending: false,
            descending: false
        }
    ]
    const [columnPriority, setColumnPriority] = React.useState(
        defaultColumnPriority);
    const setAndSaveColumnPriority = (updatedColumnPriority) => {
        setColumnPriority(updatedColumnPriority);
        setTableKey(tableKey + 1);
    };
    const tableHeadersDictionary={ 
        'username': 'Username', 
        'display_name': 'Display Name', 
        'department': 'Department', 
        'position': 'Position', 
        'roles': 'Roles',
        "modified_date": "Modified Date",
        "modifier": "Modifier"
    };
    const tableActionHeaders=["Edit", "Delete"];
    const preTableRowButtons=[
        {idKey: "table-create", 
        onClickFunction: (clicked)=>{
            clicked.preventDefault(); 
            navigate("/users/create");}, 
        text: "Add User"},
        {
            idKey: "table-edit",
            onClickFunction: (clicked)=>{
                clicked.preventDefault();
                navigate("/users/me")
            },
            text: "My Profile"
        }
    ]
// Add Role -> Edit role popup list.
    function searchUserNameAndRoles(userNameAndRoleQuerySubmit){
        userNameAndRoleQuerySubmit.preventDefault();
        document.getElementById('username-search-input').blur();
        document.getElementById('role-input').blur();
        fetchUsers(
            firstRender, 
            userNameAndRoleQuerySubmit.target[0].value, 
            searchRole, 
            setUserList, 
            setAllUserNames,
            navigate,
            columnPriority
        )
    }
    const submitRole = roleSubmit => {
        roleSubmit.preventDefault();
        var newRole = roleSubmit.target[0].value.trim()
        newRole = newRole.charAt(0).toUpperCase() + newRole.slice(1).toLowerCase();
        if (newRole.toLowerCase() === "security guard"){
            setAddRoleOpen(false);
            setRoleExistsErrorOpen(true);
        } else{
            axios({
                method: 'POST',
                baseURL: "",
                url: `${URLORIGIN}/users/role`,
                withCredentials: true, 
                data: newRole}
            ).then(
                response => {
                    setAddRoleOpen(false);
                    if (response.data.role_exists){
                        setRoleExistsErrorOpen(true);
                    } else{
                        myAdminRoles.push(newRole);
                        setMyAdminRoles(myAdminRoles);
                        window.sessionStorage.setItem("adminRoles", JSON.stringify(myAdminRoles));
                        const roles = JSON.parse(window.sessionStorage.getItem("roles"));
                        roles.push(newRole);
                        window.sessionStorage.setItem("roles", JSON.stringify(roles));
                    }
                }
            )
        }
    }
    const tableHeader = (
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount
        ) => {
            return(
                <UserHeader 
                columnPriority={columnPriority} 
                setAndSaveColumnPriority={setAndSaveColumnPriority}
                windowWidthCategory={windowWidthCategory}
                columnCount={columnCount}
                tableHeadersDictionary={tableHeadersDictionary}
                tableActionHeaders={tableActionHeaders}
                />
            )
        }
    const actionCells = row => [
        <button
        title="Edit User"
        type="button"
        className="buttons-without-borders"
        onClick={()=>navigate(`/users/${row.username}`)}>
            <BsPencilFill  className="action-icons pencil-icon"/>
        </button>,
        row.roles.some(role => role.is_admin) && !isSuperAdmin
        ? null
        : <DeleteUser 
        username={row.username} 
        userList={userList} 
        allUserNames={allUserNames} 
        setUserList={setUserList} 
        setAllUserNames={setAllUserNames}/>];
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            <UserRow key={`user-${rowIndex}`}
            row={row}
            rowIndex={rowIndex}
            columnPriority={columnPriority}
            columnCount={columnCount}
            windowWidthCategory={windowWidthCategory}
            actionCells={actionCells}/>)
    }

    React.useEffect(
        ()=>{
            fetchUsers(
                firstRender, 
                searchUserName, 
                searchRole, 
                setUserList, 
                setAllUserNames,
                navigate,
                columnPriority,
                setTableKey,
                tableKey
            );
            setFirstRender(false);
        }, 
        [columnPriority])

    return(
        <article id="user-list-entire-container">
            <SearchUserNameAndRoles
            searchUserNameAndRoles={searchUserNameAndRoles}
            allUserNames={allUserNames}
            myAdminRoles={myAdminRoles}
            setSearchRole={setSearchRole}
            searchRole={searchRole}/>
            <FullTable  
            title="User List"
            tableKey={tableKey}
            rowCount={rowCount} 
            page={page} 
            setRowCount={setRowCount} 
            setPage={setPage} 
            totalRowCount={userList.length}
            data={
                userList.slice(
                    (page - 1) * rowCount, 
                    page * rowCount)}
            defaultColumnPriority={defaultColumnPriority}
            columnPriorityKey="user"
            windowWidthCategory={windowWidthCategory}
            preTableRowButtons={preTableRowButtons}
            tableRow={tableRow}
            tableHeader={tableHeader}
            defaultColumnCount={1}
            columnPriority={columnPriority}
            setAndSaveColumnPriority={setAndSaveColumnPriority}
            />
            <Dialog
              open={addRoleOpen}
              onClose={() => {setAddRoleOpen(false);}}
              aria-labelledby='alert-dialog-title'
              aria-describedby='add-role-form'
            >
              <DialogContent>
                <form id="add-role-form" onSubmit={submitRole}>
                  <div id="add-role-form-container">
                    <label>
                      New role: 
                    </label>
                    <input 
                      required 
                      id="add-role-form-field"
                      type="text" 
                      name="NewRole" 
                      placeholder="Role" 
                      autoFocus/>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <button type="submit" form="add-role-form" id="alert-first-button" className="alert-button">Confirm</button>
                <button type="button" id="alert-second-button" onClick={()=>setAddRoleOpen(false)} className="alert-button">Cancel</button>
              </DialogActions>
            </Dialog>
            <AlertDialog 
            alertOpen={roleExistsErrorOpen} 
            setAlertOpen={setRoleExistsErrorOpen} 
            alertText="Role already existed." 
            action=""/>
        </article>
    )
}

function SearchUserNameAndRoles({
    searchUserNameAndRoles, 
    allUserNames, 
    myAdminRoles,
    setSearchRole,
    searchRole}){
    return(
        <section id="search-username-and-roles">
            <h3 id="search-username-and-roles-header">Search</h3>
            <form
            id="search-username-and-roles-form"
            onSubmit={searchUserNameAndRoles}>
                <Autocomplete
                disablePortal
                id='username-search-input'
                options={allUserNames?allUserNames:[""]}
                freeSolo
                sx={{ width: 2/5 }}
                renderInput={
                    (params) => <TextField{...params} 
                    label="Username" />}
                />
                <ChooseFromList 
                list={myAdminRoles} 
                setItem={setSearchRole} 
                item={searchRole} 
                idKey="role" 
                placeholder="Role" 
                cssClass="user-search-field" 
                defaultText="None"/>
                <button 
                title="Search" 
                className="search-button" 
                type="submit" 
                form="search-username-and-roles-form">
                    <BsSearch/>
                </button>
            </form>
        </section>
    )
}

function UserHeader(
    {columnPriority, 
    setAndSaveColumnPriority, 
    windowWidthCategory, 
    columnCount,
    tableHeadersDictionary,
    tableActionHeaders}
    ){
    return(
        <thead id="table-header">
            <tr>
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column, columnIndex) => {
                            return (
                                (column.name==="roles")
                                    ?<ChangeColumn 
                                        key={`rendered-${column}-header`}
                                        column={column}
                                        columnIndex={columnIndex}
                                        columnPriority={columnPriority} 
                                        tableHeadersDictionary={tableHeadersDictionary}
                                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                                        noSort={true}
                                    />
                                    :<ChangeColumn 
                                        key={`rendered-${column}-header`}
                                        column={column}
                                        columnIndex={columnIndex}
                                        columnPriority={columnPriority} 
                                        tableHeadersDictionary={tableHeadersDictionary}
                                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                                        noSort={false}
                                    />
                            )
                        }
                    )
                }
                {windowWidthCategory === "lg" || windowWidthCategory.includes("xl") ?
                tableActionHeaders.map(
                    (action) => {
                        return (
                            <th key={`render-${action}-header`}>
                                {action}
                            </th>
                        )
                    }
                ) :
                <th>
                    Action
                </th>
                }
            </tr>
        </thead>
    )
}

function DeleteUser({
    username, 
    userList,
    allUserNames,
    setUserList,
    setAllUserNames}){
    const deleteUser = userDelete => {
        userDelete.preventDefault();
        axios.delete(
            `${URLORIGIN}/users/${username}`, {
                baseURL: "",
                withCredentials: true}
        ).then(
            () => {
                setUserList(userList.filter(user => user.username !== username));
                setAllUserNames(allUserNames.filter(usernameIter => usernameIter !== username));
            }
        )
    }
    return (
        <div>
            <button title="Delete user" type="button" className="buttons-without-borders" onClick={deleteUser}>
                <MdDeleteForever className="action-icons delete-icon"/>
            </button>
        </div>
    )
}

function UserRow(
    {row, 
    rowIndex, 
    columnPriority, 
    columnCount, 
    windowWidthCategory, 
    actionCells}){
    return(
        <tr>
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    (column) =>{
                        return(
                            column.name==="roles"
                            ?row.roles.length===1
                                ?<td key={`user-${rowIndex}-roles`}>
                                    {row.roles[0].is_admin
                                    ?`Admin - ${row.roles[0].role}`
                                    :row.roles[0].role}
                                </td>
                                :<Roles key={`user-${rowIndex}-roles`} roles={row.roles}/>
                            :<td key={`user-${rowIndex}-${column.name}`}>
                                {row[column.name]}
                            </td>)
                        
                    }
                )
            }
            {windowWidthCategory === "lg" || windowWidthCategory.includes("xl") || (actionCells(row).filter(action => action?true:false).length === 1)
            ?actionCells(row).map(
                (actionContainer, actionIndex)=>{
                    return (
                        <td key={`user-action-${actionIndex}`}>
                            {actionContainer}
                        </td>
                    )
                }
            ) 
            :<Actions actionCells={actionCells(row)}/>
            }
        </tr>
    )
}

export function Roles({roles}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const roleListIsOpen = Boolean(anchorEl);
    return(
        <td>
            <button
                title="roles"
                id="roles-button"
                className="buttons-without-borders"
                aria-controls={roleListIsOpen ? 'roles-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={roleListIsOpen ? 'true' : undefined}
                onClick={(event) => {setAnchorEl(event.currentTarget)}}
            >
                <MdReadMore className="action-icons three-lines"/>
            </button>
            <Menu
                id="roles-menu"
                anchorEl={anchorEl}
                open={roleListIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': 'roles-button',
                }}
            >
                {roles.map(
                    (role, roleIndex)=>{
                        return (
                            <MenuItem 
                            key={`role-${roleIndex}`}
                            onClick={()=>setAnchorEl(null)}
                            >
                                {role.is_admin
                                ?`Admin(${role.role})`
                                :role.role}
                            </MenuItem>
                        )
                    }
                )}
            </Menu>
        </td>
    )
}