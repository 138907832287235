import React from "react";
import axios from "axios";
import {useParams, useNavigate} from 'react-router-dom';

import {MdDeleteForever} from "react-icons/md";
import {TbFileAlert} from "react-icons/tb";
import FormData from 'form-data';

import {URLORIGIN} from "../../config.js";
import {dateYmdhm} from "../../functions.js";
import {TopRow, FullTable, ReadonlyField} from "../../template";
import "react-calendar/dist/Calendar.css";
import "./overview.scss";
import './create.scss';

export default function UploadDocuments({windowWidthCategory}){
    const {job_no} = useParams();
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [documents, setDocuments] = React.useState([]);
    const [temp, setTemp] = React.useState(0);
    const [otherFilenames, setOtherFilenames] = React.useState([]);
    const navigate = useNavigate();
    const _ = require('lodash');
    
    const preTableRowButtons=[
        {
            idKey: "table-edit", 
            onClickFunction: filesChoose => {
                filesChoose.preventDefault();
                document.getElementById("choose-files").click()
            }, 
            text: "Choose files"
        }
    ]

    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        const fileIndex = (page-1) * rowCount + rowIndex;
        return(
            <tr key={`document-${rowIndex}`}>
                <td className="file-alert" title="An file with the same name has already been uploaded.">
                    {
                        otherFilenames.includes(row.new_name)
                            ?<TbFileAlert/> 
                            :null
                    }
                </td>
                <td>
                    <input
                        id={`file-${page}-${rowIndex}`}
                        type="text"
                        placeholder="File name"
                        defaultValue={row.new_name}
                        onInput={
                            newNameInput => {
                                newNameInput.preventDefault();
                                documents[fileIndex].new_name = newNameInput.target.value;
                                setDocuments(_.cloneDeep(documents));
                            }
                        }
                    />
                </td>
                <td>
                    <button 
                    title="Delete document" 
                    type="button" 
                    className="buttons-without-borders" 
                    onClick={
                        deleteDocumentClicked => {
                            deleteDocumentClicked.preventDefault();
                            setDocuments(
                                _.cloneDeep(
                                    documents.filter(
                                        document => document.new_name !== row.new_name
                                    )
                                )
                            );
                            setTemp(temp + 1);
                        }
                    }>
                        <MdDeleteForever 
                            className="action-icons delete-icon"/>
                    </button>
                </td>
            </tr>)
    }
    const uploadDocuments = documentsUpload => {
        documentsUpload.preventDefault();
        const formData = new FormData();
        for (const document in documents){
            formData.append(
                "files",
                documents[document].file
            );
            formData.append(
                "new_names",
                documents[document].new_name
            );
        }
        axios({
            baseURL: "",
            withCredentials: true, 
            method: 'POST',
            url: `${URLORIGIN}/jobs/uploads/${job_no}`,
            data: formData,
            headers: {
                "Content-type": "multipart/form-data",
            },
        }).then(() => navigate("/jobs"));
    }
    React.useEffect(
        ()=>{
            axios.get(
                `${URLORIGIN}/jobs/uploads/${job_no}`,
                {
                    baseURL: "",
                    withCredentials: true
                }
            ).then(
                response => setOtherFilenames(
                    JSON.parse(
                        JSON.stringify(
                            response.data.filenames
                        )
                    ).map(
                        file => file.filename
                    )
                )
            );
        }, [job_no]
    )
    return (
        <article id="upload-documents-container">
            <TopRow 
            headerText="Upload Documents"
            buttons={[
                {
                    idKey: "text-save", 
                    text: "Upload", 
                    onClickFunction: documentsUpload => {
                        (documents.length > 0)
                            ?uploadDocuments(documentsUpload)
                            :navigate("/jobs")
                    }
                }, 
                {
                    idKey: "text-back",
                    text: "Cancel",
                    onClickFunction: clicked =>{
                        // clicked.preventDefault();
                        navigate("/jobs");
                    }
                }
            ]}/>

            <UploadSessionMetaData
            jobID={job_no}
            />
            <input 
            id="choose-files" 
            type="file" 
            onChange={
                filesChosen => {
                    filesChosen.preventDefault();
                    setDocuments(
                        _.cloneDeep(
                            documents.concat(
                                Array.from(
                                    filesChosen.target.files
                                ).map(
                                    (file) => {
                                        return {
                                            file: file,
                                            new_name: file.name
                                        }
                                    }
                                )
                            )
                        )
                    );
                    setTemp(temp+1);
                }
            } 
            multiple 
            hidden/>
            <FullTable  
            tableKey={temp}
            rowCount={rowCount} 
            page={page} 
            setRowCount={setRowCount} 
            setPage={setPage} 
            totalRowCount={documents.length}
            data={
                documents[0]
                ?documents.slice(
                    (page - 1) * rowCount, 
                    page * rowCount)
                :[]
            }
            defaultColumnPriority={[]}
            columnPriorityKey="documents"
            windowWidthCategory={windowWidthCategory}
            preTableRowButtons={preTableRowButtons}
            tableRow={tableRow}
            tableHeader={ () =>
                <thead id="table-header">
                    <tr>
                        <th></th>
                        <th>File</th>
                        <th>Delete</th>
                    </tr>
                </thead>}
            defaultColumnCount={3}
            columnPriority={[]}
            />
        </article>
    )
}

function UploadSessionMetaData({jobID}){
    const displayName = window.sessionStorage.getItem('displayName');
    const setDate = () => {
        if (document.getElementById("date-input")?true:false){
            document.getElementById("date-input").value = dateYmdhm(new Date());
        }
    }
    setInterval(setDate, 1000);
    return(
        <form
        id="upload-documents-form">
            <div className="dual-fields-container">
                <ReadonlyField 
                value={jobID} 
                label="Job ID" 
                idKey="job-id" 
                placeholder="Job ID" 
                fullLength="" 
                cssClass="left-field short-label"/>
                <ReadonlyField 
                value={displayName} 
                label="Uploader" 
                idKey="uploader" 
                placeholder="Uploader" 
                fullLength="" 
                cssClass="right-field short-label"/>
                <ReadonlyField 
                value={dateYmdhm(new Date())} 
                label="Date" 
                idKey="date" 
                placeholder="Date" 
                fullLength="" 
                cssClass="left-field short-label"/>
            </div>
        </form>
    )
}