import {BsSearch} from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import './create.scss';
import './overview.scss';

export default function SearchCustomerAndVessel({searchCustomerAndShips, totalCustomerNames, totalShipRegNos}){
    return(
        <section id="search-customer-and-vessel">
            <h3 id="search-customer-and-vessel-header">Search</h3>
            <form
            id="search-customer-and-vessel-form"
            onSubmit={searchCustomerAndShips}>
                <div className="search-customer-and-vessel-input-container">
                    <Autocomplete
                        disablePortal
                        id='customer-name-search-input'
                        options={totalCustomerNames?totalCustomerNames:[""]}
                        freeSolo
                        renderInput={
                            (params) => <TextField{...params} 
                                label="Customer Name" 
                            />
                        }
                    />
                </div>
                <div className="search-customer-and-vessel-input-container">
                    <Autocomplete
                        disablePortal
                        id='ship-reg-no-search-input'
                        freeSolo
                        options={totalShipRegNos?totalShipRegNos:[""]}
                        renderInput={
                            (params)=><TextField{...params} 
                                label="Vessel Reg. No." 
                            />
                        }
                    />
                </div>
                <button title="Search" 
                className="search-button" 
                type="submit" 
                form="search-customer-and-vessel-form">
                    <BsSearch/>
                </button>
            </form>
        </section>
    )
}