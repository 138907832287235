import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import {BsSearch, BsPencilFill} from "react-icons/bs";
import {MdDeleteForever, MdReadMore} from "react-icons/md";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {URLORIGIN} from "../../config.js";
import {FullTable, ChangeColumn, Actions} from "../../template";
import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';

function fetchCustomers(
    firstRender,
    searchCustomerName,
    setCustomerList,
    setAllCustomerNames,
    navigate,
    columnPriority
) {
        var customerListParams = {}
        if (searchCustomerName){
            customerListParams.customer_name = searchCustomerName;
        }
        for (const column of columnPriority){
            if ((column.name !== "ships") && (column.ascending || column.descending)){
                customerListParams.sort = column.name;
                customerListParams.sort_ascending = column.ascending;
                customerListParams.sort_descending = column.descending;
            }
        }
        axios.get(
            `${URLORIGIN}/customers`, {
                baseURL: "",
                withCredentials: true, 
                params: customerListParams}
        ).then(response => {
            if (response.data) {
                setCustomerList(response.data.customers);
                if (firstRender){
                    setAllCustomerNames(
                        response.data.customers.map(
                            (customer) => customer.customer_name));
                }
            }
        }).catch (error => {
            if (error.response.status === 303) {
                navigate(error.response.data.url)
            }
        });
    };

export function CustomerList({windowWidthCategory}){
    const navigate = useNavigate();
    const [allCustomerNames, setAllCustomerNames] = React.useState("");
    const searchCustomerName = "";
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [customerList, setCustomerList] = React.useState([]);
    const [firstRender, setFirstRender] = React.useState(true);
    const [tableKey, setTableKey] = React.useState(0);
    const defaultColumnPriority = [
        {
            name: "customer_no",
            ascending: false,
            descending: false
        },
        {
            name: "customer_name",
            ascending: false,
            descending: false
        },
        {
            name: "contact_person",
            ascending: false,
            descending: false
        },
        {
            name: "contact_number",
            ascending: false,
            descending: false
        },
        {
            name: "ships",
            ascending: false,
            descending: false
        }
    ]
    const [columnPriority, setColumnPriority] = React.useState(
        defaultColumnPriority
    );
    const setAndSaveColumnPriority = (updatedColumnPriority) => {
        setColumnPriority(updatedColumnPriority);
        localStorage.setItem(
            `customerColumnPriority`, 
            JSON.stringify(updatedColumnPriority)
        );
        setTableKey(tableKey + 1);
    };
    // const setAndSaveColumnPriority = () => {};
    const tableHeadersDictionary={ 
        "customer_no": "Customer ID",
        "customer_name": "Customer Name", 
        "contact_person": "Contact Person", 
        "contact_number": "Contact Number", 
        "ships": "Vessels"
    };
    const tableActionHeaders=["Edit", "Delete"];
    const preTableRowButtons=[
        {idKey: "table-create", 
        onClickFunction: (clicked)=>{
            clicked.preventDefault();
            navigate("/customers/create");
        }, 
        text: "Add Customer"}
    ]

    function searchWithCustomerName(customerNameQuerySubmit){
        customerNameQuerySubmit.preventDefault();
        fetchCustomers(
            firstRender, 
            customerNameQuerySubmit.target[0].value, 
            setCustomerList, 
            setAllCustomerNames,
            navigate,
            columnPriority
        )
    }
    const tableHeader = (
        columnPriority, 
        setAndSaveColumnPriority, 
        columnCount
        ) => {
            return(
                <CustomerHeader 
                columnPriority={columnPriority} 
                setAndSaveColumnPriority={setAndSaveColumnPriority}
                windowWidthCategory={windowWidthCategory}
                columnCount={columnCount}
                tableHeadersDictionary={tableHeadersDictionary}
                tableActionHeaders={tableActionHeaders}
                />
            )
        }
    const actionCells = row => {return([
        <button
        title="Edit Customer"
        type="button"
        className="buttons-without-borders"
        onClick={()=>navigate(`/customers/edit/${row.customer_no}`)}>
            <BsPencilFill  className="action-icons pencil-icon"/>
        </button>,
        <DeleteCustomer 
        customerNo={row.customer_no} 
        customerName={row.customer_name}
        customerList={customerList} 
        allCustomerNames={allCustomerNames} 
        setCustomerList={setCustomerList} 
        setAllCustomerNames={setAllCustomerNames}/>])};
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            <CustomerRow key={`user-${rowIndex}`}
                row={row}
                rowIndex={rowIndex}
                columnPriority={columnPriority}
                columnCount={columnCount}
                windowWidthCategory={windowWidthCategory}
                actionCells={actionCells}/>
        )
    }
    React.useEffect(
        ()=>{
            fetchCustomers(
                firstRender, 
                searchCustomerName, 
                setCustomerList, 
                setAllCustomerNames,
                navigate,
                columnPriority
            );
            setFirstRender(false);
        }, 
        [columnPriority]
    )

    return(
        <article id="user-list-entire-container">
            <SearchCustomerName
                searchWithCustomerName={searchWithCustomerName}
                allCustomerNames={allCustomerNames}
            />
            <FullTable  
                tableKey={tableKey}
                title="Customer List"
                rowCount={rowCount} 
                page={page} 
                setRowCount={setRowCount} 
                setPage={setPage} 
                totalRowCount={customerList.length}
                data={
                    customerList.slice(
                        (page - 1) * rowCount, 
                        page * rowCount
                    )
                }
                defaultColumnPriority={defaultColumnPriority}
                columnPriorityKey="customer"
                windowWidthCategory={windowWidthCategory}
                preTableRowButtons={preTableRowButtons}
                tableRow={tableRow}
                tableHeader={tableHeader}
                defaultColumnCount={1}
                columnPriority={columnPriority}
                setAndSaveColumnPriority={setAndSaveColumnPriority}
            />
        </article>
    )
}

function SearchCustomerName({
    searchWithCustomerName, 
    allCustomerNames}){
    return(
        <section id="search-customer-name">
            <h3 id="search-customer-name-header">Search</h3>
            <form
            id="search-customer-name-form"
            onSubmit={searchWithCustomerName}>
                <Autocomplete
                disablePortal
                id='customer-name-search-input'
                options={allCustomerNames?allCustomerNames:[""]}
                freeSolo
                sx={{ width: 2/5 }}
                renderInput={
                    (params) => <TextField{...params} 
                    label="Customer Name" />}
                />
            </form>
            <button 
            title="Search" 
            className="search-button" 
            type="submit" 
            form="search-customer-name-form">
                <BsSearch/>
            </button>
        </section>
    )
}

function CustomerHeader(
    {columnPriority, 
    setAndSaveColumnPriority, 
    windowWidthCategory, 
    columnCount,
    tableHeadersDictionary,
    tableActionHeaders}
    ){
    return(
        <thead id="table-header">
            <tr>
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column, columnIndex) => {
                            return (
                                (column.name==="ships")
                                    ?<ChangeColumn 
                                        key={`rendered-${column}-header`}
                                        column={column}
                                        columnIndex={columnIndex}
                                        columnPriority={columnPriority} 
                                        tableHeadersDictionary={tableHeadersDictionary}
                                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                                        noSort={true}
                                    />
                                    :<ChangeColumn 
                                        key={`rendered-${column}-header`}
                                        column={column}
                                        columnIndex={columnIndex}
                                        columnPriority={columnPriority} 
                                        tableHeadersDictionary={tableHeadersDictionary}
                                        setAndSaveColumnPriority={setAndSaveColumnPriority}
                                        noSort={false}
                                    />
                            )
                        }
                    )
                }
                {windowWidthCategory === "lg" || windowWidthCategory.includes("xl") ?
                tableActionHeaders.map(
                    (action) => {
                        return (
                            <th key={`render-${action}-header`}>
                                {action}
                            </th>
                        )
                    }
                ) :
                <th>
                    Action
                </th>
                }
            </tr>
        </thead>
    )
}

function DeleteCustomer({
    customerNo, 
    customerName,
    customerList,
    allCustomerNames,
    setCustomerList,
    setAllCustomerNames}){
    const deleteCustomer = customerDelete => {
        customerDelete.preventDefault();
        axios.delete(
            `${URLORIGIN}/customers/${customerNo}`, {
                baseURL: "",
                withCredentials: true
            }
        ).then(() => {
            setCustomerList(
                customerList.filter(
                    customer => customer.customer_no !== customerNo))
            setAllCustomerNames(
                allCustomerNames.filter(
                    customerNameIter => customerNameIter !== customerName))
        });
    }
    return (
        <div>
            <button 
            title="Delete Customer" 
            type="button" 
            className="buttons-without-borders" 
            onClick={deleteCustomer}>
                <MdDeleteForever 
                className="action-icons delete-icon"/>
            </button>
        </div>
    )
}

function CustomerRow(
    {row, 
    rowIndex, 
    columnPriority, 
    columnCount, 
    windowWidthCategory, 
    actionCells}){
    return(
        <tr>
            {
                columnPriority.slice(
                    0, columnCount
                ).map(
                    (column) =>{
                        return(
                            column.name==="ships"
                            ?row.ships.length===0
                                ?<td key={`user-${rowIndex}-roles`}></td>
                                :row.ships.length===1
                                    ?<td key={`user-${rowIndex}-roles`}>
                                        {row.ships[0].ship_reg_no
                                        ?row.ships[0].ship_reg_no
                                        :row.ships[0].ship_name}
                                    </td>
                                    :<Ships key={`user-${rowIndex}-roles`} ships={row.ships}/>
                            :<td key={`user-${rowIndex}-${column.name}`}>
                                {row[column.name]}
                            </td>)
                        
                    }
                )
            }
            {windowWidthCategory === "lg" || windowWidthCategory.includes("xl") ?
            actionCells(row).map(
                (actionContainer, actionIndex)=>{
                    return (
                        <td key={`user-action-${actionIndex}`}>
                            {actionContainer}
                        </td>
                    )
                }
            ) :
            <Actions actionCells={actionCells(row)}/>
            }
        </tr>
    )
}

export function Ships({ships}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const shipListIsOpen = Boolean(anchorEl);
    return(
        <td>
            <button
                title="ships"
                id="ships-button"
                className="buttons-without-borders"
                aria-controls={shipListIsOpen ? 'ships-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={shipListIsOpen ? 'true' : undefined}
                onClick={(event) => {setAnchorEl(event.currentTarget)}}
            >
                <MdReadMore className="action-icons three-lines"/>
            </button>
            <Menu
                id="ships-menu"
                anchorEl={anchorEl}
                open={shipListIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': 'ships-button',
                }}
            >
                {ships.map(
                    (ship, shipIndex)=>{
                        return (
                            <MenuItem 
                            key={`role-${shipIndex}`}
                            onClick={()=>setAnchorEl(null)}
                            >
                                {ship.ship_reg_no
                                ?ship.ship_reg_no
                                :ship.ship_name}
                            </MenuItem>
                        )
                    }
                )}
            </Menu>
        </td>
    )
}