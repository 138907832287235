import React from 'react';

import { IoCaretDown } from "react-icons/io5";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Calendar from 'react-calendar';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import {BsThreeDots, BsEyeFill} from "react-icons/bs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import "./index.scss";
import './components/jobs/overview.scss';
import './components/jobs/create.scss';


export function EditableField(
    {
        setter, 
        label, 
        placeholder, 
        idKey, 
        cssClass, 
        type, 
        defaultValue, 
        isRequired, 
        longfield,
        minLength,
        maxLength,
        title,
        pattern
    }
){
    return(
        <div className={cssClass}>
            <label className="normal-label">{label}</label>
            {
                pattern
                ?
                    longfield
                    ?
                        isRequired
                        ?
                            <textarea
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            pattern={pattern}
                            required
                            />
                        :
                            <textarea
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            pattern={pattern}
                            />
                    :
                        isRequired
                        ?
                            <input
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input normal-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            pattern={pattern}
                            required
                            />
                        :
                            <input
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input normal-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            pattern={pattern}
                            />
                :
                    longfield
                    ?
                        isRequired
                        ?
                            <textarea
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            required
                            />
                        :
                            <textarea
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            />
                    :
                        isRequired
                        ?
                            <input
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input normal-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            required
                            />
                        :
                            <input
                            title={title?title:""}
                            id={`${idKey}-input`}
                            className={`${idKey}-input normal-input`}
                            type={type?type:"text"}
                            placeholder={placeholder}
                            defaultValue={defaultValue?defaultValue:""}
                            onInput={
                                (changeEvent) => {
                                    changeEvent.preventDefault(); 
                                    setter(changeEvent.target.value.trim());}}
                            minLength={minLength?minLength:0}
                            maxLength={maxLength?maxLength:100000000}
                            />
            }
            {
                type=="password"
                ?
                <ShowPassword idKey="add" passwordFieldID="password-input"/>
                :null
            }
        </div>
    )
}

export function ReadonlyField({value, label, idKey, placeholder, cssClass, longfield}){
    return(
        <div className={cssClass}>
            <label className="normal-label">{label}</label>
            {
                longfield
                    ?<textarea
                    id={`${idKey}-input`}
                    className="readonly-field-non-label"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    readOnly/>
                    :<input
                    id={`${idKey}-input`}
                    className="readonly-field-non-label normal-input"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    readOnly/>
            }
        </div>
    )
}

export function ChooseFromList({list, setItem, item, label, idKey, placeholder, cssClass, defaultText, isRequired, defaultValue, isEditable, form}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const listIsOpen = Boolean(anchorEl);
    item=item?item:"";
    defaultValue=defaultValue?defaultValue:"";
    return(
        <div className={cssClass}>
            <label>{label}</label>
            <button
            type="button"
            id={`${idKey}-button`}
            aria-controls={listIsOpen ? `${idKey}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={listIsOpen ? 'true' : undefined}
            onClick={(event) => { setAnchorEl(event.currentTarget)}}
            >
                <div>
                    {
                        isRequired
                            ?isEditable
                                ?form
                                    ?<input
                                        id={`${idKey}-input`}
                                        type="text"
                                        placeholder={placeholder}
                                        value={item}
                                        onInput={
                                            (changeEvent) => {
                                                changeEvent.preventDefault(); 
                                                setItem(changeEvent.target.value);}}
                                        form={form}
                                        required
                                    />
                                    :<input
                                        id={`${idKey}-input`}
                                        type="text"
                                        placeholder={placeholder}
                                        value={item}
                                        onInput={
                                            (changeEvent) => {
                                                changeEvent.preventDefault(); 
                                                setItem(changeEvent.target.value);
                                            }
                                        }
                                        required
                                    />
                                :form
                                    ?<input
                                        id={`${idKey}-input`}
                                        type="text"
                                        placeholder={placeholder}
                                        value={item}
                                        onInput={
                                            (changeEvent) => {
                                                changeEvent.preventDefault(); 
                                                setItem(changeEvent.target.value);
                                            }
                                        }
                                        form={form}
                                        readOnly
                                        required
                                    />
                                    :<input
                                        id={`${idKey}-input`}
                                        type="text"
                                        placeholder={placeholder}
                                        value={item}
                                        onInput={
                                            (changeEvent) => {
                                                changeEvent.preventDefault(); 
                                                setItem(changeEvent.target.value);}}
                                        readOnly
                                        required
                                    />
                            :isEditable
                                ?<input
                                id={`${idKey}-input`}
                                type="text"
                                placeholder={placeholder}
                                value={item}
                                onInput={
                                    (changeEvent) => {
                                        changeEvent.preventDefault(); 
                                        setItem(changeEvent.target.value.trim());}}
                                />
                                :<input
                                id={`${idKey}-input`}
                                type="text"
                                placeholder={placeholder}
                                value={item}
                                onInput={
                                    (changeEvent) => {
                                        changeEvent.preventDefault(); 
                                        setItem(changeEvent.target.value.trim());}}
                                readOnly
                                />
                    }
                    <IoCaretDown/>
                </div>
            </button>
            <Menu
                name={`${idKey}-menu`}
                anchorEl={anchorEl}
                open={listIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': `${idKey}-button`,
                }}
            >
                {
                    (list === null)
                    ?null
                    :list.map(
                        (iter, index) => {
                            return(
                            <MenuItem 
                            key={`${idKey}-menu-${index}`} 
                            onClick={
                                listItemClicked => {
                                    setAnchorEl(null); 
                                    setItem(iter.trim());}}>
                                        <p>{iter}</p>
                                        </MenuItem>)})}
                {(defaultText && (item != defaultValue))?
                [<Divider key="divider" />,
                <MenuItem key="null" onClick={defaultItemClicked => {setAnchorEl(null); setItem(defaultValue)}}>{defaultText}</MenuItem>
                ]:null
                }
            </Menu>
        </div>
    )
}

export function CalendarButton({placeholder, setter, value, idKey, label, prependText, cssClass, isRequired, defaultValue, isChinese, form}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const listIsOpen = Boolean(anchorEl);
    defaultValue=defaultValue?defaultValue:null;
    return(
        <div className={cssClass}>
            {label?<label className="create-job-label">{label}</label>:null}
            <button
                type="button"
                name={`${idKey}-button`}
                aria-controls={listIsOpen ? `${idKey}-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={listIsOpen ? 'true' : undefined}
                onClick={(event) => {event.preventDefault();setAnchorEl(event.currentTarget)}}
            >
                <div>
                    {
                        isRequired
                            ?form
                                ?<input
                                    id={`${idKey}-input`}
                                    type="text"
                                    placeholder={placeholder}
                                    form={form}
                                    value={
                                        Object.prototype.toString.call(value) === '[object Date]'
                                        ?`${prependText}${value.toLocaleDateString(undefined, {day: 'numeric', month: 'short', year: 'numeric'})}`
                                        :`${prependText}`}
                                    readOnly
                                    required
                                />
                                :<input
                                    id={`${idKey}-input`}
                                    type="text"
                                    placeholder={placeholder}
                                    value={
                                        Object.prototype.toString.call(value) === '[object Date]'
                                        ?`${prependText}${value.toLocaleDateString(undefined, {day: 'numeric', month: 'short', year: 'numeric'})}`
                                        :`${prependText}`
                                    }
                                    readOnly
                                    required
                                />
                            :<input
                                id={`${idKey}-input`}
                                type="text"
                                placeholder={placeholder}
                                value={
                                    Object.prototype.toString.call(value) === '[object Date]'
                                    ?`${prependText}${value.toLocaleDateString(undefined, {day: 'numeric', month: 'short', year: 'numeric'})}`
                                    :`${prependText}`}
                                readOnly
                            />
                    }
                    <IoCaretDown/>
                </div>
            </button>
            <Menu
                name={`${idKey}-menu`}
                anchorEl={anchorEl}
                open={listIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': `${idKey}-button`,
                }}
            >
                <Calendar id={`${idKey}-calendar`} onChange={setter} value={value?value:null}/>
                {
                    (!value)
                    ?null
                    :[<Divider key="divider"/>,
                    <MenuItem key="defaultItem" onClick={(clickedDefault) => {clickedDefault.preventDefault();setAnchorEl(null); setter(null)}}>Unset date</MenuItem>]
                }
            </Menu>
        </div>
    )
}

export function PageSetter({rowCount, page, setRowCount, setPage, totalItemsCount, rowCountInputName, pageInputName}){
    const [totalPageNumber, setTotalPageNumber] = React.useState(Math.max(Math.ceil(totalItemsCount / rowCount),1));
    React.useEffect(()=>{
        setTotalPageNumber(Math.max(Math.ceil(totalItemsCount / rowCount),1))
    }, [totalItemsCount, rowCount]);
    React.useEffect(()=>{}, [rowCount]);
    React.useEffect(()=>{}, [page]);
    return(
        <div className="page-setter">
            <div>
                <form 
                name="rowCountForm"
                onSubmit={
                    changeRowCount => {
                        changeRowCount.preventDefault();
                        document.getElementById('row-count-input-1').value = changeRowCount.target[0].value;
                        document.getElementById('row-count-input-2').value = changeRowCount.target[0].value;
                        setRowCount(
                            changeRowCount.target[0].value);
                        }
                }>
                    <label>Row count: </label>
                    <input 
                    id={rowCountInputName}
                    type="text"
                    defaultValue={rowCount}/>
                    <input type="submit" hidden />
                </form>
            </div>
            <div>
                <button type="button" onClick={
                    leftButtonClicked=>{
                        leftButtonClicked.preventDefault();
                        if (page != 1){
                            document.getElementById('page-input-1').value = page-1;
                            document.getElementById('page-input-2').value = page-1;
                            setPage(page-1);
                        }}}>
                    <BiLeftArrow />
                </button>
                <form
                name='pageForm'
                 onSubmit={
                    changePage => {
                        changePage.preventDefault();
                        if ((changePage.target[0].value < 1) || (changePage.target[0].value > totalPageNumber)){
                            document.getElementById('page-input-1').value = page;
                            document.getElementById('page-input-2').value = page;
                        } else{
                            document.getElementById('page-input-1').value = changePage.target[0].value;
                            document.getElementById('page-input-2').value = changePage.target[0].value;
                            setPage(changePage.target[0].value);
                        }}
                }>
                    <input id={pageInputName} type="text" defaultValue={page}/>
                    <label>/{totalPageNumber}</label>
                    <input type="submit" hidden />
                </form>
                <button type="button" onClick={
                    rightButtonClicked=>{
                        rightButtonClicked.preventDefault();
                        if (totalPageNumber > page){
                            document.getElementById('page-input-1').value = page+1;
                            document.getElementById('page-input-2').value = page+1;
                            setPage(page + 1);
                        }}}>
                    <BiRightArrow/>
                </button>
            </div>
        </div>
    )
}

export function MultipleButtons({cssId, buttons}){
    return(
        <div id={cssId}>
            {
                buttons.map(
                    (button) => {
                        return(
                            button
                                ?button.form
                                    ?<button
                                        type="submit"
                                        id={`${button.idKey}-button`}
                                        key={`${button.idKey}-button`}
                                        form={button.form}
                                    >
                                        {button.text}
                                    </button>
                                    :button.icon
                                        ?<button
                                            type="button"
                                            className="icon-button"
                                            onClick={button.onClickFunction}
                                            id={`${button.idKey}-icon`}
                                            key={`${button.idKey}-icon`}
                                        >
                                            {button.icon}
                                        </button>
                                        :<button
                                            type="button"
                                            onClick={button.onClickFunction}
                                            id={`${button.idKey}-button`}
                                            key={`${button.idKey}-button`}
                                        >
                                            {button.text}
                                        </button>
                                :null
                        )
                    }
                )
            }
        </div>)
}

export function AlertDialog({alertOpen, setAlertOpen, alertText, alertTextWrapper, action}){
  return(
    <Dialog
      open={alertOpen}
      onClose={() => setAlertOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
          {
              alertText
              ?<DialogContentText id='alert-dialog-description'>
                {alertText}
              </DialogContentText>
              :alertTextWrapper
          }
      </DialogContent>
      <DialogActions>
        {action ? 
        <button
        type="button"
        onClick={action}
        id="alert-first-button"
        className="alert-button"
        >
          Confirm
        </button> :
        null
        }
        <button type="button" onClick={() => setAlertOpen(false)} id={action?"alert-second-button":"alert-first-button"} className="alert-button" autoFocus>
          {action?"Cancel":"OK"}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export function FullTable(
    {
        classKey,
        tableKey,
        title,
        rowCount, 
        page, 
        setRowCount, 
        setPage, 
        totalRowCount,
        data,
        defaultColumnPriority,
        columnPriorityKey,
        tableHeadersDictionary,
        windowWidthCategory,
        preTableRowButtons,
        tableRow,
        tableHeader,
        defaultColumnCount,
        columnPriority,
        setAndSaveColumnPriority,
        showBottomDivider
    }){
    return(
        <div id="full-table" key={tableKey?tableKey.toString():""} className={`${(classKey?classKey:"")} full-table`}>
            {title?<title id="table-title" >{title}</title>:null}
            <div>
                <hr/>
                <div id="pre-table-row-wrapper">
                    {preTableRowButtons?
                    <MultipleButtons
                    cssId="pre-table-buttons-wrapper"
                    buttons={preTableRowButtons}/>:
                    null}
                    <PageSetter 
                    rowCount={rowCount} 
                    page={page} 
                    setRowCount={setRowCount} 
                    setPage={setPage} 
                    totalItemsCount={totalRowCount}
                    rowCountInputName='row-count-input-1'
                    pageInputName='page-input-1' />
                </div>
                <hr/>
                <TableCore 
                    data={data} 
                    defaultColumnPriority={defaultColumnPriority} 
                    columnPriorityKey={columnPriorityKey}
                    windowWidthCategory={windowWidthCategory}
                    tableHeadersDictionary={tableHeadersDictionary}
                    tableRow={tableRow}
                    tableHeader={tableHeader}
                    defaultColumnCount={defaultColumnCount}
                    columnPriority={columnPriority}
                    setAndSaveColumnPriority={setAndSaveColumnPriority}
                />
                <hr/>
                <div id="post-table-row-wrapper">
                    <PageSetter 
                    rowCount={rowCount} 
                    page={page} 
                    setRowCount={setRowCount} 
                    setPage={setPage} 
                    totalItemsCount={totalRowCount} 
                    rowCountInputName='row-count-input-2'
                    pageInputName='page-input-2'/>
                </div>
                {
                    showBottomDivider
                        ?<hr/>
                        :null
                }
            </div>
        </div>
    )
}

export function TableCore({
    data, 
    defaultColumnPriority, 
    columnPriorityKey,
    windowWidthCategory,
    tableRow,
    tableHeader,
    defaultColumnCount,
    columnPriority,
    setAndSaveColumnPriority
}){   
    const computeColumnCount = () => {
        switch(windowWidthCategory){
            case "xxxxxxxl":
                return Math.min(11 - defaultColumnCount, columnPriority.length);
            case "xxxxxxl":
                return Math.min(10 - defaultColumnCount, columnPriority.length);
            case "xxxxxl":
                return Math.min(9 - defaultColumnCount, columnPriority.length);
            case "xxxxl":
                return Math.min(7 - defaultColumnCount, columnPriority.length);
            case "xxxl":
                return Math.min(7 - defaultColumnCount, columnPriority.length);
            case "xxl":
                return Math.min(6 - defaultColumnCount, columnPriority.length);
            case "xl":
                return Math.min(5 - defaultColumnCount, columnPriority.length);
            case "lg":
                return Math.min(4 - defaultColumnCount, columnPriority.length);
            case "md":
                return Math.min(3 - defaultColumnCount, columnPriority.length);
            case "sm":
                return Math.min(2 - defaultColumnCount, columnPriority.length);
            case "xs":
                return Math.min(2 - defaultColumnCount, columnPriority.length);
            default:
                return Math.min(2 - defaultColumnCount, columnPriority.length);
        }
    }
    const [columnCount, setColumnCount] = React.useState(computeColumnCount);


    React.useEffect(()=>{
        setColumnCount(computeColumnCount);
    }, [windowWidthCategory]);
    React.useEffect(() =>{
        if (localStorage.getItem(`${columnPriorityKey}ColumnPriority`) === null){
            localStorage.setItem(
                `${columnPriorityKey}ColumnPriority`, 
                JSON.stringify(defaultColumnPriority));
        }
    }, []);

        
    return(
        <table>
            {tableHeader(
                columnPriority, 
                setAndSaveColumnPriority, 
                columnCount)}
            <TableBody
            data={data}
            columnPriority={columnPriority}
            columnCount={columnCount}
            tableRow={tableRow}
            />
        </table>
    )
}

export function TableBody(
    {
        data, 
        columnPriority, 
        columnCount, 
        tableRow
    }){
        React.useEffect(() => {

        }, [columnCount])
        return(
            <tbody>
                {(data === null)? null :
                data.map(
                    (row, rowIndex) => {
                        return (
                            tableRow(row, rowIndex, columnPriority, columnCount)
                        )
                    }
                )}
            </tbody>
        )
}

export function ChangeColumn(
    {
        column, 
        columnIndex, 
        columnPriority, 
        tableHeadersDictionary, 
        setAndSaveColumnPriority,
        noSort
    }){
        const [anchorEl, setAnchorEl] = React.useState(null);
        const columnMenuIsOpen = Boolean(anchorEl);
        return(
            <th>
                <button
                    id="column-button"
                    aria-controls={columnMenuIsOpen ? 'column-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={columnMenuIsOpen ? 'true' : undefined}
                    onClick={(event) => {setAnchorEl(event.currentTarget)}}
                >
                    <p>
                        {tableHeadersDictionary[column.name]}
                    </p>
                    <IoCaretDown/>
                </button>
                {
                    noSort
                        ?null
                        :<>
                            <button
                                id="asc-button"
                                className={
                                    columnPriority[columnIndex].ascending
                                        ?"sort-button-active"
                                        :"sort-button-inactive"
                                }
                                onClick={
                                    ascClicked => {
                                        ascClicked.preventDefault(); 
                                        for (var index = 0; index < columnPriority.length; index++){
                                            columnPriority[index].ascending=(
                                                (!columnPriority[index].ascending)
                                                &&(index===columnIndex)
                                            );
                                            columnPriority[index].descending=false;
                                        }
                                        setAndSaveColumnPriority(
                                            JSON.parse(
                                                JSON.stringify(
                                                    columnPriority
                                                )
                                            )
                                        );
                                    }
                                }
                            >
                                <ImSortAlphaAsc/>
                            </button>
                            <button
                                id="desc-button"
                                className={
                                    columnPriority[columnIndex].descending
                                        ?"sort-button-active"
                                        :"sort-button-inactive"
                                }
                                onClick={
                                    descClicked => {
                                        descClicked.preventDefault(); 
                                        for (var index = 0; index < columnPriority.length; index++){
                                            columnPriority[index].ascending=false;
                                            columnPriority[index].descending=(
                                                (!columnPriority[index].descending)
                                                &&(index===columnIndex)
                                            );
                                        }
                                        setAndSaveColumnPriority(
                                            JSON.parse(
                                                JSON.stringify(
                                                    columnPriority
                                                )
                                            )
                                        );
                                    }
                                }
                            >
                                <ImSortAlphaDesc/>
                            </button>
                        </>
                }
                <Menu
                    id="column-menu"
                    anchorEl={anchorEl}
                    open={columnMenuIsOpen}
                    onClose={()=>{setAnchorEl(null)}}
                    MenuListProps={{
                        'aria-labelledby': 'column-button',
                    }}
                >
                    {columnPriority.map(
                        (pickedColumn, pickedColumnIndex) => {
                            return(
                                <MenuItem 
                                key={`change-to-${pickedColumn.name}`}
                                onClick={
                                    () => {
                                        setAnchorEl(null); 
                                        // columnPriority.splice(pickedColumnIndex, 1);
                                        // columnPriority.splice(columnIndex, 0, pickedColumn);
                                        [
                                            columnPriority[columnIndex], 
                                            columnPriority[pickedColumnIndex]
                                        ] = [
                                            columnPriority[pickedColumnIndex], 
                                            columnPriority[columnIndex]
                                        ];
                                        setAndSaveColumnPriority(
                                            columnPriority
                                        );
                                    }
                                }>
                                    <p className="menu-text">
                                        {tableHeadersDictionary[pickedColumn.name]}
                                    </p>
                                </MenuItem>
                            )
                        }
                    )}
                </Menu>
            </th>
        )
}

export function Actions({actionCells}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const actionListIsOpen = Boolean(anchorEl);
    return(
        <td>
            <button
                title="Actions for item"
                id="job-action-button"
                className="buttons-without-borders"
                aria-controls={actionListIsOpen ? 'job-action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={actionListIsOpen ? 'true' : undefined}
                onClick={(event) => {setAnchorEl(event.currentTarget)}}
            >
                <BsThreeDots className="action-icons three-dots"/>
            </button>
            <Menu
                id="job-action-menu"
                anchorEl={anchorEl}
                open={actionListIsOpen}
                onClose={()=>{setAnchorEl(null)}}
                MenuListProps={{
                    'aria-labelledby': 'job-action-button',
                }}
            >
                {actionCells.map(
                    (actionContainer, actionIndex)=>{
                        return (
                            <MenuItem key={`job-action-${actionIndex}`}>
                                {actionContainer}
                            </MenuItem>
                        )
                    }
                )}
            </Menu>
        </td>
    )
}

export function TopRow(
    {headerText, 
        buttons
    }){
    return(
        <section>
            <title
                className="view-edit-job-title">{headerText}
            </title>
            <MultipleButtons 
            cssId="view-edit-buttons-container" 
            buttons={buttons}/>
        </section>
    )
}

export function ShowPassword(
  {
    idKey,
    passwordFieldID
  }
){
  return(
    <button
      type="button"
      id={`show-password-eye-${idKey}`}
      className="buttons-without-borders show-password-eye"
      title="Show password"
      onClick={
          clicked => {
              clicked.preventDefault();
              if (document.getElementById(passwordFieldID).type == "text"){
                  document.getElementById(passwordFieldID).type = "password";
                  document.getElementById(`show-password-eye-${idKey}`).className="buttons-without-borders show-password-eye";
              } else {
                  document.getElementById(passwordFieldID).type = "text";
                  document.getElementById(`show-password-eye-${idKey}`).className="buttons-without-borders show-password-eye-active";
              }
          }
      }    
      >
          <BsEyeFill />
      </button>
  )
}