import React from 'react';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';

import {dateYmd} from "../../functions.js";
import {CalendarButton} from "../../template";
import {URLORIGIN} from '../../config.js';

import './security.scss';

export default function SecurityRecord(){
    const [
        inputDateAfter, 
        setInputDateAfter
    ] = React.useState(null);
    const [
        inputDateBefore, 
        setInputDateBefore
    ] = React.useState(null);
    const navigate = useNavigate();
    React.useEffect(
        ()=>{
            // Send authentication query to backend only upon first loading.
            axios.get(
                `${URLORIGIN}/security/record`, {
                    baseURL: "",
                    withCredentials: true}
            ).catch(
                error => {
                    if (error.response.status === 303) {
                        navigate(error.response.data.url)
                    }
                }
            )
            // TODO: Check if this is a time when the stream is available before opening a new socket, if not than open a blank rectangle with text: the security stream will be available from 00:00–13:59 and 17:00–23:59.
        }, []
    );
    return (
        <section id="security-record">
            <title>Security Record</title>
            <DownloadSecurityLog
            setInputDateAfter={setInputDateAfter}
            inputDateAfter={inputDateAfter}
            setInputDateBefore={setInputDateBefore}
            inputDateBefore={inputDateBefore}
            />
        </section>
    )
}
// TODO: Only security admin can access the security records.
function DownloadSecurityLog(
    {
        setInputDateAfter,
        inputDateAfter,
        setInputDateBefore,
        inputDateBefore
    }
){
    return(
        <div id="security-filter-row">
            <div>
                <CalendarButton
                placeholder="From: "
                setter={setInputDateAfter}
                value={inputDateAfter}
                idKey="date-after"
                label=""
                prependText="From: "
                cssClass="menu-button-container calendar-button-container"/>
                <CalendarButton
                placeholder="To: "
                setter={setInputDateBefore}
                value={inputDateBefore}
                idKey="date-before"
                label=""
                prependText="To: "
                cssClass="menu-button-container calendar-button-container"/>
            </div>
            <a 
            id="download-human-log"
            href={
                `${URLORIGIN}/security/record/${inputDateAfter?("from_"+dateYmd(inputDateAfter)+"_"):""}till_${(inputDateBefore?dateYmd(new Date(Math.min(inputDateBefore.valueOf(), new Date().valueOf()))):dateYmd(new Date()))+"_"}security_log.csv?${
                    (inputDateAfter?("date_after=" + dateYmd(inputDateAfter)):"")
                    + (inputDateBefore?("&date_before=" + dateYmd(inputDateBefore)):"")
                }`} 
            download>
                Download
            </a>
            {/* TODO: Put the time selection on hold, 
            basically set 3 typable input fields separated by colons, appended by a dropdown list of AM/PM. */}
            {/* TODO: Put the human footage download on hold. */}
        </div>
    )
}