import {useParams, useNavigate} from 'react-router-dom';
import React from 'react';
import axios from 'axios';

import DialogContentText from '@mui/material/DialogContentText';

import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';
import {URLORIGIN} from '../../config.js';
import {
    EditableField, 
    ReadonlyField, 
    AlertDialog, 
    TopRow,
    FullTable,
    ChangeColumn
} 
    from '../../template';
import '../../index.scss';
import '../jobs/overview.scss';
import '../jobs/create.scss';

export function AddCustomer({windowWidthCategory}){
    const isAdd=true;
    const customer_no = "";
    return (
    <AddOrEditCustomer 
    isAdd={isAdd} 
    pathCustomerNo={customer_no} 
    windowWidthCategory={windowWidthCategory}/>)
}

export function EditCustomer({windowWidthCategory}){
    const isAdd=false;
    const {customer_no} = useParams();
    return (
    <AddOrEditCustomer 
    isAdd={isAdd} 
    pathCustomerNo={customer_no} 
    windowWidthCategory={windowWidthCategory}/>)
}

function AddOrEditCustomer({isAdd, pathCustomerNo, windowWidthCategory}){
    const navigate = useNavigate();
    const [customerNo, setCustomerNo] = React.useState(pathCustomerNo);
    const [oldCustomerName, setOldCustomerName] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [oldAddressLn1, setOldAddressLn1] = React.useState("");
    const [addressLn1, setAddressLn1] = React.useState("");
    const [oldAddressLn2, setOldAddressLn2] = React.useState("");
    const [addressLn2, setAddressLn2] = React.useState("");
    const [oldAddressLn3, setOldAddressLn3] = React.useState("");
    const [addressLn3, setAddressLn3] = React.useState("");
    const [oldContactNumber, setOldContactNumber] = React.useState("");
    const [contactNumber, setContactNumber] = React.useState("");
    const [oldContactPerson, setOldContactPerson] = React.useState("");
    const [contactPerson, setContactPerson] = React.useState("");
    const [oldContactEmail, setOldContactEmail] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    const [ships, setShips] = React.useState([]);
    const [isRetired, setIsRetired] = React.useState(false);
    const [customerExistsErrorOpen, setCustomerExistsErrorOpen] = React.useState(false);
    const [existingCustomerIsDeleted, setExistingCustomerIsDeleted] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [tableKey, setTableKey] = React.useState(0);
    const tableHeadersDictionary={ 
        "ship_reg_no": "Vessel Reg. No.",
        "ship_name": "Vessel Name"
    };
    const defaultColumnPriority = [
        {
            name: "ship_reg_no",
            ascending: false,
            descending: false
        },
        {
            name: "ship_name",
            ascending: false,
            descending: false
        }
    ];
    const [columnPriority, setColumnPriority] = React.useState(
        defaultColumnPriority
    );
    const setAndSaveColumnPriority = (updatedColumnPriority) => {
        setColumnPriority(JSON.parse(JSON.stringify(updatedColumnPriority)));
        localStorage.setItem(
            `shipColumnPriority`, 
            JSON.stringify(updatedColumnPriority)
        );
        setTableKey(tableKey + 1);
    };
    // const setAndSaveColumnPriority = () => {};
    const goHome = goingHome =>{
        goingHome.preventDefault();
        navigate("/customers");
    }
    const addCustomer = customerAdd => {
        customerAdd.preventDefault();
        const createCustomerRequestBody = {
            customer_no: customerNo,
            customer_name: customerName,
            address_ln1: addressLn1,
            address_ln2: addressLn2,
            address_ln3: addressLn3,
            contact_person: contactPerson,
            contact_number: contactNumber,
            contact_email: contactEmail
        }
        axios({
            baseURL: "",
            withCredentials: true, 
            method: 'POST',
            url: `${URLORIGIN}/customers/create`,
            data: createCustomerRequestBody
        }).then(response => {
            if (response.data.customer_exists){
                setCustomerExistsErrorOpen(true);
                setExistingCustomerIsDeleted(response.data.customer_deleted);
            }
            else {
                navigate("/customers");
            }
        });
    }
    const editCustomer = customerEdit => {
        customerEdit.preventDefault();
        const editCustomerRequestBody = {
            customer_name: customerName,
            address_ln1: addressLn1,
            address_ln2: addressLn2,
            address_ln3: addressLn3,
            contact_person: contactPerson,
            contact_number: contactNumber,
            contact_email: contactEmail
        }
        axios(
            `${URLORIGIN}/customers/edit/${customerNo}`, {
                method: 'PATCH',
                baseURL: "",
                withCredentials: true,
                data: editCustomerRequestBody
            }
        ).then(() => {
            setOldCustomerName(customerName);
            setOldAddressLn1(addressLn1);
            setOldAddressLn2(addressLn2);
            setOldAddressLn3(addressLn3);
            setOldContactNumber(contactNumber);
            setOldContactPerson(contactPerson);
            setOldContactEmail(contactEmail);
            }
        );
    }
    const deleteCustomer = () => {
        axios.delete(
            `${URLORIGIN}/customers/${customerNo}`, {
                baseURL: "",
                withCredentials: true
            }
        ).then(() => navigate("/customers"));
    }
    const resetCustomer = customerReset => {
        customerReset.preventDefault();
        setCustomerName(oldCustomerName);
        document.getElementById('customer-name-input').value = oldCustomerName;
        setAddressLn1(oldAddressLn1);
        document.getElementById('address-line-1-input').value = oldAddressLn1;
        setAddressLn2(oldAddressLn2);
        document.getElementById('address-line-2-input').value = oldAddressLn2;
        setAddressLn3(oldAddressLn3);
        document.getElementById('address-line-3-input').value = oldAddressLn3;
        setContactNumber(oldContactNumber);
        document.getElementById("contact-number-input").value = oldContactNumber;
        setContactPerson(oldContactPerson);
        document.getElementById("contact-person-input").value = oldContactPerson;
        setContactEmail(oldContactEmail);
        document.getElementById("contact-email-input").value = oldContactEmail;
    }
    const tableRow = (row, rowIndex, columnPriority, columnCount)=>{
        return(
            <tr key={`ship-${rowIndex}`} className={row.is_disabled?"deleted-ship":"in-commission-ship"}>
                {
                    columnPriority.slice(
                        0, columnCount
                    ).map(
                        (column) =>{
                            return(
                                <td key={`user-${rowIndex}-roles`}>
                                    {row[column.name]}
                                </td>
                            )
                            
                        }
                    )
                }
            </tr>
        )
    }

    React.useEffect(
        () => {
            if (isAdd){
                axios.get(
                    `${URLORIGIN}/customers/create`,
                    {
                        baseURL: "",
                        withCredentials: true
                    }
                ).catch(error => {
                    if (error.response.status === 303) {
                        navigate(error.response.data.url)
                    }
                });
            }
        }, []
    )

    React.useEffect(() => {
        if (!isAdd){
            var shipListParams = {};
            shipListParams.is_retired = isRetired;
            for (const column of columnPriority){
                if (column.ascending || column.descending){
                    shipListParams.sort = column.name;
                    shipListParams.sort_ascending = column.ascending;
                    shipListParams.sort_descending = column.descending;
                }
            }
            axios.get(
                `${URLORIGIN}/customers/edit/${customerNo}`, {
                    baseURL: "",
                    withCredentials: true,
                    params: shipListParams
                }
            ).then(response => {
                setCustomerNo(response.data.customer_no?response.data.customer_no:"");
                setCustomerName(response.data.customer_name?response.data.customer_name:"");
                setOldCustomerName(response.data.customer_name?response.data.customer_name:"");
                setAddressLn1(response.data.address_ln1?response.data.address_ln1:"");
                setOldAddressLn1(response.data.address_ln1?response.data.address_ln1:"");
                setAddressLn2(response.data.address_ln2?response.data.address_ln2:"");
                setOldAddressLn2(response.data.address_ln2?response.data.address_ln2:"");
                setAddressLn3(response.data.address_ln3?response.data.address_ln3:"");
                setOldAddressLn3(response.data.address_ln3?response.data.address_ln3:"");
                setContactPerson(response.data.contact_person?response.data.contact_person:"");
                setOldContactPerson(response.data.contact_person?response.data.contact_person:"");
                setContactNumber(response.data.contact_number?response.data.contact_number:"");
                setOldContactNumber(response.data.contact_number?response.data.contact_number:[]);
                setContactEmail(response.data.contact_email?response.data.contact_email:"");
                setOldContactEmail(response.data.contact_email?response.data.contact_email:"");
                setShips(response.data.ships?response.data.ships:[]);
            }).catch(error => {
                if (error.response.status === 303) {
                    navigate(error.response.data.url)
                }
            });
        }
    }, [isRetired, columnPriority])
    return(
        <article id="add-edit-customer-container">
            <TopRow 
            headerText={isAdd?"Add Customer":"Customer Details"} 
            buttons={[
                isAdd
                ?{
                    idKey: "text-create",
                    text: "Add",
                    form: "add-edit-customer-form",
                    onClickFunction: addCustomer
                }
                :{
                    idKey: "text-save",
                    text: "Save",
                    onClickFunction: editCustomer
                },
                isAdd
                ?null
                :{
                    idKey: "text-reset",
                    text: "Reset",
                    onClickFunction: resetCustomer
                },
                isAdd
                ?null
                :{
                    idKey: "text-delete",
                    text: "Delete",
                    onClickFunction: deleteCustomer
                },
                {
                    idKey: "text-back", 
                    text: "Back", 
                    onClickFunction: goHome}
            ]}/>

            <CustomerDetails
            isAdd={isAdd}
            customerNo={customerNo}
            setCustomerNo={setCustomerNo}
            customerName={customerName}
            setCustomerName={setCustomerName}
            addressLn1={addressLn1}
            setAddressLn1={setAddressLn1}
            addressLn2={addressLn2}
            setAddressLn2={setAddressLn2}
            addressLn3={addressLn3}
            setAddressLn3={setAddressLn3}
            contactPerson={contactPerson}
            setContactPerson={setContactPerson}
            contactNumber={contactNumber}
            setContactNumber={setContactNumber}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            addCustomer={addCustomer}
            />
            {
                isAdd
                ?null
                :<FullTable  
                tableKey={tableKey}
                rowCount={rowCount} 
                page={page} 
                setRowCount={setRowCount} 
                setPage={setPage} 
                totalRowCount={ships.length}
                data={
                    ships.slice(
                        (page - 1) * rowCount, 
                        page * rowCount)}
                defaultColumnPriority={defaultColumnPriority}
                columnPriorityKey="ship"
                windowWidthCategory={windowWidthCategory}
                columnPriority={columnPriority}
                preTableRowButtons={
                    [
                        {idKey: "table-add-role", 
                        onClickFunction: (clicked)=>{
                            clicked.preventDefault();
                            setIsRetired(!isRetired);
                        }, 
                        text: isRetired?"Hide Not-In-Service Vessels":"Show Not-In-Service Vessels"}, 
                        {idKey: "table-edit", 
                        onClickFunction: (clicked)=>{
                            clicked.preventDefault(); 
                            navigate(`/customers/ships/${customerNo}`);}, 
                        text: "Edit Vessel"}
                    ]}
                tableRow={tableRow}
                tableHeader={ 
                    (
                        columnPriority, 
                        setAndSaveColumnPriority, 
                        columnCount
                    ) => <thead id="table-header">
                        <tr>
                            {
                                columnPriority.slice(
                                    0, columnCount
                                ).map(
                                    (column, columnIndex) => {
                                        return (
                                            <ChangeColumn 
                                            key={`rendered-${column}-header`}
                                            column={column}
                                            columnIndex={columnIndex}
                                            columnPriority={columnPriority} 
                                            tableHeadersDictionary={tableHeadersDictionary}
                                            setAndSaveColumnPriority={setAndSaveColumnPriority}/>
                                        )
                                    }
                                )
                            }
                        </tr>
                    </thead>
                }
                defaultColumnCount={0}
                setAndSaveColumnPriority={setAndSaveColumnPriority}
                />
            }
            <AlertDialog 
            alertOpen={customerExistsErrorOpen} 
            setAlertOpen={setCustomerExistsErrorOpen} 
            alertTextWrapper={
                existingCustomerIsDeleted
                ?<DialogContentText id='alert-dialog-description'>
                    Customer with ID {customerNo} is found deleted in the database. Please create this entry with a new customer ID.
                </DialogContentText>
                :<DialogContentText id='alert-dialog-description'>
                    Customer with ID {customerNo} is found in the database. Please click <a id="dialog-link" href={`/customers/edit/${customerNo}`} rel="external" media="screen" type="text/html">here</a> to edit customer {customerNo}.
                </DialogContentText>
            }
            action=""/>
        </article>
    )
}

function CustomerDetails(
    {
        isAdd,
        customerNo,
        setCustomerNo,
        customerName,
        setCustomerName,
        addressLn1,
        setAddressLn1,
        addressLn2,
        setAddressLn2,
        addressLn3,
        setAddressLn3,
        contactPerson,
        setContactPerson,
        contactNumber,
        setContactNumber,
        contactEmail,
        setContactEmail,
        addCustomer
    }
){
    return(
        <form
        id="add-edit-customer-form" onSubmit={addCustomer}>
            <div>
                <div className="dual-fields-container">
                    {isAdd
                    ? <EditableField 
                    setter={setCustomerNo} 
                    label="Customer ID*" 
                    placeholder="Customer ID" 
                    idKey="customer-no" 
                    defaultValue={customerNo} 
                    cssClass="left-field"
                    isRequired={true}/>
                    : <ReadonlyField 
                    value={customerNo} 
                    label="Customer ID" 
                    idKey="customer-no" 
                    placeholder="Customer ID" 
                    fullLength="" 
                    cssClass="left-field"/>}
                    <EditableField 
                    setter={setCustomerName} 
                    label="Customer Name" 
                    placeholder="Customer Name" 
                    idKey="customer-name" 
                    defaultValue={customerName} 
                    cssClass="right-field"/>
                </div>
                <div className="dual-fields-container">
                    <EditableField 
                    setter={setAddressLn1} 
                    label="Address" 
                    placeholder="Line 1" 
                    idKey="address-line-1" 
                    defaultValue={addressLn1} 
                    cssClass="long-field short-label"/>
                    <EditableField 
                    setter={setAddressLn2} 
                    label="" 
                    placeholder="Line 2" 
                    idKey="address-line-2" 
                    defaultValue={addressLn2} 
                    cssClass="long-field short-label next-line-field"/>
                    <EditableField 
                    setter={setAddressLn3} 
                    label="" 
                    placeholder="Line 3" 
                    idKey="address-line-3" 
                    defaultValue={addressLn3} 
                    cssClass="long-field short-label next-line-field"/>
                </div>
            </div>
            <div id="contact-person">
                <h3>Contact Person</h3>
                <div>
                    <div className="dual-fields-container">
                        <EditableField
                        setter={setContactPerson}
                        label="Name"
                        placeholder="Name"
                        idKey="contact-person"
                        type="text"
                        defaultValue={contactPerson}
                        cssClass="left-field short-label"/>
                        <EditableField
                        setter={setContactNumber}
                        label="Tel."
                        placeholder="Phone Number"
                        idKey="contact-number"
                        defaultValue={contactNumber}
                        cssClass="right-field short-label"/>
                    </div>
                    <div className="dual-fields-container">
                        <EditableField
                        setter={setContactEmail}
                        label="Email"
                        type="email"
                        placeholder="Email"
                        idKey="contact-email"
                        defaultValue={contactEmail}
                        cssClass="long-field short-label"/>
                    </div>
                </div>
            </div>
        </form>
    )
}